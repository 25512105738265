import React, { useState, useEffect, useLayoutEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import Divider from '@material-ui/core/Divider';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import CardMedia from '@material-ui/core/CardMedia';
import ExpandedComment from "../ExpandedComment";
import { getComment } from '../LibraryFunctions';
import LoginBar from "../homepagecomponents/LoginBar";
import CategoryCommentView from './CategoryCommentView';

/*
Description: 
    For a given library and section, displays all the comments 
    within a given category
Data from props:
    props.jwt - user's jwt token
    props.match.params.libraryId 
    props.match.params.sectionId
    props.match.params.category
*/

export function SingleCommentView(props) {

    const classes = useStyles();
    //Width and height of window
    const [width, height] = useWindowSize();
    // The section object for the desired section, null if doesn't exist
    const [comment, setComment] = useState(null);
    //used to hard refresh the ExpandedComment component
    const [update, setUpdate] = useState(0);
    //for expandedComment component
    const opened = { open: true }

    // Gets the section based off libraryId and sectionId
    useEffect(() => {
        const getCommentData = async () => {
            console.log(props.match.params)
            getComment(props.match.params.libraryId,
                props.match.params.sectionId,
                props.match.params.commentId)
                .then((comment) => {
                    if (comment !== null) {
                        console.log("comment: " + comment.category);
                        setComment(comment);
                    } else {
                        console.log("error retrieving the comment");
                    }
                });
        };
        getCommentData();
    }, [props.match.params]);

    const main = {
        display: 'flex',
        flexDirection: 'column',
        width: width *.98,
    };

    return (
        <div>
            {comment !== null && comment.type != 'Category Overview' &&
                <div style={main}>
                    <LoginBar comment={comment} color={'none'} edit={true}/>
                    <div key={comment.id} className={classes.container}>
                        <CustomAccordion expanded={true}>
                            <CustomAccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id={comment.id.toString()}
                                IconButtonProps={{ edge: 'start' }}
                            >
                                <Typography
                                    component={'span'}
                                    className={classes.summary}
                                >
                                    <div>{comment.title}</div>
                                </Typography>
                            </CustomAccordionSummary>
                            <Divider />
                            <AccordionDetails
                                className={classes.accordionDetails}>
                                <Typography
                                    component={'span'}
                                    style={{ width: '100%' }}
                                >
                                    <ExpandedComment
                                        update={update}
                                        comment={comment}
                                        display={opened}
                                    />
                                </Typography>
                            </AccordionDetails>
                        </CustomAccordion>
                    </div>
                </div>
            }
            {comment !== null && comment.type == 'Category Overview' &&
                <CategoryCommentView 
                    libraryId={props.match.params.libraryId}
                    sectionId={props.match.params.sectionId}
                    category={comment.category}
                />
            }
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        paddingBottom: '1%',
        paddingLeft: '2%',
        marginTop: '1%'
    },
    accordionDetails: {
        marginTop: '1%'
    },
    summary: {
        fontSize: theme.typography.pxToRem(30),
        fontWeight: theme.typography.fontWeightRegular,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: 'grey'
    },
    media: {
        height: 50,
        width: 50,
    },
    pictureRoot: {
        minWidth: 50,
        boxShadow: 'none',
        padding: '1%',
    },
}));

const CustomAccordion = withStyles({
    root: {
        border: '1px solid silver',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: '0%',
        },
        margin: 0,
    },
    expanded: {
    },
})(MuiAccordion);

const CustomAccordionSummary = withStyles({
    root: {
        height: 40,
        marginBottom: -1,
        minHeight: 35,
        '&$expanded': {
            minHeight: 45,
        },
    },
    content: {
        '&$expanded': {
            margin: '0 0',
        },
    },
    expandIcon: {
        order: -1
    },
    expanded: {},
})(MuiAccordionSummary);

//Keeps track of the window size
function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            //console.log(window.document.getElementById("main").offsetWidth)
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}