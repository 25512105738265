import React, { useState, useEffect } from 'react';
import { useAuth0 } from '../react-auth0-spa';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    withRouter,
    useHistory,
    useLocation
} from "react-router-dom";
import { MainTab } from './LibraryTab/MainTab';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Profile from './Profile';
import {
    checkIfUserExistsAndCreate,
    createUser,
} from './LibraryFunctions';


// The dashboard with the different tabs
export function Dash(props) {
    const classes = useStyles();
    // 0 for libraries, 1 for profile
    const [tabIndex, setTabIndex] = useState(0);
    const [createdUser, setCreateUser] = useState(false);
    const { logout } = useAuth0();
    const history = useHistory();
    const location = useLocation();
    const [category, setCategory] = useState(null);
    const [commentIdForEdit, setCommentIdForEdit] = useState(null);

    useEffect(() => {
        // console.log("useEffect called");
        if (location.pathname.includes('libraries')) {
            setTabIndex(0);
        } else {
            setTabIndex(1);
        }

        // handle link redirect view
        if (location.pathname.includes('category')) {
            const ind = location.pathname.indexOf("/category");
            const endVals = location.pathname.substring(ind + 10).split("\/");
            const categoryname = endVals[0];
            const commentId = endVals[1];
            setCommentIdForEdit(commentId);
            setCategory(categoryname);
        }

        if (props.jwt !== "") {
            // check if the user exists
            checkIfUserExistsAndCreate(props.jwt).then(userId => {
                return userId;
            }).then(id => {
                if (id === -1) {
                    return createUser(props.jwt);
                } else {
                    setCreateUser(true);
                }
            }).then(createdId => {
                if (createdId != null) {
                    setCreateUser(true);
                }
            });
        }
    }, [props]);

    function handleChange(event, newValue) {
        // console.log("handleChange called");
        setTabIndex(newValue);
        if (newValue === 0) {
            history.push(`/libraries`);
        } else if (newValue === 1) {
            history.push(`/profile`);
        }
    };

    const tabPanel = {
        'height': '100%',
        'width': '100%'
    }

    return (
        <div className={classes.root} style={{ minWidth: '1340px' }}>
            <Tabs
                orientation="vertical"
                value={tabIndex}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabs}
            >
                <Tab label="Dashboard" {...a11yProps(0)} />
                <Tab label="Profile" {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={tabIndex} index={0} style={tabPanel}>
                {createdUser && (
                    <MainTab jwt={props.jwt} category={category} commentIdForEdit={commentIdForEdit} />
                )}
            </TabPanel>
            <TabPanel value={tabIndex} index={1} style={tabPanel}>
                <Profile jwt={props.jwt} />
            </TabPanel>
        </div>
    );
}

// component for the individual tabs from material ui
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}
                    style={{ maxHeight: "100%" }}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: 224,
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
}));
