import React, { useState, useEffect } from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { CustomTextField } from './CustomPopupComponents';
import { CustomFinishButton } from './CustomPopupComponents';
import { CustomSelect } from './CustomPopupComponents';
import { PaperStyle } from './CustomPopupComponents';
import { CustomTitle } from './CustomPopupComponents';

/*
Description: 
    Popup window for creating/editing a library

Data from props:
    formType (string) - value="Edit"/"Create"

Function from props:
    handleLibrary(libraryName, libraryDescription) - create a new library
    closeWindow()
*/

export function CreateOrEditLibraryPopup(props) {
    const formDefaultValues = {
        libraryName: "",
        libraryDescription: "",
    }
    const [formValues, setFormValues] = useState(props.formDefaultValues ? props.formDefaultValues : formDefaultValues);
    const { libraryName, libraryDescription } = formValues;
    function handleChange(e) {
        const target = e.target;
        setFormValues(prevState => ({
            ...prevState,
            [target.name]: target.value
        }));
    }

    const [open, setOpen] = useState(true);
    const handleClose = () => {
        setOpen(false);
        props.closeWindow();
    };

    const handleFinish = () => {
        handleClose();
        props.handleLibrary(libraryName, libraryDescription);
    }

    return (
        <div>
            <Dialog
                PaperProps={PaperStyle}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={"sm"}
            >
                <CustomTitle title={(props.formType === "Create") ? ("Create a New Library") : ("Edit Library")} onClick={handleClose} />
                <DialogContent>
                    <CustomTextField title="Name" onChange={handleChange} name="libraryName" value={libraryName} rows={1} onEnter={handleFinish} />
                    <CustomTextField title="Description (300 Character Limit)" onChange={handleChange} name="libraryDescription" value={libraryDescription} rows={3} maxCharacters={300} onEnter={handleFinish} />
                </DialogContent>
                <DialogActions>
                    <CustomFinishButton text={(props.formType === "Create") ? ("Create") : ("Save")} onClick={handleFinish} />
                </DialogActions>
            </Dialog>
        </div>
    );
}