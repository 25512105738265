import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { useAuth0 } from "../react-auth0-spa";

const PrivateRoute = ({ component: Component, path, ...rest }) => {
    const { loading, isAuthenticated, loginWithRedirect, getIdTokenClaims } = useAuth0();
    const [jwt, setJwt] = useState("");

    useEffect(() => {
        if (loading) {
            return;
        } else if (isAuthenticated) {
            getIdTokenClaims().then(function (response) {
                if (response !== undefined) {
                    setJwt(response.__raw);
                }
            })
            return;
        }
        const fn = async () => {
            await loginWithRedirect({
                appState: { targetUrl: window.location.pathname }
            });
        };
        fn();
    }, [loading, isAuthenticated, loginWithRedirect, path]);

    const render = props =>
        isAuthenticated === true ? <Component {...props} jwt={jwt} /> : null;

    return <Route exact path={path} render={render} {...rest} />;
};

export default PrivateRoute;