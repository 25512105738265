import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import CommentDescription from './CommentDescription';
import { DeletePopup } from './Popups/DeletePopups';
import { CreateOrEditSectionPopup } from './Popups/SectionPopups';

/*
Description: The window for displaying comments in the section
based on their categories.

Data from props: section object, the section with all
the comments that we are going to display

Functions from props: 
addComment(name, goodExample, badExample, description, 
    catergory, parentSection)

deleteSection(sectionId)

editSectionName(sectionId, sectionTitle): edit the section name, 
pass the new section name and other details of the section like sectionId, that
section's comments, and the sections's sections (subsection list).

addSection(title, props.libraryId, props.section.id)

editComment(sectionId, title,
            explanation, incorrectExample, correctExample,
            category, commentId)

deleteComment(sectionId, commentId)

setParentSections: allows us to specify what sub-section we are on by using ids
of any parent sections and child sections associated with the current sections
we have selected.

*/

export function LibraryComment(props) {

    //Style for the material-ui components
    const classes = useStyles();

    const [showDeleteSectionPopup, setShowDeleteSectionPopup] = useState(false);

    //props for the New Section button popup
    const [sectionName, setSectionName] = useState("Section");
    //boolean used to display the section popup to edit it's name
    const [sectionPopupEdit, setSectionPopupEdit] = useState(false);
    //defines the type of section popup needed
    const [formType, setFormType] = useState(null);
    //contains default section values displayed in the edit section popup
    const [formDefaultValues, setFormDefaultValues] = useState({})

    //This funciton handles changing the name of the current
    //section.
    function handleEditSectionName(sectionTitle) {
        props.editSectionName(props.section.id, sectionTitle);
    }

    //When the edit button is pressed, this sets the formType of the
    //section popup and sets the sectionPopupEdit boolean to true so
    //the seciton popup is displayed.
    function handleEditSectionPopup() {
        setFormType('Edit');
        setSectionName(props.section.name);
        const values = {
            sectionName: JSON.parse(JSON.stringify(props.section.name))
        }
        setFormDefaultValues(values);
        setSectionPopupEdit(true);
    }

    //Sets showDeleteSectionPopup to true so the delete popup
    //is displayed.
    function handleDeleteSection() {
        setSectionName(props.section.name);
        setShowDeleteSectionPopup(true);
    }

    //Handles deleting a section
    function deleteSection() {
        props.deleteSection(props.section.id);
    }


    return (
        <React.Fragment>
            <div className={classes.libComment}>
                <div className={classes.header}>
                    <h1 className={classes.title}>
                        {props.section.name}
                    </h1>
                    <div>
                        <IconButton aria-label="edit" size="medium" onClick={handleEditSectionPopup}>
                            <Edit className={classes.headerButton} />
                        </IconButton>
                        <IconButton aria-label="delete" size="medium" onClick={handleDeleteSection}>
                            <Delete className={classes.headderButton} />
                        </IconButton>
                    </div>
                </div>
                <CommentDescription
                    permissionType={props.permissionType}
                    section={props.section}
                    addComment={props.addComment}
                    addSection={props.addSection}
                    libraryId={props.libraryId}
                    jwt={props.jwt}
                    selectedCommentId={props.selectedCommentId}
                    selectedCommentCategory={props.selectedCommentCategory}
                    editComment={props.editComment}
                    deleteComment={props.deleteComment}
                    moveComment={props.moveComment}
                    setPath={props.setPath}
                    path={props.path}
                    libraryData={props.libraryData}
                    updateCommentOrder={props.updateCommentOrder}
                    commentIdForEdit={props.commentIdForEdit}
                />
            </div >
            {sectionPopupEdit &&
                <CreateOrEditSectionPopup
                    formType={formType}
                    handleSection={handleEditSectionName}
                    closeWindow={() => setSectionPopupEdit(false)}
                    formDefaultValues={formDefaultValues} />}
            {showDeleteSectionPopup &&
                (<DeletePopup
                    permissionType={props.permissionType}
                    deleteType={sectionName}
                    handleDelete={deleteSection}
                    closeWindow={() => setShowDeleteSectionPopup(false)} />)}
        </React.Fragment>
    );
}

//Styling for all of the custom componenets
const useStyles = makeStyles((theme) => ({
    libComment: {
        width: '100%'
    },
    headerButton: {
        fontWeight: 500,
    },
    header: {
        color: 'grey',
        fontWeight: 500,
        fontSize: 'medium',
        padding: 0,
        margin: 0,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    title: {
        color: "grey",
        fontFamily: "Roboto"
    }
}));