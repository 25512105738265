import React, { useState, useEffect } from 'react';
import {
    Button,
    Box,
} from '@material-ui/core';
import { MyLibrariesCard } from './MyLibrariesCard';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Popover from '@material-ui/core/Popover';
import { CreateOrEditLibraryPopup } from './Popups/LibraryPopups';
import { ConfirmationPopup } from './Popups/ConfirmationPopup';
import Grid from '@material-ui/core/Grid';


/*
Description: Window with the libraries for the user

Data from props: Array of library objects (libraries), library object 
will have a name, description, type (comment/text) (can ignore for now),
and permission type (owner, editor, viewer)

Functions from props: librarySelected(index)
                      createNewLibrary(name, description)  
                      deleteExistingLibrary(libraryId)
                      shareExistingLibrary(libraryId, email, role)
*/
export function MyLibraries(props) {

    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);
    const openEditOrDelete = Boolean(anchorEl);

    const [creatingNewLibrary, setCreatingNewLibrary] = useState(false);

    const [confirmationPopup, setConfirmationPopup] = useState(false);
    const [confirmationPopupValues, setConfirmationPopupValues] = useState(null);

    const handleLibraryCreateOrUpload = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = (event) => {
        setAnchorEl(null)
    };

    const handleCreateLibrary = () => {
        setCreatingNewLibrary(true);
    };

    const handleUploadLibrary = (event) => {
        const file = event.target.files[0];
        const extension = file.name.split('.').pop().toLowerCase();
        let libraryJSON = null;

        if (extension == "lib") {
            const reader = new FileReader();
            reader.onload = async (e) => {
                libraryJSON = e.target.result;
                console.log(libraryJSON);
                handleUploadJSON(libraryJSON);
            };
            reader.readAsText(file);
        } else {
            handleUploadJSON(libraryJSON);
        }
        setAnchorEl(null);
    };

    const handleUploadJSON = (libraryJSON) => {
        console.log(libraryJSON);
        if (libraryJSON == null) {
            console.log("Bad File");
            let values = {};
            values.title = "Bad Request";
            values.message = "Invalid file provided.";
            setConfirmationPopupValues(values);
            setConfirmationPopup(true);
        } else {
            props.uploadLibrary(libraryJSON).then((status) => {
                let values = {};
                console.log(status);
                if (status === 200) {
                    values.title = "Success";
                    values.message = "Upload has been succesful.";
                } else if (status === 400) {
                    values.title = "Bad Request";
                    values.message = "Invalid file provided for library upload.";
                }
                setConfirmationPopupValues(values);
                setConfirmationPopup(true);
            });
        }
    }

    const handleCloseLibraryCreatePopup = () => {
        setCreatingNewLibrary(false);
        setAnchorEl(null);
    }

    const handleLibraryClick = (event, index, id) => {
        props.librarySelected(index, id);
    }

    const handleShareExistingLibrary = (libraryId, email, role, libraryName) => {
        props.shareExistingLibrary(libraryId, email, role).then((status) => {
            let values = {};
            console.log(status);
            if (status === 200) {
                values.title = "Success";
                values.message = libraryName + " has been shared."
            } else if (status === 403) {
                values.title = "Forbidden";
                values.message = "You do not have permissions to share " + libraryName + ".";
            } else if (status === 400) {
                values.title = "Bad Request";
                values.message = "Recipient does not exist.";
            } else if (status === 401) {
                values.title = "Unauthorized Request";
                values.message = "You are unauthorized to change the permission of the recipient";
            }
            setConfirmationPopupValues(values);
            console.log(values);
            console.log(confirmationPopupValues);
            setConfirmationPopup(true);
        });
    }

    const handleShareCopyOfExistingLibrary = (libraryId, email, libraryName) => {
        props.shareCopyOfExistingLibrary(libraryId, email).then((status) => {
            let values = {};
            console.log(status);
            if (status === 200) {
                values.title = "Success";
                values.message = "Copy of " + libraryName + " has been shared."
            } else if (status === 403) {
                values.title = "Forbidden";
                values.message = "You do not have permissions to share " + libraryName + ".";
            } else if (status === 400) {
                values.title = "Bad Request";
                values.message = "Recipient does not exist.";
            } else if (status === 401) {
                values.title = "Unauthorized Request";
                values.message = "You are unauthorized to change the permission of the recipient";
            }
            setConfirmationPopupValues(values);
            console.log(values);
            console.log(confirmationPopupValues);
            setConfirmationPopup(true);
        });
    }

    const handleRecoverBackupLibrary = (libraryJSON, libraryId) => {
        if (libraryJSON == null) {
            let values = {};
            values.title = "Bad Request";
            values.message = "Invalid file provided.";
            setConfirmationPopupValues(values);
            setConfirmationPopup(true);
        } else {
            props.recoverBackupLibrary(libraryJSON, libraryId).then((status) => {
                let values = {};
                console.log(status);
                if (status === 200) {
                    values.title = "Success";
                    values.message = "Recovery has been succesful.";
                } else if (status == 403) {
                    values.title = "Forbidden";
                    values.message = "You do not have permissions to recover.";
                } else if (status === 400) {
                    values.title = "Bad Request";
                    values.message = "Invalid backup file provided for the library.";
                }
                setConfirmationPopupValues(values);
                setConfirmationPopup(true);
            });
        }
    }

    const handleDeleteExistingLibrary = (libraryId, libraryName) => {
        props.deleteExistingLibrary(libraryId).then((status) => {
            let values = {};
            if (status === 200) {
                values.title = "Success";
                values.message = libraryName + " has been deleted."
            } else if (status === 403) {
                values.title = "Forbidden";
                values.message = "You do not have permissions to delete " + libraryName + ".";
            }
            setConfirmationPopupValues(values);
            setConfirmationPopup(true);
        });
    };

    return (
        <React.Fragment>
            <h1>My Libraries</h1>
            <Button
                variant="contained"
                onClick={(event) => handleLibraryCreateOrUpload(event)}
            >
                New Library
            </Button>
            <Box
                className={classes.container}
                display={"flex"}
                flexWrap={"wrap"}
                    marginTop={"20px"}
            >
                {/* Popover */}
                <Box
                    display="flex"
                    justifyContent="flex-end"
                >
                    <Popover
                        id='simple-popover'
                        open={openEditOrDelete}
                        anchorEl={anchorEl}
                        onClose={(event) => handlePopoverClose(event)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <List>
                            <ListItem button onClick={handleCreateLibrary} key={0} className={classes.listItems}>
                                <ListItemText primary='Create' />
                            </ListItem>
                            <ListItem button variant="contained" component="label" key={1} className={classes.listItems}>
                                <ListItemText primary='Upload' />
                                <input type="file" hidden onChange={(event) => handleUploadLibrary(event)} />
                            </ListItem>
                        </List>
                    </Popover>
                </Box>

                {/* The actual library boxes */}
                {props.libraries.map(function (lib, index) {
                    return (
                        <Box
                            key={props.libraries + index}
                            onClick={(event) => handleLibraryClick(event, index, lib.id)}
                        >
                            <MyLibrariesCard
                                libraryName={lib.name}
                                description={lib.description}
                                permission={lib.permission}
                                libraryId={lib.id}
                                deleteExistingLibrary={() => handleDeleteExistingLibrary(lib.id, lib.name)}
                                editExistingLibrary={props.editExistingLibrary}
                                shareExistingLibrary={handleShareExistingLibrary}
                                shareCopyOfExistingLibrary={handleShareCopyOfExistingLibrary}
                                downloadBackupLibrary={props.downloadBackupLibrary}
                                recoverBackupLibrary={handleRecoverBackupLibrary}
                            />
                        </Box>
                    );
                })}
            </Box>

            {
                creatingNewLibrary && (<CreateOrEditLibraryPopup
                    formType="Create"
                    handleLibrary={props.createNewLibrary}
                    closeWindow={handleCloseLibraryCreatePopup} />)
            }
            {
                confirmationPopup && (<ConfirmationPopup
                    title={confirmationPopupValues.title}
                    message={confirmationPopupValues.message}
                    closeWindow={() => setConfirmationPopup(false)} />)
            }
        </React.Fragment>
    );
}

const useStyles = makeStyles({
    container: {
        // minWidth: '300px',
    },
    newLibraryButton: {
        alignItems: 'flex-end',
    },
});
