import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import { downloadClickEvent } from '../../GAEvents';

export default function UsePage(props) {
    const classes = useStyles();
    const title = "How do I install ECoS?";
    const stepOne = "1. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor";
    const stepTwo = "2. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor";
    const stepThree = "3. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor";

    const handleInstall = () => {
        downloadClickEvent();
        window.open("https://appsource.microsoft.com/en-us/product/office/WA200003975?tab=Overview");
    }

    return (
        <div>
            <Grid container 
                direction="row"
                justify="center"
                alignItems="center" 
                className={classes.root}
            >
                {/*
                <Grid container 
                    direction="row"
                    justify="center"
                    alignItems="center" xs={12} className={classes.grid}
                >
                    <Typography className={classes.title}>{title}</Typography>
                </Grid>
                <Grid item xs={7} className={classes.grid}>
                    <Typography className={classes.description}>{stepOne}</Typography>
                </Grid>
                <Grid item xs={5} className={classes.grid}>
                    <Card className={classes.card}>
                        <CardMedia 
                            className={classes.media}
                            image='https://ecos-media.s3-us-west-2.amazonaws.com/Landing.jpg'
                        />
                    </Card>
                </Grid>
                <Grid item xs={7} className={classes.grid}>
                    <Typography className={classes.description}>{stepTwo}</Typography>
                </Grid>
                <Grid item xs={5} className={classes.grid}>
                    <Card className={classes.card}>
                        <CardMedia 
                            className={classes.media}
                            image='https://ecos-media.s3-us-west-2.amazonaws.com/Landing.jpg'
                        />
                    </Card>
                </Grid>
                <Grid item xs={7} className={classes.grid}>
                    <Typography className={classes.description}>{stepThree}</Typography>
                </Grid>
                <Grid item xs={5} className={classes.grid}>
                    <Card className={classes.card}>
                        <CardMedia 
                            className={classes.media}
                            image='https://ecos-media.s3-us-west-2.amazonaws.com/Landing.jpg'
                        />
                    </Card>
                </Grid>
                */}
                <Grid container 
                    direction="row"
                    justify="center"
                    alignItems="center" xs={12} className={classes.grid}
                >
                    <Button onClick={() => handleInstall()} className={classes.button} variant="raised">
                        Download Now
                    </Button>

                </Grid>
            </Grid>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      paddingTop: '100px',
      paddingRight: '100px',
      paddingLeft: '100px',
    },
    title: {
        fontWeight: 700,
        color: '#1B0D6E',
        fontSize: 50,
        paddingBottom: '15px',
    },
    grid: {
        paddingLeft: '25px',
        paddingRight: '25px',
        paddingBottom: '25px',
    },
    description: {
        fontSize: 25,
    },
    card: {
        backgroundColor: 'black',
    },
    media: {
        paddingTop: '56.25%',
    },
    button: {
        marginBottom: '55px',
        fontSize: 20,
        color: "white",
        background: '#5096FF',
        border: 'none',
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingRight: '50px',
        paddingLeft: '50px',
        "&:hover": {
            backgroundColor: '#5096FF'
        },
        borderRadius: 25,
    },
  }));