import React, { useState, useEffect, useLayoutEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import CardMedia from '@material-ui/core/CardMedia';
import ExpandedComment from "../ExpandedComment";
import { getSection } from '../LibraryFunctions';
import LoginBar from "../homepagecomponents/LoginBar";

/*
Description: 
    For a given library and section, displays all the comments 
    within a given category
Data from props:
    props.jwt - user's jwt token
    props.match.params.libraryId 
    props.match.params.sectionId
    props.match.params.category
*/
export default function CategoryCommentView(props) {

    const classes = useStyles();
    //Width and height of window
    const [width, height] = useWindowSize();
    // The section object for the desired section, null if doesn't exist
    const [sectionData, setSectionData] = useState(null);
    //hard-coded categories for tabs
    const categories = ['Efficiency', 'Content', 'Style'];
    //used to keep track of what tab is pressed and what
    //comments should be displayed based on the tab
    const [tabsValue, setTabsValue] = useState(0);
    //used to hard refresh the ExpandedComment component
    const [update, setUpdate] = useState(0);
    //for expandedComment component
    const opened = {open: true}
    // Gets the section based off libraryId and sectionId
    useEffect(() => {
        const getSectionData = async () => {
            getSection(props.match.params.libraryId, props.match.params.sectionId).then((section) => {
                if (section !== null) {
                    setSectionData(section);
                } else {
                    console.log("error retrieving the section");
                }
            });
        }
        getSectionData();
        console.log(props.match.params)
        if (props.match.params.category) {
            setTabsValue(categories.indexOf(props.match.params.category))
        } else {
            setTabsValue(categories.indexOf('Efficiency'))
        }
    }, [props.match.params]);

    //changes the current tab displayed after a click
    const handleChange = (event, newTabsValue) => {
        setTabsValue(newTabsValue);
    };

    const main = {
        display: 'flex',
        flexDirection: 'column',
        width: width *.98
    };

    return (
        <div style={main}>
            <LoginBar secData={sectionData} color={'none'} edit={true}/>
            
            {sectionData !== null &&
                <div className={classes.container}>
                    <h1 className={classes.title}>
                        {sectionData.name}
                    </h1>
                    <StyledTabs value={tabsValue}
                        onChange={handleChange}
                        aria-label="styledTabs">
                        {categories.map(category => (
                            <StyledTab
                                key={categories.indexOf(category)}
                                label={category}
                                {...a11yProps(categories.indexOf(category))} />
                        ))}
                    </StyledTabs>
                    <Divider />
                    <div className={classes.belowTabs}>
                        {sectionData.comments.map(comment => {
                                return (
                                    <TabPanel
                                    key={comment.id}
                                    value={tabsValue}
                                    index={categories.indexOf(comment.category)}>
                                        <CustomAccordion>
                                            <CustomAccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id={comment.id.toString()}
                                                IconButtonProps={{ edge: 'start' }}
                                            >
                                                <Typography
                                                    component={'span'}
                                                    className={classes.summary}
                                                >
                                                    <div>{comment.title}</div>
                                                </Typography>
                                            </CustomAccordionSummary>
                                            <Divider />
                                            <AccordionDetails
                                                className={classes.accordionDetails}>
                                                <Typography
                                                    component={'span'}
                                                    style={{ width: '100%' }}
                                                >
                                                    <ExpandedComment
                                                        update={update}
                                                        comment={comment}
                                                        display={opened}
                                                    />
                                                </Typography>
                                            </AccordionDetails>
                                        </CustomAccordion>
                                    </TabPanel>
                                );
                        })}
                    </div>
                </div>
            }
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    title: {
        color: "grey",
        fontFamily: "Roboto"
    },
    container: {
        paddingBottom: '1%',
        paddingLeft: '2%',
        marginTop: '1%'
    },
    accordionDetails: {
        marginTop: '1%'
    },
    summary: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: 'grey'
    },
    media: {
        height: 50,
        width: 50,
    },
    pictureRoot: {
        minWidth: 50,
        boxShadow: 'none',
        padding: '1%',
    },
    box: {
        padding: "0.5%",
    },
}));

const CustomAccordion = withStyles({
    root: {
        border: '1px solid silver',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: '0%',
        },
        margin: 0,
    },
    expanded: {
    },
})(MuiAccordion);

const CustomAccordionSummary = withStyles({
    root: {
        height: 40,
        marginBottom: -1,
        minHeight: 35,
        '&$expanded': {
            minHeight: 45,
        },
    },
    content: {
        '&$expanded': {
            margin: '0 0',
        },
    },
    expandIcon: {
        order: -1
    },
    expanded: {},
})(MuiAccordionSummary);

const StyledTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        color: 'black',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),
        '&:focus': {
            opacity: 1,
        },
    },
}))((props) => <Tab disableRipple {...props} />);

const StyledTabs = withStyles({
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        '& > span': {
            maxWidth: 40,
            width: '100%',
            backgroundColor: 'grey',
        },
    },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

//Specifies the TabPanel component that is being used
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const classes = useStyles();
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
            className={classes.tabPanel}
        >
            {value === index && (
                <Box p={3} className={classes.box}>
                    {children}
                </Box>
            )}
        </div>
    );
}

//Props specification for TabPanel
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

//Creates an id and sets some style settings 
//for a StyledTab.
//Allows the ability to scroll when there are too
//many comments to fit on the screen.
function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

//Keeps track of the window size
function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            //console.log(window.document.getElementById("main").offsetWidth)
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}