import React from 'react';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import NativeSelect from "@material-ui/core/NativeSelect";
import MenuItem from '@material-ui/core/MenuItem';
import InputBase from "@material-ui/core/InputBase";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import DeleteIcon from "@material-ui/icons/Delete";

export function CustomTextField(props) {
    const classes = useStyles();

    function handleKeyPress(e) {
        if (e.key === 'Enter') {
            props.onEnter();
        }
    }

    return (
        <div>
            <DialogContentText className={classes.textFieldTitle}>
                {props.title}
            </DialogContentText>
            <TextField
                margin="dense"
                variant="outlined"
                onChange={props.onChange}
                name={props.name}
                value={props.value}
                //rows={props.rows}
                onKeyPress={handleKeyPress}
                multiline
                fullWidth
                inputProps={{ maxLength: 300 }}
            />
        </div>
    );
}

export function CustomFinishButton(props) {
    const classes = useStyles();

    return (
        <div>
            <Button className={classes.button}
                    variant="outlined"
                    onClick={props.onClick}>
                {props.text}
            </Button>
        </div>
    );
}

export function CustomDeleteButton(props) {
    const classes = useStyles();

    return (
        <div>
            <Button className={classes.button}
                    variant="contained"
                    color="secondary"
                    startIcon={<DeleteIcon />}
                    onClick={props.onClick}>
                {props.text}
            </Button>
        </div>
    );
}

export function CustomSelect(props) {
    const classes = useStyles();

    return (
        <div>
            <DialogContentText className={classes.textFieldTitle}>
                {props.title}
            </DialogContentText>
            <NativeSelect
                name={props.name}
                value={props.value}
                onChange={props.onChange}
                input={<StyledInput />}
            >
                {props.options.map(option => { return <option key={option} value={option}>{option}</option> })}
            </NativeSelect>
        </div>
    );
}

export function CustomTitle(props) {
    const classes = useStyles();

    return (
        <div>
            <DialogTitle>
                {props.title ? props.title : ""}
                <IconButton aria-label="close" className={classes.closeButton} onClick={props.onClick}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    },
    textFieldTitle: {
        marginBottom: "0",
        color: 'black'
    },
    pageButtons: {
        background: 'white',
        border: 'none',
    },
    button: {
        background: '#B1B1B1',
        border: '2px solid #B1B1B1',
        marginRight: '16px',
        marginBottom: '16px'
    },
}));

export const StyledInput = withStyles(theme => ({
    input: {
        marginTop: "10px",
        borderRadius: 4,
        position: "relative",
        border: "1px solid #B1B1B1",
        fontSize: 14,
        padding: "10px 26px 10px 12px",
        marginBottom: "10px",
        "&:focus": {
            borderRadius: 4,
            border: "1px solid #3f51b5",
            backgroundColor: "white"
        }
    }
}))(InputBase);

export const PaperStyle = {
    style: {
        background: '#FFFFFF',
        border: '1px solid #000000',
        boxSizing: 'border-box',
        borderRadius: '5px',
    },
}