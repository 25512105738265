import React from "react";
import { useAuth0 } from '../react-auth0-spa';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
  useHistory
} from "react-router-dom";
import Button from "@material-ui/core/Button";
//import styled from 'styled-components';
//import { makeStyles } from '@material-ui/core/styles';




const LogoutButton = () => {
  const { logout } = useAuth0();
  return (
    <Button
    
      onClick={() =>
        logout({
          returnTo: window.location.origin,
        })
      }
      variant="contained"
    >
      LOG OUT
    </Button>
  );
};




export default LogoutButton;