// src/index.js

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "./react-auth0-spa";
import config from "./auth_config.json";
import history from "./utils/history";

// A function that routes the user to the right place
// after login
const onRedirectCallback = appState => {
  // console.log(appState.targetUrl);
  // console.log("index.js");
  // console.log(appState.commentCategory);
  // console.log(appState.secData);
  if (appState && appState.targetUrl.includes("/link/comment")) {
    // Get library, section and (possibly) comment number if from link view
    const valsAfterSlash = appState.targetUrl.substring("/link/comment".length + 1);
    console.log(valsAfterSlash);
    const arrOfVals = valsAfterSlash.split("/");
    console.log(arrOfVals);
    if (appState.commentCategory) {
      // if from SingleCommentView, redirect to appropriate route
      history.push(window.location.pathname + "libraries/" + arrOfVals[0] + "/" + arrOfVals[1] + "/category/" + appState.commentCategory.category + "/" + arrOfVals[2]);
    } else if (appState.secData.comments) {
      // if from CategoryCommentView, redirect to appropriate route
      console.log(appState.secData.comments);
      history.push(window.location.pathname + "libraries/" + arrOfVals[0] + "/" + arrOfVals[1] + "/category/" + appState.secData.comments[0].category + "/none");
    } else {
      // redirect to homepage if info unavailable
      history.push(window.location.pathname + "libraries");
    }
  } else {  // standard redirect
    history.push(window.location.pathname + "libraries");
  }

  // history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname + "libraries");
};

ReactDOM.render(
  <Auth0Provider
    domain={config.domain}
    client_id={config.clientId}
    redirect_uri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
  >
    <App />
  </Auth0Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();