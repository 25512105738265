import React, { useState, useEffect } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import LibraryDescription from './LibraryDescription';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import AddCircle from '@material-ui/icons/AddCircle';
import { CreateOrEditSectionPopup } from './Popups/SectionPopups';
import Button from '@material-ui/core/Button';
import { DeletePopup } from './Popups/DeletePopups';

/*
Description: The window for a section, and its subsections in the library/section

Data from props: section object, the section with all
the subsections that we are going to display

Functions from props: 
sectionSelected(section): when a subsection is clicked, pass the section
object to this

editSectionName(sectionId, sectionTitle, comments, sections): edit the section name, 
pass the new section name and other details of the section like sectionId, that
section's comments, and the sections's sections (subsection list).

deleteSection(sectionId): delete the section

addSection(name, sectionId, libraryId): pass the name of the new section, sectionId is
the id of the current section object and the parent section id

setPath: allows us to specify what sub-section we are on by using ids
of any parent sections and child sections associated with the current sections
we have selected.

*/


export default function LibrarySection(props) {

    //Style for the material-ui components
    const classes = useStyles();

    //props for the Section button popups
    const [sectionPopup, setSectionPopup] = useState(false);
    const [showDeleteSectionPopup, setShowDeleteSectionPopup] = useState(false);
    const [sectionName, setSectionName] = useState("Section");
    const [sectionPopupEdit, setSectionPopupEdit] = useState(false);
    const [sectionPopupAdd, setSectionPopupAdd] = useState(false);
    const [formType, setFormType] = useState(null);
    //contains default section values displayed in the edit section popup
    const [formDefaultValues, setFormDefaultValues] = useState({})

    //Calls onto the given editSectionName 
    function handleEditSection(sectionTitle) {
        props.editSectionName(props.section.id, sectionTitle);
    }

    //Sets setShoweDeleteSectionPopup to true so the delete
    //popup can be displayed
    function handleDeleteSection() {
        setSectionName(props.section.name);
        setShowDeleteSectionPopup(true);
    }

    //Calls addSection for the popup to create a new section
    function handleAddSection(sectionTitle) {
        props.addSection(sectionTitle, props.libraryId, 0);
    }

    //Sets the formType to Create and sets the display
    //bolean for section popup to true.
    function handleAddSectionPopup() {
        setFormType('Create');
        setSectionPopupAdd(true);
    }

    //Sets the formType to Edit and sets the display
    //bolean for section popup to true.
    function handleEditSectionPopup() {
        setFormType('Edit');
        setSectionName(props.section.name);
        const values = {
            sectionName: JSON.parse(JSON.stringify(props.section.name))
        }
        setFormDefaultValues(values);
        setSectionPopupEdit(true);
    }

    //Handles deleting a section
    function deleteSection() {
        props.deleteSection(props.section.id);
    }

    return (
        <React.Fragment>
            <div className={classes.libSection}>
                {props.section ?
                    <div>
                        <div className={classes.header}>
                            <h1 className={classes.title}>
                                {props.section.name}
                            </h1>
                            <div>
                                <IconButton
                                    aria-label="edit"
                                    size="medium"
                                    onClick={handleEditSectionPopup}
                                >
                                    <Edit className={classes.headerButton} />
                                </IconButton>
                                <IconButton
                                    aria-label="delete"
                                    size="medium"
                                    onClick={handleDeleteSection}
                                >
                                    <Delete className={classes.headderButton} />
                                </IconButton>
                            </div>
                        </div>
                        <LibraryDescription
                            section={props.section}
                            sectionSelected={props.sectionSelected}
                            addSection={props.addSection}
                            libraryId={props.libraryId} 
                            setPath={props.setPath}
                            path={props.path}
                            libraryData={props.libraryData}
                        />
                    </div>
                    :
                    <div>
                        <div className={classes.header}>
                            <h1 className={classes.title}>
                                Empty Library
                            </h1>
                        </div>
                        <Divider />
                        <div className={classes.vacantSectionContainer}>
                            <div>
                                <Button
                                    onClick={handleAddSectionPopup}
                                    variant="contained"
                                    className={classes.newSectionButton}>
                                    New Section
                                </Button>
                            </div>
                            <div className={classes.vacantSectionDisplay}>
                                <div>
                                    <Button
                                        onClick={handleAddSectionPopup}
                                        variant="contained"
                                        className={classes.vacantSectionDisplay}>
                                        <AddCircle className={classes.addCircleIcon} size="large"/>
                                    </Button>
                                </div>
                                <h1 className={classes.vacantSectionTitle}>Add Section</h1>
                            </div>
                        </div>
                    </div>
                }
            </div >
            {sectionPopupEdit &&
                <CreateOrEditSectionPopup
                    formType={formType}
                    handleSection={handleEditSection}
                    closeWindow={() => setSectionPopupEdit(false)}
                    formDefaultValues={formDefaultValues} />}
            {sectionPopupAdd &&
                <CreateOrEditSectionPopup
                    formType={formType}
                    handleSection={handleAddSection}
                    closeWindow={() => setSectionPopupAdd(false)} />}
            {showDeleteSectionPopup &&
                (<DeletePopup
                    permissionType={props.permissionType}
                    deleteType={sectionName}
                    handleDelete={deleteSection}
                    closeWindow={() => setShowDeleteSectionPopup(false)} />)}
        </React.Fragment>
    );
}

//Styling for all of the custom componenets
const useStyles = makeStyles((theme) => ({
    vacantSectionDisplay: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '15%',
        height: '100%',
    },
    vacantSectionContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    newSectionButton: {
        float: 'right',
        textTransform: 'none',
        marginTop: '2%',
        boxShadow: 'none',
        marginBottom: '2%'
    },
    vacantSectionTitle: {
        fontWeight: 500,
        fontSize: 'large',
        color: 'grey',
        marginBottom: '0%'
    },
    addCircleIcon: {
        color: 'Silver',
        fontWeight: 500,
        fontSize: 50,
    },
    libSection: {
        width: '100%'
    },
    headerButton: {
        fontWeight: 500,
    },
    header: {
        color: 'grey',
        fontWeight: 500,
        fontSize: 'medium',
        padding: 0,
        margin: 0,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    title: {
        color: "grey",
        fontFamily: "Roboto"
    }
}));
