import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Tabs from '@material-ui/core/Tabs';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MoreVert from '@material-ui/icons/MoreVert';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import AddCircle from '@material-ui/icons/AddCircle';
import { CreateOrEditSectionPopup } from './Popups/SectionPopups';
import { DeletePopup } from './Popups/DeletePopups';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import NewComment from './NewComment';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ExpandedComment from "./ExpandedComment";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';

/*
Description: The componenet recursively displays all the comments
in the LibraryComment window

Data from props: section object, the section with all
the comments that we are going to display, and the library 
id is passed down as well

Functions from props: 
addComment(name, goodExample, badExample, description, 
    catergory, parentSection)

addSection(title, props.libraryId, props.section.id)

editComment(sectionId, title,
            explanation, incorrectExample, correctExample,
            category, commentId)

deleteComment(sectionId, commentId)

updateCommentOrder(list of comment ids)

setParentSections: allows us to specify what sub-section we are on by using ids
of any parent sections and child sections associated with the current sections
we have selected.

*/

export default function CommentDescription(props) {

    //Creates a list of comments stored localy, and creates the base list for the expandedComments variable
    useEffect(() => {
        let orderedComments = []
        props.section.commentOrder.map(id => orderedComments.push(props.section.comments.find(d => d.id == id)))
        setComments(orderedComments);
        let expanded = [];
        for (var i = 0; i < orderedComments.length; i++) {
            let currentId = orderedComments[i].id;
            let obj = { id: currentId, open: false };
            expanded.push(obj);
        }
        setExpandedComments(expanded);
    }, [props.section]);

    //Sets the current tab to be selected
    useEffect(() => {
        setTabsValue(categories.indexOf(props.selectedCommentCategory));
        if (props.commentIdForEdit) {
            if(props.commentIdForEdit != 'none'){
                const id = props.commentIdForEdit
                const foundComment = props.section.comments.find(d => d.id == id)
                const commentCopy = JSON.parse(JSON.stringify(foundComment));
                setEditComment(commentCopy);
                setCommentEditPopup(true);
                setFormType('Edit');
                const comment = commentCopy
                if (comment.type == null) {
                    props.editComment(props.section.id, comment.title, comment.explanation, comment.category, comment.id, 'Regular')
                }
                const values = {
                    category: comment.category,
                    commentText: comment.title,
                    commentExplanation: comment.explanation,
                    type: comment.type
                }
                setFormDefaultValues(values);
            }
        }
    }, [props.selectedCommentCategory]);

    //Style for the material-ui are set and for the title of this section
    const classes = useStyles();

    //list of comments for the provided section
    const [comments, setComments] = useState([]);
    //boolean used to track when the comments are being reordered
    const [isDragging, setIsDragging] = useState(false);
    //the current comment being reordered
    const [draggingComment, setDraggingComment] = useState(null);
    //the original category of the current comment being reordered
    const [draggingCommentOriginalCategory, setDraggingCommentOriginalCategory] = useState(null);
    //boolean used to show the delete popup
    const [commentDeletePopup, setCommentDeletePopup] = useState(false);
    //boolean used to show the edit comment popup
    const [commentEditPopup, setCommentEditPopup] = useState(false);
    //boolean used to show the move comment popup
    const [commentMovePopup, setCommentMovePopup] = useState(false);
    //this keeps track of the comment object that is being edited
    const [editComment, setEditComment] = useState(null);
    //this is the target button object used for refrence to display
    //the edit or delete comment popup
    const [anchorEl, setAnchorEl] = useState(null);
    //this object contains default comment values displayed in the comments popup
    //when the comment is being edited
    const [formDefaultValues, setFormDefaultValues] = useState({})
    //this is the target button object used for refrence to display
    //the new section or new comment popup
    const [sectionOrCommentPopup, setSectionOrCommentPopup] = useState(null);
    //boolean used to show the new comment popup 
    const [commentAddPopup, setCommentAddPopup] = useState(false);
    //boolean used to show the new section popup
    const [sectionAddPopup, setSectionAddPopup] = useState(false);
    //used to keep track of what tab is pressed and what
    //comments should be displayed based on the tab
    const [tabsValue, setTabsValue] = useState(0);
    //hard-coded categories for tabs
    const categories = ['Efficiency', 'Content', 'Style'];
    //keeps track of the type of popup that should be displayed
    //for comment and seciton popups
    const [formType, setFormType] = useState(null);
    //list of objects containing comment ids and if they have been expanded or not [{id: someId, open:false},...]
    const [expandedComments, setExpandedComments] = useState([])
    //used to hard refresh the ExpandedComment component
    const [update, setUpdate] = useState(0);
    //keeps track of the reordering mode
    const [reorder, setReorder] = useState(false);

    //changes the current tab displayed after a click
    const handleChange = (event, newTabsValue) => {
        setTabsValue(newTabsValue);
    };

    //displays the edit/delete comment popup if anchorEl is defined
    const openEditOrDelete = Boolean(anchorEl);
    //sets the id of the edit/delete comment popup if the MoreVert button
    //was pressed 
    const idForEditOrDelete = openEditOrDelete ? 'simple-popover' : undefined;

    //When the MoreVert button is pressed for a specific commment.
    //The comment is taken down as editComment object, and the 
    //anchorEl target button is also set to display the edit/delete
    //comment popup.
    const handleCommentEditOrDeletePopup = (event, comment) => {
        const commentCopy = JSON.parse(JSON.stringify(comment));
        setEditComment(commentCopy);
        setAnchorEl(event.currentTarget);
    }

    //Once the edit comment button is pressed in the edit/delete
    //popup, the existing values of this comment are passed to the
    //comment popup and a formType is set to indicate that a comment
    //needs to be edited. And the comment popup boolean is set to
    //true in order to display it.
    const handleEditPressedComment = () => {
        handleRequestCloseEditOrDelete();
        setCommentEditPopup(true);
        setFormType('Edit');
        const comment = editComment
        if (comment.type == null) {
            props.editComment(props.section.id, comment.title, comment.explanation, comment.category, editComment.id, 'Regular')
        }
        const values = {
            category: comment.category,
            commentText: comment.title,
            commentExplanation: comment.explanation,
            type: comment.type
        }
        setFormDefaultValues(values);
    };

    //Once the delete comment button is pressed in the edit/delete
    //popup, the boolean for delete popup is set to true in order to
    //display it.
    const handleDeletePressedComment = () => {
        handleRequestCloseEditOrDelete();
        setCommentDeletePopup(true);
    };

    //Once the move comment button is pressed in the popup,
    //set the boolean to display the move comment popup.
    const handleMovePressedComment = () => {
        if(editComment) {
            setCommentMovePopup(true);
            handleRequestCloseEditOrDelete(); //does something idk
        }
    }

    //This is the funciton that is passed down to the comment
    //popup when a comment is being edited. Edits the comment.
    function handleEditComment(category, title, explanation, type) {
        props.editComment(props.section.id, title, explanation, category, editComment.id, type);
    }

    //This is the function that is passed down to the delete
    //popup to delete the comment.
    function handleDeleteComment() {
        props.deleteComment(props.section.id, editComment.id)
    }

    //Thjis is the function that is passed down to the move
    //popup to move the comment
    function handleMoveComment(comment, srcSectionPath, destSectionPath){
        if(comment && srcSectionPath && destSectionPath) {
            setCommentMovePopup(false);
            props.moveComment(comment,srcSectionPath, destSectionPath);
        }
    }

    //This function is called when comment button is pressed in
    //the new section/comment popup. It set's the formType and 
    //sets the boolean for the comment popup to true so it could
    //be displayed.
    function handleNewCommentPopup(type) {
        handleRequestCloseSectionOrComment();
        setFormType(type);
        setCommentAddPopup(true);
    }

    //sets the reordreding mode on
    function handleNewOrder() {
        collapseExpanded()
        setReorder(true);
    }

    //sets the reordering mode off
    function saveOrder() {
        collapseExpanded()
        setReorder(false);
    }

    //This function is called when sub-section button is pressed in
    //the new section/comment popup. It set's the formType and 
    //sets the boolean for the section popup to true so it could
    //be displayed.
    function handleNewSectionPopup(type) {
        handleRequestCloseSectionOrComment();
        setFormType(type);
        setSectionAddPopup(true);
    }

    //Closes the popover for Edit/Delete comment button
    //when edit is selected, so that NewComment
    //componenet can be displayed
    function handleRequestCloseEditOrDelete() {
        setAnchorEl(null);
    }

    //Closes the popover for new Section/Comment comment button
    //when comment is selected, so that NewComment
    //componenet can be displayed
    function handleRequestCloseSectionOrComment() {
        setSectionOrCommentPopup(null);
    };

    //This function is called when "New" button is pressed
    //to display the new section/comment popup by setting
    //the sectionOrCommentPopup boolean to the button target
    //value.
    function handleAddCommentOrSection(event) {
        setSectionOrCommentPopup(event.currentTarget);
    }

    //This is the function that is passed to the section popup
    //when a new section is being created.
    function handleNewSection(title) {
        props.addSection(title, props.libraryId, props.section.id);
    }

    //This is the function that is passed to the comment popup
    //when a new comment is being created.
    function handleNewComment(category, commentText, commentExplanation, type) {
        props.addComment(props.section.id, commentText, commentExplanation, category, type);
        setCommentAddPopup(false);
    }

    //handle a comment being dragged and released 
    function onDragEnd(result) {
        // dropped outside the list
        setIsDragging(false);
        if (draggingComment.category != draggingCommentOriginalCategory) {
            props.editComment(props.section.id, draggingComment.title,
                draggingComment.explanation, draggingComment.category, draggingComment.id, draggingComment.type);
        }
        setDraggingComment(null);
        if (!result.destination) {
            return;
        }
        const reorderedComments = reorderComments(
            comments,
            result.source.index,
            result.destination.index
        );
        let draggingCommentIndex = reorderedComments.findIndex(comment => comment.id == draggingComment.id);
        reorderedComments[draggingCommentIndex].category = draggingComment.category;

        props.updateCommentOrder(reorderedComments);
        setComments(reorderedComments);
    };
    // a little function to help us with reordering the result of a drag
    const reorderComments = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    //removes all initiated tinymce editors
    async function collapseExpanded() {
        window.tinymce.remove()
        await expandedComments.map(function (y) {
            y.open = false
        })
        await setUpdate(update + 1)
    }

    //runs command when a comment is first dragged
    function onDragStart(result) {
        const comment = comments.find(comment => comment.id == result.draggableId)
        setDraggingComment(comment);
        setDraggingCommentOriginalCategory(comment.category);
        setIsDragging(true);
    }

    //allows for a comment to be dragged across categories
    function onTabMouseOver(index) {
        if (isDragging) {
            draggingComment.category = categories[index];
            setTabsValue(index);
        }
    }

    //keeps track of what comments have been expanded in the past
    const handleAccordionChange = (id) => (event, newExpanded) => {
        if (newExpanded) {
            expandedComments.find(y => y.id == id).open = true;
        } else {
            expandedComments.find(y => y.id == id).open = false;
        }
        let u = update + 1;
        setUpdate(u);
    };

    //displays the new section/comment popup if sectionOrCommentPopup is defined
    const openSecitonOrComment = Boolean(sectionOrCommentPopup);
    //sets the id of the new section/comment popup if the "New"button
    //was pressed 
    const idForSectionOrComment = openSecitonOrComment ? 'simple-popover' : undefined;

    // Rendering for the popover to create a new comment/section
    function renderNewCommentSectionPopover() {
        return (
            <div>
                {!commentAddPopup &&
                    <Button
                        onClick={(event) => handleNewCommentPopup("Create")}
                        variant="contained"
                        aria-describedby={idForSectionOrComment}
                        className={classes.newSectionButton}>
                        New
                </Button>}
                <Popover
                    id={idForSectionOrComment}
                    open={openSecitonOrComment}
                    anchorEl={sectionOrCommentPopup}
                    onClose={() => setSectionOrCommentPopup(null)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <List>
                        <ListItem button onClick={() => handleNewSectionPopup("Create")} key={0} className={classes.listItems}>
                            <ListItemText primary='Sub-section' />
                        </ListItem>
                        <ListItem autoFocus button onClick={() => handleNewCommentPopup("Create")} key={1} className={classes.listItems}>
                            <ListItemText primary='Comment' />
                        </ListItem>
                    </List>
                </Popover>
                {commentAddPopup &&
                    <NewComment
                        formType={formType}
                        handleComment={handleNewComment}
                        closeWindow={() => setCommentAddPopup(false)} />}
                {sectionAddPopup &&
                    <CreateOrEditSectionPopup
                        formType={formType}
                        handleSection={handleNewSection}
                        closeWindow={() => setSectionAddPopup(false)} />}
            </div>
        );
    }


    // Rendering for the popover to edit/delete a comment
    function renderEditDeleteCommentPopover(comment) {
        return (
            <div onClick={(event) => { event.stopPropagation() }}>
                <IconButton
                    aria-label="delete"
                    aria-describedby={idForEditOrDelete}
                    size="medium"
                    onClick={
                        (event) => handleCommentEditOrDeletePopup(event, comment)}>
                    <MoreVert />
                </IconButton>
                <Popover
                    id={idForEditOrDelete}
                    open={openEditOrDelete}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <List>
                        <ListItem button onClick={() => handleMovePressedComment(comment)} key={0} className={classes.listItems}>
                            <ListItemText primary='Move' />
                        </ListItem>
                        <ListItem button onClick={handleEditPressedComment} key={1} className={classes.listItems}>
                            <ListItemText primary='Edit' />
                        </ListItem>
                        <ListItem autoFocus button onClick={handleDeletePressedComment} key={2} className={classes.listItems}>
                            <ListItemText primary='Delete' />
                        </ListItem>
                    </List>
                </Popover>
                {
                    commentDeletePopup &&
                    <DeletePopup
                        permissionType={'owner'}
                        deleteType="Comment"
                        handleDelete={handleDeleteComment}
                        closeWindow={() => setCommentDeletePopup(false)}
                    />
                }
            </div >
        );
    }

    // Rendering of button for adding a new comment
    function renderNewCommentButton() {
        return (
            <div>
                {!commentAddPopup && !commentEditPopup && !reorder && 
                    <Button
                        className={classes.orderButton}
                        variant="contained"
                        onClick={() => handleNewOrder()} >
                        Change Order
                </Button>}
                {!commentAddPopup && !commentEditPopup && reorder &&
                    <Button
                        className={classes.orderButton}
                        variant="contained"
                        onClick={() => saveOrder()} >
                        Save Order
                </Button>}
                {!commentAddPopup && !commentEditPopup && !reorder &&
                    <Button
                        onClick={() => handleNewCommentPopup("Create")}
                        variant="contained"
                        className={classes.newCommentButton}>
                        New Comment
                </Button>}
                {commentAddPopup &&
                    <NewComment
                        formType={formType}
                        handleComment={handleNewComment}
                        closeWindow={() => setCommentAddPopup(false)} />}
            </div>
        );
    }

    const MoveCommentDialog = (args) => {

        const {open, handleMoveComment} = args
        const toggleList =[];
        const [selectedPath, setSelectedPath] = useState(null);
        const renderTree = (nodes, path) => {
            if(Array.isArray(nodes.sections) && nodes.sections.length !== 0){
                toggleList.push(`${path}/${nodes.id}`)
            };
            return (
                <TreeItem
                    key={`${path}/${nodes.id}`} nodeId={`${path}/${nodes.id}`} label={nodes.name} className={classes.treeItem}>
                    {Array.isArray(nodes.sections) ?
                            nodes.sections.map((node) => renderTree(node, `${path}/${nodes.id}`))
                        :
                        null}
                </TreeItem>
            )
        };
        const handleNodeSelect = (value) =>{
            if(!toggleList.includes(value) && value != props.section.id ){
                setSelectedPath(value);
            }else{
                setSelectedPath(null);
            }
        }


        return (
            <Dialog
                aria-labelledby="simple-dialog-title"
                open={open}
                onClose={()=>setCommentMovePopup(false)}
                className={classes.moveDialog}
            >
                <DialogTitle id="simple-dialog-title" className={classes.dialogTitle}>Move Comment</DialogTitle>
                <TreeView
                    className={classes.treeView}
                    defaultCollapseIcon={<ExpandMoreIcon/>}
                    defaultExpandIcon={<ChevronRightIcon/>}
                    defaultEndIcon={<FolderOpenIcon/>}
                    onNodeSelect={(event, value)=>handleNodeSelect(value)}>
                    {props.libraryData.libraryContent.sections.map(
                        i => renderTree(i, "")
                    )}
                </TreeView>

                <Button
                    variant={"contained"}
                    disabled={!selectedPath}
                    onClick={
                        ()=> {
                            //composes a formatted string from props.path
                            const {topSection, subSection, subSubSection} = props.path;
                            const srcSectionPath = `/${topSection}` + (subSection ? `/${subSection}` : ``) + (subSubSection ? `/${subSubSection}` : ``)

                            handleMoveComment(editComment, srcSectionPath, selectedPath);
                        }
                    }>
                    Move
                </Button>
            </Dialog>
        )
    }


    //render comment when they are not being dragged
    function renderComments() {
        return (
            <div>
                {comments.map((comment, index) => (
                    <TabPanel
                        key={comment.id}
                        value={tabsValue}
                        index={categories.indexOf(comment.category)}>
                        <CustomAccordion onChange={handleAccordionChange(comment.id)}>
                            <CustomAccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id={"accordion" + comment.id}
                                IconButtonProps={{ edge: 'start' }}
                            >
                                <Typography
                                    component={'span'}
                                    className={classes.summary}
                                >
                                    <div>{comment.title}</div>
                                    {renderEditDeleteCommentPopover(comment)}
                                </Typography>
                            </CustomAccordionSummary>
                            <Divider />
                            <AccordionDetails
                                className={classes.accordionDetails}>
                                <Typography
                                    component={'span'}
                                    style={{ width: '100%' }}
                                >
                                    <ExpandedComment
                                        update={update}
                                        comment={comment}
                                        display={expandedComments.find(y => y.id == comment.id)}
                                    />
                                </Typography>
                            </AccordionDetails>
                        </CustomAccordion>
                    </TabPanel>))}
            </div>
        );
    }

    // Renders the list of comments for when a user is reordering comments
    function renderDraggableComments() {
        return (
            <div>
                {comments.map((comment, index) => (
                    <Draggable key={comment.id} draggableId={comment.id.toString()} index={index}>
                        {(provided, snapshot) =>
                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                {renderSingleDraggableComment(comment)}
                                {provided.placeholder}
                            </div>
                        }
                    </Draggable>
                ))}
            </div>
        );
    }

    // Renders a single draggable comment
    function renderSingleDraggableComment(comment) {
        return (
            <TabPanel
                key={comment.id}
                value={tabsValue}
                index={categories.indexOf(comment.category)}>
                <CustomAccordion onChange={handleAccordionChange(comment.id)} disabled={true}>
                    <CustomAccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id={"accordion" + comment.id}
                        IconButtonProps={{ edge: 'start' }}
                    >
                        <Typography
                            component={'span'}
                            className={classes.summary}
                        >
                            <div>{comment.title}</div>
                            {renderEditDeleteCommentPopover(comment)}
                        </Typography>
                    </CustomAccordionSummary>
                </CustomAccordion>
            </TabPanel>
        );
    }

    // Rendering when there are no comments
    function renderEmptyCommentsView() {
        let displayNew = true;
        if (props.path.topSection != null && props.path.subSubSection != null) {
            displayNew = false;
        }
        return (
            <div>
                {displayNew ?
                    <div>
                        <Divider/>
                        <div className={classes.vacantSectionContainer}>
                            {renderNewCommentSectionPopover()}
                            {!commentAddPopup &&
                            <div className={classes.vacantSectionDisplay}>
                                <Button
                                        onClick={(event) => handleNewCommentPopup("Create")}
                                        variant="contained"
                                        aria-describedby={idForSectionOrComment}
                                        
                                        className={classes.vacantSectionDisplay}>
                                        <AddCircle className={classes.addCircleIcon} size="large"/>
                                </Button>   
                                <h1 className={classes.vacantSectionTitle}>Organize your Section</h1>
                                <p className={classes.vacantSectionParagraph}>Add comments</p>
                            </div>}
                        </div>
                    </div>
                    : renderCommentsView()}
            </div>
        );
    }

    // Rendering of when there are comments to display
    function renderCommentsView() {
        return (
            <div>
                {!commentAddPopup && !commentEditPopup &&
                    <StyledTabs value={tabsValue}
                        onChange={handleChange}
                        aria-label="styledTabs">
                        {categories.map(category => (
                            <StyledTab
                                key={categories.indexOf(category)}
                                onMouseOver={() => onTabMouseOver(categories.indexOf(category))}
                                label={category}
                                {...a11yProps(categories.indexOf(category))} />
                        ))}
                    </StyledTabs>}
                <Divider />
                {commentEditPopup &&
                    <NewComment
                        formType={formType}
                        handleComment={handleEditComment}
                        formDefaultValues={formDefaultValues}
                        closeWindow={() => setCommentEditPopup(false)}
                    />}
                {commentMovePopup &&
                    <MoveCommentDialog
                        id={editComment.id}
                        open ={commentMovePopup}
                        handleMoveComment={handleMoveComment}/>}
                <div className={classes.belowTabs}>
                    {renderNewCommentButton()}
                    {!commentAddPopup && !commentEditPopup && reorder &&
                        <div>
                            <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
                                <Droppable droppableId="droppable">
                                    {(provided, snapshot) =>
                                        <div ref={provided.innerRef}>
                                            {renderDraggableComments()}
                                            {provided.placeholder}
                                        </div>
                                    }
                                </Droppable>
                            </DragDropContext>
                        </div>}
                    {!commentAddPopup && !commentEditPopup && !reorder && renderComments()}
                </div>
            </div >
        );
    }

    return (
        <div>
            {comments.length == 0 ?
                renderEmptyCommentsView()
                :
                renderCommentsView()
            }
        </div >
    );
}

//Styling for all of the custom componenets
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    accordionDetails: {
        marginTop: '1%'
    },
    summary: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: 'grey'
    },
    newCommentButton: {
        float: 'right',
        textTransform: 'none',
        marginBottom: '1%',
        boxShadow: 'none',
    },
    orderButton: {
        float: 'left',
        textTransform: 'none',
        marginBottom: '1%',
        boxShadow: 'none',
    },
    newSectionButton: {
        float: 'right',
        textTransform: 'none',
        marginTop: '2%',
        boxShadow: 'none',
        marginBottom: '2%'
    },
    belowTabs: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        marginTop: '2%',
        //width: 650
        width: '100%'
    },
    vacantSectionContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    vacantSectionDisplay: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: 'auto',
    },
    vacantSectionTitle: {
        fontWeight: 500,
        fontSize: 'large',
        color: 'grey',
        marginBottom: '0%'
    },
    listItems: {
        paddingTop: 0,
        paddingBottom: 0
    },
    vacantSectionParagraph: {
        fontWeight: 500,
        fontSize: 'small',
        color: 'Silver',
        marginTop: '0%'
    },
    addCircleIcon: {
        color: 'Silver',
        fontWeight: 500,
        fontSize: 50,
    },
    tabPanel: {
        width: "100%"
    },
    box: {
        padding: "1%",
    },
    dialogTitle:{
        paddingBottom:'0.4rem'
    },
    treeView:{
        padding:'0.5rem',
        color:'black',
    },
    treeItem:{
        padding:'0.4rem',
        margin:'0.2rem',
        border:'1px solid silver',
        borderRadius:'5px'
    }
}));

const StyledTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        color: 'black',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),
        '&:focus': {
            opacity: 1,
        },
    },
}))((props) => <Tab disableRipple {...props} />);

const StyledTabs = withStyles({
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        '& > span': {
            maxWidth: 40,
            width: '100%',
            backgroundColor: 'grey',
        },
    },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

//Specifies the TabPanel component that is being used
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const classes = useStyles();
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
            className={classes.tabPanel}
        >
            <Box p={3} className={classes.box}>
                {children}
            </Box>
        </div>
    );
}

//Props specification for TabPanel
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

//Creates an id and sets some style settings 
//for a StyledTab.
//Allows the ability to scroll when there are too
//many comments to fit on the screen.
function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

const CustomAccordion = withStyles({
    root: {
        border: '1px solid silver',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: '0%',
        },
        margin: 0,
    },
    expanded: {
    },
})(MuiAccordion);

const CustomAccordionSummary = withStyles({
    root: {
        height: 40,
        marginBottom: -1,
        minHeight: 35,
        '&$expanded': {
            minHeight: 45,
        },
    },
    content: {
        '&$expanded': {
            margin: '0 0',
        },
    },
    expandIcon: {
        order: -1
    },
    expanded: {},
})(MuiAccordionSummary);