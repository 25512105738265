import React from "react";
import DescriptionPage from "./DescriptionPage";
import UsePage from "./UsePage";
import InstallPage from "./InstallPage";
import WorkingPage from "./WorkingPage";
import FrontPage from "./FrontPage";
import LoginBar from "./LoginBar";
import Grid from '@material-ui/core/Grid'
import {
    pageViewsTracking
} from '../../GAEvents';

export default function HomePage(props) {

    return (
        <div>
            {pageViewsTracking('/')}
            <Grid container
                alignItems = 'center'
                justify = 'center'
                alignContent = 'center'
                style = {{minHeight: "50vh"}}
            >
                <Grid item xs={12}>
                    <LoginBar/>
                </Grid>
                <Grid item xs={12}
                    justify = "center"
                    align = "center"
                >
                    <FrontPage/>
                </Grid>
                <Grid item xs={12}
                    justify = "center"
                    align = "center"
                >
                    <DescriptionPage/>
                </Grid>
                <Grid item xs={12}
                    justify = "center"
                    align = "center"
                >
                    <WorkingPage/>
                </Grid>
                <Grid item xs={12}
                    justify = "center"
                    align = "center"
                >
                    <UsePage/>
                </Grid>
                <Grid item xs={12}
                    justify = "center"
                    align = "center"
                >
                    <InstallPage/>
                </Grid>
            </Grid>
        </div>
    );
}
