const contents = [
    {
        title: "Getting an ECoS Account",
        steps: [
            {
                step: "Go to https://ecostools.com/",
            },
            {
                step: 'Click the "Sign up" button on the top right.',
            },
            {
                step: "Log into ECoS with a Google Account.",
            },
        ],
    },
    {
        title: "Downloading and using the ECoS addin in Microsoft Word",
        steps: [
            {
                step: "Go to https://ecostools.com/",
            },
            {
                step: 'Click "Get it now"',
            },
            {
                step: 'Open up Word, find the "ECoS App" Button on the Home tab, and log into your ECoS account.',
            },
        ],
    },
    {
        title: "Getting access to the main ECoS library",
        steps: [
            {
                step: "Open up Word and choose the ECoS library that you were shared.",
            },
            {
                step: "Make sure you have downloaded the ECoS addin.",
            },
            {
                step: "Make sure you have an ECoS account.",
            },
            {
                step: "Go to https://ecostools.com/ and log in",
            },
            {
                step: "Reach out to the owner of the main library that you need.",
            },
        ],
    },
    {
        title: "Editing a Comment",
        steps: [
            {
                step: "Choose the library you want to edit.",
            },
            {
                step: 'Find a comment you want to edit OR navigate to the right place and click "New Comment".',
            },
            {
                step: 'Be careful to thematically match how we create comments, and do your best to retain our format(s). For instance, keep the "Explanation", "Examples of Correct Use", "Examples of Incorrect Use", "Additional Materials" structure.',
            },
            {
                step: "Add, edit, and remove content as necessary. Save.",
            },
        ],
    },
    {
        title: "Manually Storing and Restoring Backups of Library",
        steps: [
            {
                step: "Click on the three dots on the library you want to backup",
            },
            {
                step: 'Select "Download Backup". This will download a copy of the existing library to your device',
            },
            {
                step: 'To restore, select the three dots and then "Restore Backup". This will open a file explorer in which you can select the downloaded copy of the library',
            },
        ],
    },
    {
        title: "Creating a Different Version of a Library with New Name",
        steps: [
            {
                step: 'If you haven’t already, download a backup of the library you want (See "Manually Storing and Restoring Backups of Library")',
            },
            {
                step: 'Select "New Library" on your Dashboard, followed by “Upload"',
            },
            {
                step: "Once the file has uploaded and you receive confirmation, select the three dots on the library you just created",
            },
            {
                step: 'Select "Edit" and change the name of the library to whatever you would like',
            },
        ],
    },
    {
        title: "Sharing a Copy of a Library",
        steps: [
            {
                step: 'Click on the three dots on the library you want to share a copy of and click on "Share a Copy"',
            },
            {
                step: "You can share a copy with yourself if you want to save the current state of the library as another instance, or you can share the current state of the library with someone else",
            },
            {
                step: 'Enter the email of who you want to share the library with and after clicking the "Share" button you should get a success message afterwards',
            },
            {
                step: "The shared copy is an entirely different instance of the library, and any changes to the shared copy will not be reflected in the original library. ",
            },
        ],
    },
    {
        title: "Change the order of sections and comments in a Library",
        steps: [
            {
                step: "Choose the library you want to edit.",
            },
            {
                title: "For sections",
                steps: [
                    {
                        step: 'Click "Change Order"',
                    },
                    {
                        step: "Drag the section names to reorder to desire",
                    },
                    {
                        step: 'Click "Save Order"',
                    },
                ],
            },
            {
                title: "For subsections",
                steps: [
                    {
                        step: "Click the section you want to reorder subsections",
                    },
                    {
                        step: 'Click "Change Order"',
                    },
                    {
                        step: "Drag the section names to reorder to desire",
                    },
                    {
                        step: 'Click "Save Order"',
                    },
                ],
            },
            {
                title: "For comments",
                steps: [
                    {
                        step: "Click the subsection you want to reorder comments",
                    },
                    {
                        step: 'Click "Change Order"',
                    },
                    {
                        step: "Drag the section names to reorder to desire",
                    },
                    {
                        step: 'Click "Save Order"',
                    },
                ],
            },
        ],
    },
    {
        title: "Move a comment to different sections",
        steps: [
            {
                step: "Choose the library and section you want to move the comment",
            },
            {
                step: "Click the three-dots button on the right of the comment",
            },
            {
                step: 'Click "Move"',
            },
            {
                step: "Choose the targeted section you want to move the comment",
            },
            {
                step: 'Click "Move"',
            },
        ],
    },
];

export default contents;
