// src/App.js

import React, { useState, useEffect } from 'react';
import LoginPage from './components/LoginPage';
import { useAuth0 } from './react-auth0-spa';
import { Dash } from './components/Dash';
import PrivateRoute from "./components/PrivateRoute";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
  useHistory
} from "react-router-dom";
import { EULA } from './components/legal/EULA';
import { Support } from './components/legal/Support';
import { PrivacyPolicy } from './components/legal/PrivacyPolicy';
import { TermsAndConditions } from './components/legal/TermsAndConditions';
import CategoryCommentView from './components/LinkedFromAddIn/CategoryCommentView'
import { SingleCommentView } from './components/LinkedFromAddIn/SingleCommeView';
import HomePage from './components/homepagecomponents/HomePage';
import ReactGA from "react-ga";
import Tutorials from './components/Tutorials';

function App() {

  useEffect(() => {
    ReactGA.initialize('UA-211289178-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])

  const { isAuthenticated, loading, getIdTokenClaims, user } = useAuth0();

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App" /*style={{minWidth: '1340px'}}*/>
      <header>
        <Router>
          <Switch>
            <Route exact path="/">
              {/*<NavBar />*/}
              <HomePage />
            </Route>

            <Route exact path="/eula">
              <EULA />
            </Route>

            <Route exact path="/support">
              <Support />
            </Route>

            <Route exact path="/tutorials">
              <Tutorials />
            </Route>

            <Route exact path="/privacy-policy">
              <PrivacyPolicy />
            </Route>

            <Route exact path="/terms-and-conditions">
              <TermsAndConditions />
            </Route>

            <PrivateRoute exact path="/libraries" component={Dash} />

            <PrivateRoute exact path="/profile" component={Dash} />
            
            <PrivateRoute exact path="/libraries/:libraryId" component={Dash} />

            <PrivateRoute exact path="/libraries/:libraryId/:sectionId" component={Dash} />
            <PrivateRoute exact path="/libraries/:libraryId/:sectionId/category/:categoryId/:commentIdOrNone" component={Dash} />

            <PrivateRoute exact path="/libraries/:libraryId/:sectionId/:sectionId" component={Dash} />
            <PrivateRoute exact path="/libraries/:libraryId/:sectionId/:sectionId/:sectionId" component={Dash} />

            <Route path='/link/comment/:libraryId/:sectionId/:commentId' component={SingleCommentView} />
            {/* <Route path='/link/:libraryId/:sectionId' component={CategoryCommentView} /> */}
            <Route path='/link/:libraryId/:sectionId/:category' component={CategoryCommentView} />
          </Switch>
        </Router>
      </header>
    </div>
  );
}

export default App;