import React, { useState, useEffect, useLayoutEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from "@material-ui/core/Typography";
import AddCircle from '@material-ui/icons/AddCircle';

/*
Description: The componenet renders the recursive view of all the sections
in navigation view.

Data from props: 

sections, sections to be rendered in the navigation view

parentId, id of the parent section under which the given section
will be rendered

navKey, a variable that hard refreshes this component when new props are
given to the LibraryNavigation, parent component of this one.

selectedSection, the currently selected section displayed on the
right hand side of SectionTab

sectionOrder, existing list of section ids that give the order of how
the section should be displayed

update, a string used to distinguish how far down in the section
accordions we are trying to render the given sections

granId, the id of the parent section to the current section's parent
so a grandparent id.

Functions from props: 

sectionSelected, used to update the newly selected section in the navigations

handleSectionClick, re-rooutes and runs sectionSelected using the LibraryNavigation
instead of directly calling sectionSelected because this will hard update the
right side of the SectionTab which sectionSelected won't if called here.

handleCreateSection, makes new section

updateSectionOrder, updates order of the sub-sections or sub-sub-sections 
within a section

updateLibrarySectionOrder, updates order of the top most sections by updating
the section list contained in the library content
*/

export default function NavigationSections(props) {

    // when new props are given by the parent component (LibraryNavigation),
    // this will update the sectionOrder, selectedSection, currentParentPathId,
    // and currentSectionPathId varibles
    useEffect(() => {
        getSectionsInOrder()
        setSelectedSection(props.selectedSection)
        let parentid = null
        let sectionid = null
        if (props.update == "library") {
        } else if (props.update == "sub-section") {
            parentid = props.parentId
        }
        setCurrentParentPathId(parentid)
        setCurrentSectionPathId(sectionid)
    }, [props]);

    //initiates the classes we use for styling
    const classes = useStyles();

    // Array of sections in order that is given by the
    // order of props.sectionOrder ids.
    const [sectionOrder, setSectionOrder] = useState([]);
    // Varibale used to keep track of when a user is trying
    // to change the order of the section displayed by this component
    const [reorder, setReorder] = useState(false);
    // The section the user has previously selected and which is 
    // currently displayed on the right side of SectionTab
    const [selectedSection, setSelectedSection] = useState();
    // The id of the section for which we are rendering the sub-sections
    // for
    const [currentSectionPathId, setCurrentSectionPathId] = useState();
    // The id of the parent to the section we are displaying sub-section
    // for
    const [currentParentPathId, setCurrentParentPathId] = useState();

    // Retrieves the sectionOrder given by props and sets the
    // sectionOrder array used in this component.
    async function getSectionsInOrder() {
        let len = props.sectionOrder.length
        let order = []
        for (var i = 0; i < len; i++) {
            let d = props.sectionOrder[i];
            if (!!props.sections.find(y => y.id == d)) {    
                order.push(d)
            } 
        }
        if ( order.length < props.sections.length) {
            let orderedSections = []
            order.map(id => orderedSections.push(props.sections.find(d => d.id == id)))
            let sectionsLength = props.sections.length;
            for (var i = 0; i < sectionsLength; i++) {
                let sec = props.sections[i];
                if (!orderedSections.find(y => y.id == sec.id)) {
                    orderedSections.push(sec)
                }
            }
            if (props.update == "library") {
                props.updateLibrarySectionOrder(orderedSections)
            } else {
                props.updateSectionOrder(orderedSections, currentSectionPathId, currentParentPathId, null, props.parentId)
            }
            await setSectionOrder(orderedSections)
        } else if (order.length == 0 && props.sections.length == 0) {
            await setSectionOrder([])
        } else {
            let orderedSections = []
            order.map(id => orderedSections.push(props.sections.find(d => d.id == id)))
            await setSectionOrder(orderedSections);
        }
    }

    // Updates appropriate secitonOrders of the library
    // when the user stopped dragging section around
    function handleOnDragEnd(result) {
        // handles section dragged out of ends, returns
        // it to original position
        if (!result.destination) {
            return;
        }
        // updates sections order
        const items = Array.from(sectionOrder);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setSectionOrder(items);
        if (props.update == "library") {
            props.updateLibrarySectionOrder(items)
        } else {
            props.updateSectionOrder(items, currentSectionPathId, currentParentPathId, null, props.parentId)
        }
    }

    // Called when "Change Order" button is clicked
    function handleNewOrder() {
        setReorder(true);
    }

    // Called when "Set Order" button is clicked
    function saveOrder() {
        if (props.update != "library") {
            props.finalOrderUpdate(props.parentId)
        }
        setReorder(false);
    }

    //Load a subsection with no child sections
    function renderSubSubSection(section, granId, parentId) {
        return (
            <div key={section.id} className={classes.subSubContainer}>
                <Button
                    className={classes.subSubButton}
                    component={'span'}
                    onClick={() => props.handleSectionClick(granId, parentId, section.id, section)}
                >
                    {section.name}
                </Button>
            </div>

        );
    }

    // Renders a section that will never have sub-sections
    function renderEmptySection(section) {
        let parentid = null
        let sectionid = null
        let subid = null
        let add = "New Sub-Sub-Section"
        let create = "Sub-Sub-Section"
        let addSectionId = null
        let addParentId = null
        if (props.update == "library") {
            create = "Sub-Section"
            add = "New Sub-Section"
            parentid = section.id
            addSectionId = section.id
        } else {
            addParentId = props.parentId
            addSectionId = section.id
            parentid = props.parentId
            sectionid = section.id
        }
        return (
            <div key={section.id}>
                {section.comments == 0 ?
                    <CustomAccordion
                        key={section.id}
                        onChange={() => props.handleSectionClick(parentid, sectionid, subid, section)}
                    >
                        <CustomAccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1d-content"
                            IconButtonProps={{ edge: 'start' }}
                        >
                            <Typography
                                className={classes.typography}
                                component={'span'}
                            >
                                {section.name}
                            </Typography>
                        </CustomAccordionSummary>
                        <CustomAccordionDetails >
                            <div className={classes.addFirstButtonContainer}>
                                <Button
                                    startIcon={<AddCircle className={classes.addIcon} />}
                                    variant="contained" className={classes.addSubButton}
                                    onClick={() => props.handleCreateSection(create, addSectionId, addParentId, subid)}>
                                    {add}
                                </Button>
                            </div>
                        </CustomAccordionDetails>
                    </CustomAccordion>
                    :
                    <div key={section.id} className={classes.sectionButton}>
                        <Button
                            className={classes.sectionButton}
                            component={'span'}
                            onClick={() => props.handleSectionClick(parentid, sectionid, subid, section)}
                        >
                            {section.name}
                        </Button>
                    </div>
                }
            </div>
        );
    }

    // Renders content for the section that may have sub-sections
    function renderSectionContent(section, selectedSection) {
        let parentid = null
        let sectionid = null
        let subid = null
        let add = "New Sub-Sub-Section"
        let create = "Sub-Sub-Section"
        let nextUpdate = "sub-sub-section"
        let addSectionId = null
        let addParentId = null
        if (props.update == "library") {
            create = "Sub-Section"
            add = "New Sub-Section"
            parentid = section.id
            addSectionId = section.id
            nextUpdate = "sub-section"
        } else {
            addParentId = props.parentId
            addSectionId = section.id
            parentid = props.parentId
            sectionid = section.id
        }
        return (
            <div key={section.id}>
                {section.comments == 0 ?
                    <CustomAccordion
                        onChange={() => props.handleSectionClick(parentid, sectionid, subid, section)}
                        key={section.id}
                    >
                        <CustomAccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1d-content"
                            IconButtonProps={{ edge: 'start' }}
                        >
                            <Typography
                                className={classes.typography}
                                component={'span'}
                            >
                                {section.name}
                            </Typography>
                        </CustomAccordionSummary>
                        <CustomAccordionDetails >
                            {!!props.sections.find(y => y.id == section.id) &&
                            <NavigationSections
                                sections={props.sections.find(y => y.id == section.id).sections}
                                parentId={section.id}
                                navKey={props.navKey}
                                selectedSection={props.selectedSection}
                                sectionOrder={props.sections.find(y => y.id == section.id).sectionOrder}
                                update={nextUpdate}
                                sectionSelected={props.sectionSelected}
                                handleSectionClick={props.handleSectionClick}
                                handleCreateSection={props.handleCreateSection}
                                updateSectionOrder={props.updateSectionOrder}
                                granId={props.parentId}
                                finalOrderUpdate={props.finalOrderUpdate}
                            />}
                            <div className={classes.addFirstButtonContainer}>
                                <Button
                                    startIcon={<AddCircle className={classes.addIcon} />}
                                    variant="contained" className={classes.addSubButton}
                                    onClick={() => props.handleCreateSection(create, addSectionId, addParentId, subid)}>
                                    {add}
                                </Button>
                            </div>
                        </CustomAccordionDetails>
                    </CustomAccordion>
                    :
                    <div key={section.id}>
                        <Button
                            className={classes.sectionButton}
                            component={'span'}
                            onClick={() => props.handleSectionClick(parentid, sectionid, subid, section)}
                        >
                            {section.name}
                        </Button>
                    </div>
                }
            </div>
        );
    }

    // Renders sections when they are being reordered and must be disabled 
    // so there would not be any bugs
    function renderDisabledSection(section, selectedSection) {
        return (
            <div key={section.id}>
                {section.comments == 0 ?
                    <CustomAccordion
                        key={section.id}
                        disabled={true}
                    >
                        <CustomAccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1d-content"
                            IconButtonProps={{ edge: 'start' }}
                        >
                            <Typography
                                className={classes.typography}
                                component={'span'}
                            >
                                {section.name}
                            </Typography>
                        </CustomAccordionSummary>
                        <CustomAccordionDetails >
                        </CustomAccordionDetails>
                    </CustomAccordion>
                    :
                    <div key={section.id} className={classes.sectionButton}>
                        <Button
                            variant="contained"
                            disabled={true}
                            component={'span'}
                        >
                            {section.name}
                        </Button>
                    </div>
                }
            </div>
        );
    }

    // Renders a given section
    function renderSection(section, selectedSection) {
        //When sub-section has no sub-sub-sections
        if (section.sections.length === 0 || section.sections == undefined) {
            return (
                <div>
                    {renderEmptySection(section)}
                </div>
            )
        } else {
            return (
                <div>
                    {renderSectionContent(section, selectedSection)}
                </div>
            )
        }
    }

    // Renders the list of section given by props
    function renderAllSections() {
        if (sectionOrder.length < 1 || !sectionOrder) {
            return (
                <div></div>
            )
        } else if (reorder) {
            return (
                <div className={classes.section}>
                    {sectionOrder.map((section, index) => (
                        <Draggable key={section.id} draggableId={section.id.toString()} index={index}>
                            {(provided, snapshot) =>
                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                    {renderDisabledSection(section, selectedSection)}
                                    {provided.placeholder}
                                </div>
                            }
                        </Draggable>
                    ))}
                </div>
            )
        } else if (props.update == "sub-sub-section") {
            return (
                <div className={classes.section}>
                    {sectionOrder.map((section, index) => (
                        <div key={section.id + currentSectionPathId}>
                            {section &&
                                <div key={section.id}>
                                    {renderSubSubSection(section, props.granId, props.parentId)}
                                </div>
                            }
                        </div>
                    ))}
                </div>
            )
        } else {
            return (
                <div className={classes.section}>
                    {sectionOrder.map((section, index) => (
                        <div key={section.id + currentSectionPathId}>
                            {section &&
                                <div key={section.id}>
                                    {renderSection(section, selectedSection)}
                                </div>
                            }
                        </div>
                    ))}
                </div>
            )
        }
    }

    return (
        <div stuff={props.navKey}>
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) =>
                        <div ref={provided.innerRef}>
                            {renderAllSections()}
                            {provided.placeholder}
                        </div>
                    }
                </Droppable>
            </DragDropContext>
            {!reorder &&
                <Button
                    className={classes.orderButton}
                    variant="contained"
                    onClick={() => handleNewOrder()} >
                    Change Order
            </Button>}
            {reorder &&
                <Button
                    className={classes.orderButton}
                    variant="contained"
                    onClick={() => saveOrder()} >
                    Save Order
            </Button>}
        </div >
    );
}

//styling
const useStyles = makeStyles((theme) => ({
    addSubButton: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        color: 'black',
        "&:hover": {
            backgroundColor: 'transparent',
            boxShadow: 'none',
        },
        paddingTop: '6px',
        paddingLeft: '7px',
        textTransform: "none",
        fontSize: '85%',
    },
    sectionButtonDisabled: {
        boxShadow: 'none',
        fontWeight: theme.typography.fontWeightRegular,
        textTransform: "none",
        width: '100%',
        padding: 0,
        marginTop: '1%',
        marginBottom: '1%',
        display: 'inline-block',
        fontSize: '100%'
    },
    addButton: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        color: 'black',
        "&:hover": {
            backgroundColor: 'transparent',
            boxShadow: 'none',
        },
        paddingTop: '6px',
        paddingLeft: '7px',
        textTransform: "none",
        fontSize: '85%',
    },
    addIcon: {
        color: "#484848",
    },
    orderButton: {
        textTransform: 'none',
        marginBottom: '1%',
        boxShadow: 'none',
    },
    section: {
        dislay: 'flex',
        flexDirection: 'column',
        justifyContent: 'left',
    },
    addFirstButtonContainer: {
        textTransform: "none",
        width: '100%',
        padding: 0,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    sectionButton: {
        boxShadow: 'none',
        fontWeight: theme.typography.fontWeightRegular,
        textTransform: "none",
        "&:hover": {
            backgroundColor: 'transparent',
            boxShadow: 'none',
        },
        width: '100%',
        padding: 0,
        marginTop: '1%',
        marginBottom: '1%',
        marginLeft: '2%',
        display: 'inline-block',
        fontSize: '100%'
    }
}));

const CustomAccordion = withStyles({
    root: {
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: '0%',
        },
        width: '100%',
        marginTop: '1%',
        marginBottom: '1%'
    },
    expanded: {},
})(MuiAccordion);

const CustomAccordionSummary = withStyles({
    root: {
        marginBottom: -1,
        minHeight: 45,
        width: '100%',
        '&$expanded': {
            minHeight: 56,
        },
        padding: 0
    },
    content: {
        '&$expanded': {
            margin: '0 0',
        },
    },
    expandIcon: {
        order: -1
    },
    expanded: {},
})(MuiAccordionSummary);

const CustomAccordionDetails = withStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        padding: "0px 16px 0px 16px",
        justifyContent: 'left',
        width: '100%',
    },
}))(MuiAccordionDetails);
