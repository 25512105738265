import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import Card from '@material-ui/core/Card';

export default function DescriptionPage(props) {
    const classes = useStyles();
    const title = "What is ECoS?";
    const description = "ECoS is an application that allows users to review " +
                        "technical documents more efficiently. A user can create " +
                        "a library of common errors that they encounter when " +
                        "reviewing documents and how to address these issues. " +
                        "The user can then quickly insert these comments to a " +
                        "document when reviewing without having to type out the " +
                        "comment each time.";
    return (
        <div>
            <Grid container 
                direction="row"
                justify="center"
                alignItems="center" 
                className={classes.root}
            >
                <Grid item xs={9} className={classes.grid}>
                    <Typography className={classes.title}>{title}</Typography>
                    <Typography className={classes.description}>{description}</Typography>
                </Grid>
                <Grid item xs={6} className={classes.grid}>
                    <Card className={classes.card}>
                        <CardMedia 
                            className={classes.media}
                            image='https://ecos-media.s3-us-west-2.amazonaws.com/logo-filled.png'
                        />
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      paddingTop: '100px',
      paddingRight: '100px',
      paddingLeft: '100px',
      paddingBottom: '100px',
    },
    title: {
        fontWeight: 700,
        color: '#1B0D6E',
        fontSize: 50,
        paddingBottom: '15px',
    },
    description: {
        fontSize: 25,
    },
    grid: {
        paddingLeft: '25px',
        paddingRight: '25px',
    },
    card: {
        boxShadow: 'none',
        paddingTop: '15px',
        maxHeight: 100,
        maxWidth: 100,
    },
    media: {
        paddingTop: '100%',
    }
  }));