import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import Card from '@material-ui/core/Card';

export default function UsePage(props) {
    const classes = useStyles();
    const title = "How do I use ECoS?";
    const websiteSubtitle = "The Website";
    const addInSubtitle = "The Add-In";
    const websiteDescription = "The website contains all of the comments and " +
                               "edits that users can integrate into the writing.";
    const addInDescription = "The add-in can be easily installed within Microsoft " +
                             "word. This is where comments can be integrated into.";
    return (
        <div id="use_page">
            <Grid container 
                direction="row"
                justify="center"
                alignItems="center" 
                className={classes.root}
            >
                <Grid item xs={12} className={classes.grid}>
                    <Typography className={classes.title}>{title}</Typography>
                </Grid>
                <Grid item xs={6} className={classes.grid}>
                    <Card className={classes.card}>
                        <CardMedia 
                            component='iframe'
                            className={classes.media}
                            src='https://www.youtube.com/embed/jBvRA4lEhj8'
                        />
                    </Card>
                </Grid>
                <Grid item xs={6} className={classes.grid}>
                    <Typography className={classes.subtitle}>{websiteSubtitle}</Typography>
                    <Typography className={classes.description}>{websiteDescription}</Typography>
                </Grid>
                <Grid item xs={6} className={classes.grid}>
                    <Card className={classes.card}>
                        <CardMedia 
                            component='iframe'
                            className={classes.media}
                            src='https://www.youtube.com/embed/kAKT8_-y8fQ'
                        />
                    </Card>
                </Grid>
                <Grid item xs={6} className={classes.grid}>
                    <Typography className={classes.subtitle}>{addInSubtitle}</Typography>
                    <Typography className={classes.description}>{addInDescription}</Typography>
                </Grid>
            </Grid>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      paddingTop: '100px',
      paddingRight: '100px',
      paddingLeft: '100px',
    },
    title: {
        fontWeight: 700,
        color: '#1B0D6E',
        fontSize: 50,
        paddingBottom: '15px',
    },
    subtitle: {
        fontWeight: 500,
        textDecoration: 'underline',
        fontSize: 30,
        paddingBottom: '5px',
    },
    grid: {
        paddingLeft: '25px',
        paddingRight: '25px',
        paddingBottom: '25px',
    },
    description: {
        fontSize: 25,
    },
    card: {
    },
    media: {
        height: '45.25vh'
    }
  }));