import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import MoreVert from '@material-ui/icons/MoreVert';
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import { DeletePopup } from './Popups/DeletePopups';
import { CreateOrEditLibraryPopup } from './Popups/LibraryPopups';
import { ShareLibraryPopup } from './Popups/ShareLibraryPopups';

/*
Description: a single card displaying a library in the my libraries

Data from props: libraryName, permission, type, libraryId

State: libraries
*/
export function MyLibrariesCard(props) {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);
    const openEditOrDelete = Boolean(anchorEl);

    const [libraryDeletePopup, setLibraryDeletePopup] = useState(false);
    const [libraryEditPopup, setLibraryEditPopup] = useState(false);
    const [librarySharePopup, setLibrarySharePopup] = useState(false);
    const [libraryShareCopyPopup, setLibraryShareCopyPopup] = useState(false);

    const [formDefaultValues, setFormDefaultValues] = useState(null);

    const handleCommentEditOrDeletePopup = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleDeletePressedLibrary = (event) => {
        setLibraryDeletePopup(true);
    };

    const handleEditPressedLibrary = (event) => {
        const values = {
            libraryName: props.libraryName,
            libraryDescription: props.description
        }
        setFormDefaultValues(values);
        setLibraryEditPopup(true);
    };

    const handleSharePressedLibrary = (event) => {
        setLibrarySharePopup(true);
    }

    const handleShareCopyPressedLibrary = (event) => {
        setLibraryShareCopyPopup(true);
    }

    const handleDownloadBackupPressedLibrary = (event) => {
        props.downloadBackupLibrary(props.libraryId);
        setAnchorEl(null);
    }

    const handleUploadBackupPressedLibrary = (event) => {
        const file = event.target.files[0];
        const extension = file.name.split('.').pop().toLowerCase();
        let libraryJSON = null;

        if (extension == "lib") {
            const reader = new FileReader();
            reader.onload = async (e) => {
                libraryJSON = e.target.result;
                console.log(libraryJSON);
                props.recoverBackupLibrary(libraryJSON, props.libraryId);
            };
            reader.readAsText(file);
        } else {
            props.recoverBackupLibrary(null, props.libraryId);
        }

        setAnchorEl(null);
    }

    const handleEditLibrary = (newLibraryName, newLibraryDescription) => {
        props.editExistingLibrary(props.libraryId, newLibraryName, newLibraryDescription);
    };

    const handlePopoverClose = (event) => {
        setAnchorEl(null)
    };

    const handleCloseLibraryDeletePopup = () => {
        setLibraryDeletePopup(false);
        setAnchorEl(null);
    }

    const handleCloseLibraryEditPopup = () => {
        setLibraryEditPopup(false);
        setAnchorEl(null);
    }

    const handleCloseLibrarySharePopup = () => {
        setLibrarySharePopup(false);
        setAnchorEl(null);
    }

    const handleCloseLibraryShareCopyPopup = () => {
        setLibraryShareCopyPopup(false);
        setAnchorEl(null);
    }

    // Renders the popover to edit/delete a library
    function renderEditDeleteLibraryPopover() {
        return (
            <div
                onClick={(event) => { event.stopPropagation() }}
                style={{ marginLeft: "90%" }}
            >
                <IconButton
                    aria-label="delete"
                    aria-describedby='simple-popover'
                    size="small"
                    onClick={(event) => handleCommentEditOrDeletePopup(event)}
                >
                    < MoreVert />
                </IconButton>
                <Popover
                    id='simple-popover'
                    open={openEditOrDelete}
                    anchorEl={anchorEl}
                    onClose={(event) => handlePopoverClose(event)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <List>
                        <ListItem button onClick={(event) => handleEditPressedLibrary(event)} key={0} className={classes.listItems}>
                            <ListItemText primary='Edit' />
                        </ListItem>
                        <ListItem button onClick={(event) => handleDeletePressedLibrary(event)} key={1} className={classes.listItems}>
                            <ListItemText primary='Delete' />
                        </ListItem>
                        <ListItem button onClick={(event) => handleSharePressedLibrary(event)} key={2} className={classes.listItems}>
                            <ListItemText primary='Share' />
                        </ListItem>
                        <ListItem button onClick={(event) => handleShareCopyPressedLibrary(event)} key={3} className={classes.listItems}>
                            <ListItemText primary='Share Copy' />
                        </ListItem>
                        <ListItem button onClick={(event) => handleDownloadBackupPressedLibrary(event)} key={4} className={classes.listItems}>
                            <ListItemText primary='Download Backup' />
                        </ListItem>
                        <ListItem button variant="contained" component="label" key={5} className={classes.listItems}>
                            <ListItemText primary='Restore Backup' />
                            <input type="file" hidden onChange={(event) => handleUploadBackupPressedLibrary(event)} />
                        </ListItem>
                    </List>
                </Popover>
                {
                    libraryDeletePopup && (<DeletePopup
                        permissionType={'owner'}
                        deleteType={props.libraryName}
                        handleDelete={props.deleteExistingLibrary}
                        closeWindow={handleCloseLibraryDeletePopup} />)
                }
                {
                    libraryEditPopup && (<CreateOrEditLibraryPopup
                        formDefaultValues={formDefaultValues}
                        formType='Edit'
                        closeWindow={handleCloseLibraryEditPopup}
                        handleLibrary={handleEditLibrary} />)
                }
                {
                    librarySharePopup && (<ShareLibraryPopup
                        libraryId={props.libraryId}
                        libraryName={props.libraryName}
                        shareExistingLibrary={props.shareExistingLibrary}
                        closeWindow={handleCloseLibrarySharePopup} />)
                }
                {
                    libraryShareCopyPopup && (<ShareLibraryPopup
                        copy
                        libraryId={props.libraryId}
                        libraryName={props.libraryName}
                        shareExistingLibraryCopy={props.shareCopyOfExistingLibrary}
                        closeWindow={handleCloseLibraryShareCopyPopup} />)
                }
            </div >
        );
    }

    // Renders the library's name and desription on the card
    function renderLibraryNameDescription() {
        return (
            <Box component="div" className={classes.box}>
                <LibraryNameTextTypography display='inline' variant='h5'>
                    {props.libraryName}
                </LibraryNameTextTypography>
                <br />
                <LibraryDescriptionTextTypography display='inline' variant='body2'>
                    {props.description}
                </LibraryDescriptionTextTypography>
            </Box>
        );
    }

    return (
        <React.Fragment>
            <Box
                className={classes.card}
                margin="20px 20px 0px 0px"
            >
                <Box ml="auto" mt={1}>
                    {renderEditDeleteLibraryPopover()}
                </Box>
                <Box mr="auto" mt="auto" ml={1} mb={1}>
                    {renderLibraryNameDescription()}
                </Box>
            </Box >
        </React.Fragment>
    );
}

const useStyles = makeStyles({
    card: {
        background: '#D1D1D1',
        width: "285px",
        height: "151px",
    },
    box: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        display: 'block',
        width: "270px",
    }
});

const LibraryNameTextTypography = withStyles({
    root: {
        color: '#000000',
    }
})(Typography);

const LibraryDescriptionTextTypography = withStyles({
    root: {
        color: '#5E5E5E',
    }
})(Typography);