import React, { useState } from "react";
import {
    Container,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Grid,
    Card,
    CardMedia,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import contents from "./Contents";
import LoginBar from "../homepagecomponents/LoginBar";

const Tutorials = () => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div className={classes.container}>
            <LoginBar color="white" />
            <Container>
                <>
                    <Typography variant="h4" className={classes.title}>
                        ECoS Tutorials
                    </Typography>
                    <div>
                        {contents.map((content, index) => (
                            <Accordion
                                key={index}
                                expanded={expanded === index}
                                onChange={handleChange(index)}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id={index}
                                >
                                    <Typography
                                        sx={{ width: "33%", flexShrink: 0 }}
                                    >
                                        {content.title}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <Typography>
                                                {displaySteps(content)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {content.video && (
                                                <Card className={classes.card}>
                                                    <CardMedia
                                                        component="iframe"
                                                        className={
                                                            classes.media
                                                        }
                                                        src={content.video}
                                                    />
                                                </Card>
                                            )}
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </div>
                </>
            </Container>
        </div>
    );
};

const displaySteps = (content) => {
    return (
        <ol>
            {content.steps.map((step, index) => (
                <li key={index}>
                    {step.step ? (
                        step.step
                    ) : (
                        <>
                            {step.title}
                            {displaySteps(step)}
                        </>
                    )}
                </li>
            ))}
        </ol>
    );
};

const useStyles = makeStyles((theme) => ({
    container: {
        marginBottom: "20px",
    },
    logoContainer: {
        padding: "10px 0",
        height: "50px",
    },
    logo: {
        height: "100%",
    },
    title: {
        marginBottom: "20px",
    },
    media: {
        height: 315,
    },
}));

export default Tutorials;
