import React, { useState, useEffect } from 'react';
import { LibraryNavigation } from '../LibraryNavigation';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { LibraryComment } from '../LibraryComment';
import LibrarySection from '../LibrarySection';
import { SearchBar } from '../SearchBar';
import {
    addSection,
    addComment,
    deleteSection,
    editSection,
    editComment,
    deleteComment,
    getLibraryById,
    getSectionFromLibrary,
    setCommentOrder,
    getCommentOrder,
    setSectionOrderLibrary,
    setSectionOrder,
    getSectionOrder,
    getAllPermissions
} from '../LibraryFunctions';
import {
    jsonAdd,
    jsonChange,
    jsonDelete,
} from "../JsonUpdateFunctions";
import {
    BrowserRouter as Router,
    useHistory,
    useLocation
} from "react-router-dom";
import {Divider, Snackbar} from "@material-ui/core";


/*
Description: 

This is called by the MainTab when a user has selected a library
to view. 

If the library is empty then this will display an empty navigation
component on the left with button options of adding new sections to this
library. If the library contains sections then there will be a drop-down
like navigation on the left and a display of a section's content on the right.

When a user first clicks on a library with existing sections, the top/first most
section is automatically selected. If this top section contains sub-sections
inside then that sections's sub-sections will be displayed as tiles on the right 
using the LibrarySection component. If this top section contains comments, then
the LibraryComment component will be used to display the section's list of comments 
on the right hand side. 

Different displayes allow the user to update the selected library accordingly.
By editting, deleting, or adding new section or comments.

Data From Props: 
props.path: the current path to specific section 
props.selectLibraryId: the id of the selected library
props.selectedSection: the section object for the selected section
props.jwt: allows for us to retrieve the content of the selected library

Functions From Props: 
props.setPath: used to update the original url path through MainTab
props.back: used to backtrack to the LibraryTab display

*/
export function SectionTab(props) {

    //Contains the object of the entire library that SectionTab is using
    //to display different sections or make changes to the library
    const [selectedLibrary, setSelectedLibrary] = useState(null);

    //Object of the selected section the user most recently clicked
    //to view using this SectionTab
    const [selectSection, setSelectSection] = useState(null);

    //This variables is used by the SearchBar to set the id for a comment
    //the user has searched for so the display can be updated accordingly by
    //displaying the comment's section
    const [selectedCommentId, setSelectedCommentId] = useState(null);

    //This variable is used to set the default category displayed on the right
    //hand side for sections with comments, and this is also used by the SearchBar
    //to pull up the proper display for a comment in a specific category
    const [selectedCommentCategory, setSelectedCommentCategory] = useState("Efficiency");

    const [movedAlert, setMovedAlert] = useState(false);
    useEffect(() => {
        //Gets the all the data for the library we are trying to display
        console.log("useEffect called");
        getLibrary()
        if (props.category) {
            console.log(props.category)
            setSelectedCommentCategory(props.category);
        }
        getAllPermissions(props.jwt, props.selectLibraryId).then(x => console.log(x));
    }, [props.path])

    //Gets the object of the library that the user selected
    function getLibrary() {
        console.log('get library')
        getLibraryById(props.jwt, props.selectLibraryId).then((library) => {
            setSelectedLibrary(library);
            //If no top level section was pre-selected and the library contains sections,
            //then this updates the path object with the id for the top most section.
            //If a section has been selected inside this library the section object for this
            //select section is retrieved.
            //Otherwise the selectSection object initiated earlier is set to null.
            if (!props.path.topSection && library.libraryContent.sections.length > 0) {
                setSelectSection(getSectionFromLibrary(library, library.libraryContent.sections[0].id))
                // let p = JSON.parse(JSON.stringify(props.path))
                // p.topSection = library.libraryContent.sections[0].id
                // props.setPath(p)
            } else if (props.path.topSection ) {
                if (props.path.subSection) {
                    if (props.path.subSubSection) {
                        setSelectSection(getSectionFromLibrary(library, props.path.subSubSection))
                    } else {
                        setSelectSection(getSectionFromLibrary(library, props.path.subSection))
                    }
                } else {
                    let sec = getSectionFromLibrary(library, props.path.topSection)
                    setSelectSection(sec)
                }
    
            } else {
                setSelectSection(null)
            }
        });
    }



    //Adds a new section to the current library
    async function addSectionToLibrary(name, libraryId, parentSectionId) {
        //Default section object that will be used and added to the exiting
        //json object of selectLibrary
        let section = {
            comments: [],
            id: "",
            name: name,
            sections: [],
            commentOrder: [],
            sectionOrder: []
        }
        await addSection(props.jwt, libraryId, parentSectionId, name).then((sectionId) => {
            section.id = sectionId.id;
            jsonAdd(section, "section", selectedLibrary.libraryContent, props.path)

        });
        //Finds newly added section to update selectSection object\
        const newSection = getSectionFromLibrary(selectedLibrary, parentSectionId);
        if (newSection) {
            setSelectSection(JSON.parse(JSON.stringify(newSection)));
        } else {
            getLibrary()
        }
    }

    //Edits a section in the current library
    async function editSectionInLibrary(sectionId, sectionName) {
        const existingSection = JSON.parse(JSON.stringify(getSectionFromLibrary(selectedLibrary, sectionId)));
        //Creates a section object using the current selectSection
        //object
        const section = {
            comments: existingSection.comments,
            id: sectionId,
            name: sectionName,
            sections: existingSection.sections,
            commentOrder: [],
            sectionOrder: []
        }
        const libraryId = props.selectLibraryId
        await editSection(props.jwt, libraryId, sectionId, sectionName).then(() => {
            jsonChange(section, "section", selectedLibrary.libraryContent, props.path);
        });
        //Updates selectSection object
        setSelectSection(section);
    }

    //Delets a section in the current library
    async function deleteSectionFromLibrary(sectionId) {
        const libraryId = props.selectLibraryId
        await deleteSection(props.jwt, libraryId, sectionId).then(() => {
            jsonDelete(sectionId, "section", selectedLibrary.libraryContent, props.path);
            console.log("after delete")
            console.log(selectedLibrary.libraryContent)
        });
        //Updates selectSection object
        reverseToPrevSection(sectionId)
    }

    //Adds a new comment to the current library
    function addCommentToSection(sectionId, title, explanation, category, type) {
        //Default comment object that will be used to add the comment
        let comment = {
            category: category,
            title: title,
            explanation: explanation,
            id: "",
            type: type
        }

        const libraryId = props.selectLibraryId
        addComment(props.jwt, libraryId, sectionId, title, explanation, category, type).then((commentId) => {
                comment.id = commentId;
                jsonAdd(comment, "comment", selectedLibrary.libraryContent, props.path);
                
                //Updates selectSection object
                const section = JSON.parse(JSON.stringify(getSectionFromLibrary(selectedLibrary, sectionId)));
                setSelectSection(section);
            });
    }

    //Edits a comment in the current library
    function editLibraryComment(sectionId, title, explanation, category, commentId, type) {
        //Default comment object that will be used to add the comment
        const commentValues = {
            category: category,
            title: title,
            explanation: explanation,
            id: commentId,
            type: type
        }

        const libraryId = props.selectLibraryId
        editComment(props.jwt, libraryId, sectionId, title, explanation,
            category, commentId, type).then(() => {
                jsonChange(commentValues, "comment", selectedLibrary.libraryContent, props.path);

                //Updates selectSection object
                const section = JSON.parse(JSON.stringify(getSectionFromLibrary(selectedLibrary, sectionId)));
                setSelectSection(section);
            });
    }

    //moves a comment from src section to dest section
    function moveLibraryComment(comment,srcSectionPath,destSectionPath) {
        let oldCommentID = comment.id;
        const srcPathArr = srcSectionPath.split('/').filter(e=> e);
        const destPathArr = destSectionPath.split('/').filter(e=> e);

        const libraryId = props.selectLibraryId
        if (srcSectionPath != destSectionPath) {
            addComment(props.jwt, libraryId, destPathArr[destPathArr.length - 1], comment.title, comment.explanation, comment.category)
                .then((commentId) => {
                    comment.id = commentId;
                    jsonAdd(comment, "comment", selectedLibrary.libraryContent, createPath(destPathArr));
                    deleteLibraryComment(srcPathArr[srcPathArr.length - 1], oldCommentID)
                    const section = JSON.parse(JSON.stringify(getSectionFromLibrary(selectedLibrary, srcPathArr[srcPathArr.length - 1])));
                    setSelectSection(section);
                })
            setMovedAlert(true);
        }
    }

    //returns a path object with the last section set to a target section
    function createPath(arr){
        let p = JSON.parse(JSON.stringify(props.path))
        p.topSection = arr[0] ? arr[0] : null;
        p.subSection = arr[1] ? arr[1] : null;
        p.subSubSection = arr[2] ? arr[2] : null;
        return p
    }


    //Deletes a comment in the current library
    function deleteLibraryComment(sectionId, commentId) {
        const libraryId = props.selectLibraryId
        deleteComment(props.jwt, libraryId, sectionId, commentId).then(() => {
            jsonDelete(commentId, "comment", selectedLibrary.libraryContent, props.path);

            //Updates selectSection object
            const section = JSON.parse(JSON.stringify(getSectionFromLibrary(selectedLibrary, sectionId)));
            setSelectSection(section);
        });
    }

    //Since a section has been deleted, this function will be called
    //to update the selectSection object that no longer exists. If there
    //is no more section in this library then selectsections becomes null. If the 
    //section was deleted inside of another section then the parent section
    //will be used to update selectSection object. Otherwise the top most
    //section is used to set selectSection object.
    async function reverseToPrevSection(sectionId) {
        let p = JSON.parse(JSON.stringify(props.path))
        if (props.path.topSection == null || props.path.topSection == sectionId) {
            if (selectedLibrary.libraryContent.sections[0]) {
                setSelectSection(selectedLibrary.libraryContent.sections[0]);
                p.topSection = selectedLibrary.libraryContent.sections[0].id;
                await props.setPath(p)
            } else {
                p.topSection = null;
                p.subSection = null;
                p.subSubButton = null;
                await props.setPath(p)
            }
        } else {
            if (props.path.subSection == sectionId) {
                p.subSection = null;
                setSelectSection(JSON.parse(JSON.stringify(getSectionFromLibrary(selectedLibrary, props.path.topSection))))
                await props.setPath(p)
            } else {
                p.subSubSection = null;
                setSelectSection(JSON.parse(JSON.stringify(getSectionFromLibrary(selectedLibrary, props.path.subSection))))
                await props.setPath(p)
            }
        }
    }

    // Updates the order of coments for a currently selected section
    function updateCommentOrder(comments){
        if(comments.length > 0){
            let ourOrder = comments.map(y => y.id);
            getCommentOrder(props.selectLibraryId,selectSection.id).then((order)=>{
                if(!order.every((value, index) => value === ourOrder[index])){
                    setCommentOrder(props.jwt, props.selectLibraryId,selectSection.id,ourOrder);
                    let section = selectSection;
                    section.commentOrder = ourOrder;
                    jsonChange(section, "section", selectedLibrary.libraryContent, props.path);
                    setSelectSection(section);
                }
            })
        }
    }

    // Updates the order of sub-sections for a given section
    async function updateSectionOrder(sections, sectionId, parentId, subId, id){
        if(sections.length > 0){
            let ownPath = {
                librariesPicked: true,
                setLibrary: props.selectLibraryId,
                topSection: parentId,
                subSection: sectionId,
                subSubSection: subId
            }
            let ourOrder = sections.map(y => y.id);
            await getSectionOrder(props.selectLibraryId,id).then((order)=>{
                if(!order.every((value, index) => value === ourOrder[index])){
                    setSectionOrder(props.jwt, props.selectLibraryId,id,ourOrder);
                    let section = JSON.parse(JSON.stringify(getSectionFromLibrary(selectedLibrary, id)));
                    section.sectionOrder = ourOrder;
                    jsonChange(section, "section", selectedLibrary.libraryContent, ownPath);
                }
            })
        }
    }

    async function updateSelectSectionAfterOrderUpdate(sectionId) {
        if(selectSection.id == sectionId){
            let sec = JSON.parse(JSON.stringify(getSectionFromLibrary(selectedLibrary, sectionId)));
            await setSelectSection(sec);
        }
    }

    // Updates the order of top level sections that is set by the library
    async function updateLibrarySectionOrder(sections){
        if(sections.length > 0){
            let ourOrder = sections.map(y => y.id);
            await setSectionOrderLibrary(props.jwt, props.selectLibraryId, ourOrder).then(()=>{
                selectedLibrary.libraryContent.sectionOrder = ourOrder
            })
        }
    }

    //Displayes the right hand side using the content of the current 
    //selectSection object
    function handleRightSideDisplay() {

        if (selectSection.sections.length > 0) {
            /* When the exisitng opened library gets a new selectedSections 
            and that section has sections */
            return (
                <Container>
                    <LibrarySection
                        permissionType={props.permissionType}
                        section={selectSection}
                        deleteSection={deleteSectionFromLibrary}
                        editSectionName={editSectionInLibrary}
                        sectionSelected={setSelectSection}
                        addSection={addSectionToLibrary}
                        libraryId={props.selectLibraryId}
                        setPath={props.setPath}
                        path={props.path}
                        libraryData={selectedLibrary}
                    />
                    <Snackbar
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        message ="Comment Moved"
                        onClose={()=>setMovedAlert(false)}
                        open={movedAlert}/>
                </Container>
            );
        } else {
            /* When the exisitng opened library gets a new selectedSections and that 
            section has commments or no subsections or comments*/
            return (
                <Container>
                    <LibraryComment
                        editSectionName={editSectionInLibrary}
                        section={selectSection}
                        deleteSection={deleteSectionFromLibrary}
                        addComment={addCommentToSection}
                        addSection={addSectionToLibrary}
                        libraryId={props.selectLibraryId}
                        selectedCommentId={selectedCommentId}
                        selectedCommentCategory={selectedCommentCategory}
                        setSelectedCommentId={props.setSelectedCommentId}
                        editComment={editLibraryComment}
                        deleteComment={deleteLibraryComment}
                        moveComment={moveLibraryComment}
                        setPath={props.setPath}
                        path={props.path}
                        jwt={props.jwt}
                        permissionType={props.permissionType}
                        libraryData={selectedLibrary}
                        updateCommentOrder={updateCommentOrder}
                        commentIdForEdit={props.commentIdForEdit}
                    />
                    <Snackbar
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        message ="Comment Moved"
                        onClose={()=>setMovedAlert(false)}
                        open={movedAlert}/>
                </Container>
            );
        }
    }

    return (
        <div>
            {/*{console.log(selectSection)}*/}
            {/*{console.log(selectedLibrary)}*/}
            {selectedLibrary &&
                <div>
                    <SearchBar
                        key={selectedLibrary.toString()}
                        setSelectedSection={setSelectSection}
                        setSelectedCommentId={setSelectedCommentId}
                        setSelectedCommentCategory={setSelectedCommentCategory}
                        library={selectedLibrary}
                        libraryString={JSON.stringify(selectedLibrary)}
                        setPath={props.setPath}
                        path={props.path}
                    />
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <Container>
                                <LibraryNavigation
                                    libraryString={JSON.stringify(selectedLibrary)}
                                    library={selectedLibrary}
                                    selectedSection={selectSection}
                                    addSection={addSectionToLibrary}
                                    sectionSelected={setSelectSection}
                                    setPath={props.setPath}
                                    path={props.path}
                                    back={props.back}
                                    updateSectionOrder={updateSectionOrder}
                                    updateLibrarySectionOrder={updateLibrarySectionOrder}
                                    finalOrderUpdate={updateSelectSectionAfterOrderUpdate}
                                />
                            </Container>
                        </Grid>
                        <Divider orientation="vertical" flexItem/>
                        <Grid item xs={true}>
                            {props.path.topSection ?
                                (selectSection && handleRightSideDisplay()) :
                                (<Container>
                                    <LibrarySection
                                        permissionType={props.permissionType}
                                        section={selectedLibrary.libraryContent.sections[0]}
                                        deleteSection={deleteSectionFromLibrary}
                                        editSectionName={editSectionInLibrary}
                                        sectionSelected={setSelectSection}
                                        addSection={addSectionToLibrary}
                                        libraryId={props.selectLibraryId}
                                        setPath={props.setPath}
                                        path={props.path}
                                        libraryData={selectedLibrary}
                                    />
                                </Container>)}
                        </Grid>
                    </Grid>
                </div>
            }
        </div>
    );
}