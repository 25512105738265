
export function getPermission(libraryId, email) {
    return fetch(process.env.REACT_APP_BACKEND_ENDPOINT +
        `library/permission?lib=${libraryId}&email=${email}`, {
        method: "GET",
    }).then(
        function (response) {
            if (response.status === 200) {
                return response.text().then(text => {
                    return text;
                })
            } else {
                response.text().then(text => {
                    return null;
                })
            }
        }
    )
}

export function getAllPermissions(jwt, libraryId) {
    return fetch(process.env.REACT_APP_BACKEND_ENDPOINT + "library/getAllPermissions?lib=" + libraryId, {
        method: "GET",
        headers: {
            "Authorization": jwt,
            "Content-Type": "application/json",
        },
    }).then(
        function (response) {
            if (response.status === 200) {
                return response.json().then(json => {
                    return json;
                })
            } else {
                response.text().then(text => {
                    return null;
                })
            }
        }
    )
}

// gets all of a users libraries
// returns libraries as json, null if failed
export function getAllLibraries(jwt) {
    // get all the libs for the given user
    console.log(jwt);
    return fetch(process.env.REACT_APP_BACKEND_ENDPOINT + "library/getAllById", {
        method: "GET",
        headers: {
            "Authorization": jwt,
            "Content-Type": "application/json",
        },
    }).then(
        function (response) {
            if (response.status === 200) {
                return response.json().then(json => {
                    return json;
                })
            } else {
                response.text().then(text => {
                    return null;
                })
            }
        }
    )
}

// gets all of a users libraries without internal content but just with ids
export function getLibraryById(jwt, id) {
    // get all the libs for the given user
    return fetch(process.env.REACT_APP_BACKEND_ENDPOINT + "library/getById?id=" + id, {
        method: "GET",
        headers: {
            "Authorization": jwt,
            "Content-Type": "application/json",
        }, 
    }).then(
        function (response) {
            if (response.status === 200) {
                return response.json().then(json => {
                    return json;
                })
            } else {
                response.text().then(text => {
                    return null;
                })
            }
        }
    )
}

// gets the order of sub-sections for a given section
export function getSectionOrder(libid, secid) {
    return fetch(process.env.REACT_APP_BACKEND_ENDPOINT +
        `library/section/getSectionOrder?libid=${libid}&secid=${secid}`, {
        method: "GET",
    }).then(
        function (response) {
            if (response.status === 200) {
                return response.json().then(json => {
                    // return the section json data
                    return json;
                });
            } else {
                // error
                return response.json().then(json => {
                    return null;
                });
            }
        }
    )
}

// gets the order of top level sections for a given library
export function getSectionOrderLibrary(libid) {
    return fetch(process.env.REACT_APP_BACKEND_ENDPOINT +
        `library/getSectionOrder?libid=${libid}`, {
        method: "GET",
    }).then(
        function (response) {
            if (response.status === 200) {
                return response.json().then(json => {
                    // return the section json data
                    return json;
                });
            } else {
                // error
                return response.json().then(json => {
                    return null;
                });
            }
        }
    )
}

// gets order of comments in a section
export function getCommentOrder(libid, secid) {
    return fetch(process.env.REACT_APP_BACKEND_ENDPOINT +
        `library/section/getCommentOrder?libid=${libid}&secid=${secid}`, {
        method: "GET",
    }).then(
        function (response) {
            if (response.status === 200) {
                return response.json().then(json => {
                    // return the section json data
                    return json;
                });
            } else {
                // error
                return response.json().then(json => {
                    return null;
                });
            }
        }
    )
}

// sets the order of top level sections for a given library
export function setSectionOrderLibrary(jwt, libraryId, sectionids) {
    const body = {
        libraryId: libraryId,
        order: sectionids,
        sectionId: 0,
    }
    // make api call to create library
    return fetch(process.env.REACT_APP_BACKEND_ENDPOINT + "library/setSectionOrder", {
        method: "POST",
        headers: {
            "Authorization": jwt,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body)
    }).then(
        function (response) {
            if (response.status === 200) {
                return response.json().then(json => {
                    //return the id of the newly added section
                    return json;
                });
            } else {
                // error
                return response.json().then(json => {
                    return null;
                });
            }
        }
    )
}

// sets the order of sub-sections for a given section
export function setSectionOrder(jwt, libraryId, sectionId, sectionids) {
    const body = {
        libraryId: libraryId,
        order: sectionids,
        sectionId: sectionId,
    }
    // make api call to create library
    return fetch(process.env.REACT_APP_BACKEND_ENDPOINT + "library/section/setSectionOrder", {
        method: "POST",
        headers: {
            "Authorization": jwt,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body)
    }).then(
        function (response) {
            if (response.status === 200) {
                return response.json().then(json => {
                    //return the id of the newly added section
                    return json;
                });
            } else {
                // error
                return response.json().then(json => {
                    return null;
                });
            }
        }
    )
}

// sets order of comments in a section
export function setCommentOrder(jwt, libraryId, sectionId, commentids) {
    const body = {
        libraryId: libraryId,
        order: commentids,
        sectionId: sectionId,
    }
    // make api call to create library
    return fetch(process.env.REACT_APP_BACKEND_ENDPOINT + "library/section/setCommentOrder", {
        method: "POST",
        headers: {
            "Authorization": jwt,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body)
    }).then(
        function (response) {
            if (response.status === 200) {
                return response.json().then(json => {
                    //return the id of the newly added section
                    return json;
                });
            } else {
                // error
                return response.json().then(json => {
                    return null;
                });
            }
        }
    )
}

// adds a section to the library
// returns the updated library json, null if an error occurred
export function addSection(jwt, libraryId, sectionId, sectionName) {
    const body = {
        sectionName: sectionName,
        libraryId: libraryId,
        sectionId: sectionId,
    }
    // make api call to create library
    return fetch(process.env.REACT_APP_BACKEND_ENDPOINT + "library/section/add", {
        method: "POST",
        headers: {
            "Authorization": jwt,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body)
    }).then(
        function (response) {
            if (response.status === 200) {
                return response.json().then(json => {
                    //return the id of the newly added section
                    return json;
                });
            } else {
                // error
                return response.json().then(json => {
                    return null;
                });
            }
        }
    )
}

// edits a section to in the library
// returns the updated library json, null if an error occurred
export function editSection(jwt, libraryId, sectionId, sectionName) {
    const body = {
        libraryId: libraryId,
        newName: sectionName,
        sectionId: sectionId,
    }
    // make api call to create library
    return fetch(process.env.REACT_APP_BACKEND_ENDPOINT + "library/section/update", {
        method: "POST",
        headers: {
            "Authorization": jwt,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body)
    }).then(
        function (response) {
            if (response.status === 200) {
                return response.json().then(() => {
                    return null;
                });
            } else {
                // error
                return response.json().then(json => {
                    return null;
                });
            }
        }
    )
}

// adds a comment to the given library
// returns the updated section of the library, null if not
export function addComment(jwt, libraryId, sectionId, title, explanation, category, type) {
    const body = {
        category: category,
        goodExample: "",
        badExample: "",
        title: title,
        explanation: explanation,
        libraryId: libraryId,
        sectionId: sectionId,
        additionalMaterials: "",
        type: type
    }
    // make api call to create library
    return fetch(process.env.REACT_APP_BACKEND_ENDPOINT + "library/comment/add", {
        method: "POST",
        headers: {
            "Authorization": jwt,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body)
    }).then(
        function (response) {
            if (response.status === 200) {
                return response.json().then(json => {
                    // return the id of the new added comment
                    return json.id;
                });
            } else {
                // error
                return response.json().then(json => {
                    return null;
                });
            }
        }
    )
}

// edit a comment in the given library
// returns the updated section of the library, null if not
export function editComment(jwt, libraryId, sectionId, title, explanation, category, commentId, type) {
    const body = {
        category: category,
        goodExample: "",
        badExample: "",
        title: title,
        explanation: explanation,
        libraryId: libraryId,
        sectionId: sectionId,
        commentId: commentId,
        additionalMaterials: "",
        type: type
    }
    // make api call to create library
    return fetch(process.env.REACT_APP_BACKEND_ENDPOINT + "library/comment/update", {
        method: "POST",
        headers: {
            "Authorization": jwt,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body)
    }).then(
        function (response) {
            if (response.status === 200) {
                return response.json().then(() => {
                    return null;
                });
            } else {
                // error
                return response.json().then(json => {
                    return null;
                });
            }
        }
    )
}

// deletes a comment in the given library
// returns the updated section of the library, null if not
export function deleteComment(jwt, libraryId, sectionId, commentId) {
    const body = {
        commentId: commentId,
        libraryId: libraryId,
        sectionId: sectionId
    }
    // make api call to create library
    return fetch(process.env.REACT_APP_BACKEND_ENDPOINT + "library/comment/delete", {
        method: "DELETE",
        headers: {
            "Authorization": jwt,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body)
    }).then(
        function (response) {
            if (response.status === 200) {
                return response.json().then(() => {
                    return null;
                });
            } else {
                // error
                return response.json().then(json => {
                    return null;
                });
            }
        }
    )
}


export function createLibrary(jwt, name, description) {
    const body = {
        name: name,
        description: description
    }
    // make api call to create library
    return fetch(process.env.REACT_APP_BACKEND_ENDPOINT + "library/create", {
        method: "POST",
        headers: {
            "Authorization": jwt,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body)
    }).then(
        function (response) {
            if (response.status === 200) {
                return response.json().then(json => {
                    return json;
                });
            } else {
                // error
                return response.json().then(json => {
                    return null;
                });
            }
        }
    );
}

export function uploadLibrary(jwt, libraryJSON) {
    const body = {
        libraryJSON: libraryJSON,
    }
    // make api call to upload library
    return fetch(process.env.REACT_APP_BACKEND_ENDPOINT + "library/upload", {
        method: "POST",
        headers: {
            "Authorization": jwt,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body)
    }).then(
        function (response) {
            let responseObj = { status: response.status };
            return response.json().then(json => {
                responseObj.json = json;
                return responseObj;
            });
        }
    );
}

// checks if the user exists and if so gets the id
// if the user does not exist create the user
// returns id if user exists, -1 if new user, null if error
export function checkIfUserExistsAndCreate(jwt) {
    return fetch(process.env.REACT_APP_BACKEND_ENDPOINT + "auth/login", {
        method: "GET",
        headers: {
            "Authorization": jwt,
            "Content-Type": "application/json",
        },
    }).then(
        function (response) {
            if (response.status === 200) {
                return response.json().then(json => {
                    return json.id;
                })
            } else {
                return response.json().then(json => {
                    return null;
                })
            }
        }
    )
}

// creates a user for the specified jwt
// returns the id of the created user, null if error
export function createUser(jwt) {
    return fetch(process.env.REACT_APP_BACKEND_ENDPOINT + "user/create", {
        method: "POST",
        headers: {
            "Authorization": jwt,
            "Content-Type": "application/json",
        },
    }).then(
        function (response) {
            if (response.status === 200) {
                return response.json().then(json => {
                    // set the user id
                    return json.id;
                })
            } else {
                return response.json().then(json => {
                    return null;
                })
            }
        }
    )
}

// deletes a section from a library
// returns the updated library json, null if an error occurred
export function deleteSection(jwt, libraryId, sectionId) {
    const body = {
        libraryId: libraryId,
        sectionId: sectionId,
    }
    // make api call to create library
    return fetch(process.env.REACT_APP_BACKEND_ENDPOINT + "library/section/delete", {
        method: "DELETE",
        headers: {
            "Authorization": jwt,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body)
    }).then(
        function (response) {
            if (response.status === 200) {
                return response.json().then(()=> {
                    return null;
                });
            } else {
                // error
                return response.json().then(json => {
                    return null;
                });
            }
        }
    )
}

// get a section based off given libraryId and sectionId
// returns section as json, null if failed
export function getSection(libraryId, sectionId) {
    // make api call to get section
    return fetch(process.env.REACT_APP_BACKEND_ENDPOINT +
        `library/section/get?libraryId=${encodeURIComponent(libraryId)}&sectionId=${encodeURIComponent(sectionId)}`, {
        method: "GET",
    }).then(
        function (response) {
            if (response.status === 200) {
                return response.json().then(json => {
                    // return the section json data
                    return json;
                });
            } else {
                // error
                return response.json().then(json => {
                    return null;
                });
            }
        }
    )
}

// get a comment based off given libraryId, sectionId, and commentId
// returns section as json, null if failed
export function getComment(libraryId, sectionId, commentId) {
    // make api call to get section
    return fetch(process.env.REACT_APP_BACKEND_ENDPOINT +
        `library/comment/get?libraryId=${encodeURIComponent(libraryId)}
         &sectionId=${encodeURIComponent(sectionId)}
         &commentId=${encodeURIComponent(commentId)}`, {
        method: "GET",
    }).then(
        function (response) {
            if (response.status === 200) {
                return response.json().then(json => {
                    // return the section json data
                    return json;
                });
            } else {
                // error
                return response.json().then(json => {
                    return null;
                });
            }
        }
    )
}
// delete's a user's library
export function deleteLibrary(jwt, libraryId) {
    const body = {
        libraryId: libraryId,
    }
    // make api call to create library
    return fetch(process.env.REACT_APP_BACKEND_ENDPOINT + "library/delete", {
        method: "POST",
        headers: {
            "Authorization": jwt,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body)
    }).then(
        function (response) {
            let responseObj = { status: response.status };
            return response.json().then(json => {
                responseObj.json = json;
                return responseObj;
            });
        }
    )
}

// update's a user's library
export function updateLibrary(jwt, id, name, description) {
    const body = {
        libraryId: id,
        name: name,
        description: description
    }
    // make api call to create library
    return fetch(process.env.REACT_APP_BACKEND_ENDPOINT + "library/update", {
        method: "POST",
        headers: {
            "Authorization": jwt,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body)
    }).then(
        function (response) {
            if (response.status === 200) {
                return response.json().then(json => {
                    return json;
                });
            } else {
                // error
                return response.json().then(json => {
                    return null;
                });
            }
        }
    );
}

// recover's a user's library
export function recoverLibrary(jwt, libraryJSON, libraryId) {
    const body = {
        libraryJSON: libraryJSON,
        libraryId: libraryId,
    }
    // make api call to create library
    return fetch(process.env.REACT_APP_BACKEND_ENDPOINT + "library/recover", {
        method: "POST",
        headers: {
            "Authorization": jwt,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body)
    }).then(
        function (response) {
            let responseObj = { status: response.status };
            return response.json().then(json => {
                console.log(json);
                responseObj.json = json;
                return responseObj;
            });
        }
    );
}

// share a user's library with another user
export function shareLibrary(jwt, libraryId, emailToShareWith, roleToGive) {

    const body = {
        libraryId: libraryId,
        emailToShareWith: emailToShareWith,
        roleToGive: roleToGive
    }
    // make api call to create library
    return fetch(process.env.REACT_APP_BACKEND_ENDPOINT + "library/share", {
        method: "POST",
        headers: {
            "Authorization": jwt,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body)
    }).then(
        function (response) {
            let responseObj = { status: response.status };
            return response.json().then(json => {
                responseObj.json = json;
                return responseObj;
            });
        }
    );
}

export function getSectionFromLibrary(libraryData, sectionId) {
    return helper(libraryData.libraryContent.sections, sectionId);
}

function helper(sections, sectionId) {
    let res = null
    sections.forEach(section => {
        if (section.id == sectionId) {
            res = section
            return;
        } else {
            section.sections.forEach(sec => {
                if (sec.id == sectionId) {
                    res =  sec
                    return;
                } else {
                    sec.sections.forEach(s => {
                        if (s.id == sectionId) {
                            res = s
                            return;
                        }
                    })
                }
            })
        }
    })
    return res;
}

export function getSectionHistory(libraryData, sectionId) {
    return getHistoryForSection(libraryData.libraryContent.sections, sectionId);
}

function getHistoryForSection(sections, sectionId) {
    let res = {
        parent: null,
        section: null,
        child: null
    }
    sections.forEach(section => {
        if (section.id === sectionId) {
            res.parent = section.id;
            return;
        } else {
            section.sections.forEach(sec => {
                if (sec.id === sectionId) {
                    res.parent = section.id;
                    res.section = sec.id;
                    return;
                } else {
                    sec.sections.forEach(s => {
                        if (s.id === sectionId) {
                            res.parent = section.id;
                            res.section = sec.id;
                            res.child = s.id;
                            return;
                        }
                    })
                }
            })
        }
    })
    return res;
}

// share a copy of a user's library with another user
export function shareLibraryCopy(jwt, libraryId, emailToShareWith) {
    const body = {
        libraryId: libraryId,
        emailToShareWith: emailToShareWith,
    }
    // make api call to create library
    return fetch(process.env.REACT_APP_BACKEND_ENDPOINT + "library/shareCopy", {
        method: "POST",
        headers: {
            "Authorization": jwt,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body)
    }).then(
        function (response) {
            let responseObj = { status: response.status };
            return response.json().then(json => {
                console.log(json);
                responseObj.json = json;
                return responseObj;
            });
        }
    );
}