import React from 'react';

export function TermsAndConditions() {
    return (
        <div>
            <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
            <meta name="Generator" content="Microsoft Word 15 (filtered)" />
            <style dangerouslySetInnerHTML={{ __html: "\n<!--\n /* Font Definitions */\n @font-face\n\t{font-family:\"Cambria Math\";\n\tpanose-1:2 4 5 3 5 4 6 3 2 4;}\n@font-face\n\t{font-family:Calibri;\n\tpanose-1:2 15 5 2 2 2 4 3 2 4;}\n /* Style Definitions */\n p.MsoNormal, li.MsoNormal, div.MsoNormal\n\t{margin-top:0in;\n\tmargin-right:0in;\n\tmargin-bottom:10.0pt;\n\tmargin-left:0in;\n\tline-height:115%;\n\tfont-size:11.0pt;\n\tfont-family:\"Calibri\",sans-serif;}\n.MsoChpDefault\n\t{font-family:\"Calibri\",sans-serif;}\n.MsoPapDefault\n\t{margin-bottom:10.0pt;\n\tline-height:115%;}\n@page WordSection1\n\t{size:8.5in 11.0in;\n\tmargin:1.0in 1.0in 1.0in 1.0in;}\ndiv.WordSection1\n\t{page:WordSection1;}\n-->\n" }} />
            <div className="WordSection1">
                <p className="MsoNormal" align="center" style={{ marginBottom: '.0001pt', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ fontSize: '18.0pt', fontFamily: '"Arial",sans-serif', color: 'black' }}>Terms &amp; Conditions</span></b></p>
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp;</span></p>
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span style={{ color: 'black' }}>Please read this “Terms &amp; Conditions” document and if
              you do not agree, you should not use our Microsoft Add-in named as “ ECoS”.</span></p>
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp;</span></p>
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span style={{ color: 'black' }}>“MaxAlton LLC” owns and operates this Microsoft Add-in i.e.
              ECoS. &nbsp;This document governs your </span>relationship with <span style={{ color: 'black', background: 'white' }}>Microsoft Add-in i.e. ECoS</span><span style={{ color: 'black' }}>. By using and accessing this Microsoft Add-in and all
                    related services, facilities and products (collectively, the
                    "Services") are subject to the following terms and conditions as may
                    be updated from time to time. You should check this page regularly to know what
              changes we made to it.</span></p>
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span style={{ fontSize: '12.0pt' }}>&nbsp;</span></p>
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span style={{ color: 'black' }}>We reserve the rights to change/modify or withdraw the
                Services without any notice. We will not be liable if for any reason this Microsoft
                Add-in is unavailable at any time or for any period. From time to time, we may
              restrict access to some parts or this entire Microsoft Add-in.</span></p>
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}>&nbsp;</span></p>
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><b><span style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}>Links to other Microsoft
                Add-ins</span></b></p>
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span style={{ color: 'black' }}>This Microsoft Add-in may contain links to other Microsoft
                Add-ins (the "Linked Sites"), which are not operated by us. We have
                no control over the Linked Sites and accept no responsibility for them or for
              any loss or damage that may arise from your use of them. </span></p>
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif' }}>&nbsp;</span></p>
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><b><span style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}>Privacy Policy</span></b></p>
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span style={{ color: 'black' }}>We collect your data for the described process and purpose
                and do not share your personal and information to any other Microsoft Add-in or
                party. By using this Microsoft Add-in, you consent to the processing described
              therein, and warrant that all data provided by you is accurate.</span></p>
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif' }}>&nbsp;</span></p>
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><b><span style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}>Prohibitions</span></b></p>
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span style={{ color: 'black' }}>You must not misuse this Microsoft Add-in. You will not
                commit or encourage a criminal offense on this Microsoft Add-in. We will not be
                liable for any loss or damage caused by a distributed denial-of-service attack,
                viruses or other technologically harmful material that may infect your computer
                equipment, computer programs, data or other proprietary material due to your
                use of this Microsoft Add-in or to your downloading of any material posted on
              it, or on any Microsoft Add-in linked to it.</span></p>
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif' }}>&nbsp;</span></p>
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><b><span style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}>Intellectual Property</span></b></p>
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span style={{ color: 'black' }}>The intellectual property rights in all content (including
                photographic images) made available to you on or through this Microsoft Add-in
                remains the property of&nbsp;MaxAlton LLC. You may store, print and display the
                content supplied solely for your own personal use. You are not permitted to
                publish, manipulate, distribute or otherwise reproduce, in any format, any of
                the content or copies of the content supplied to you or which appears on this Microsoft
                Add-in nor may you use any such content in connection with any business or
              commercial enterprise.</span></p>
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif' }}>&nbsp;</span></p>
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><b><span style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}>Waiver</span></b></p>
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span style={{ color: 'black' }}>In order to contract with ECoS you must be over 18 years of
                age and possess a valid credit or debit card issued by a bank acceptable to us.
                We retain the right to refuse any request made by you. When submitting
                information, you undertake that all details you provide to us are true and
                accurate, that you are an authorized user of the credit or debit card used to
              place your order and that there are sufficient funds to cover the cost.</span></p>
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif' }}>&nbsp;</span></p>
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><b><span style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}>Disclaimer of Liability</span></b></p>
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span style={{ color: 'black' }}>The material displayed on this Microsoft Add-in is provided
                without any guarantees, conditions or warranties as to its accuracy. Unless
                expressly stated to the contrary to the fullest extent permitted by law Microsoft
                Add-in, content providers and advertisers hereby expressly exclude all
                conditions, warranties and other terms which might otherwise be implied by
                statute, common law or the law of equity and shall not be liable for any
                damages whatsoever, including but without limitation to any direct, indirect,
                special, consequential, punitive or incidental damages, or damages for loss of
                use, profits, data or other intangibles, damage to goodwill or reputation, or
                the cost of procurement of substitute services, arising out of or related to
                the use, inability to use, performance or failures of this Microsoft Add-in or
                the Linked Sites and any materials posted thereon, irrespective of whether such
                damages were foreseeable or arise in contract, tort, equity, restitution, by
              statute, at common law or otherwise. </span></p>
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif' }}>&nbsp;</span></p>
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><b><span style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}>Indemnity</span></b></p>
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span style={{ color: 'black' }}>You agree to indemnify, defend and hold harmless ECoS, its
                directors, officers, employees, consultants, agents, and affiliates, from any
                and all third party claims, liability, damages and/or costs (including, but not
                limited to, legal fees) arising from your use this Microsoft Add-in or your
              breach of the Terms &amp; Conditions.</span></p>
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif' }}>&nbsp;</span></p>
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><b><span style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}>Variation</span></b></p>
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span style={{ color: 'black' }}>We&nbsp;shall have the right in its absolute discretion at
                any time and without notice to amend, remove or vary the Services and/or any
              page of this Microsoft Add-in.</span></p>
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}>&nbsp;</span></p>
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><b><span style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}>Entire Agreement</span></b></p>
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span style={{ color: 'black' }}>The above Terms &amp; Conditions constitute the entire
                agreement of the parties and supersede any and all preceding and
              contemporaneous agreements between you and Microsoft Add-in i.e. ECoS. </span></p>
                <p className="MsoNormal" style={{ textAlign: 'justify', textJustify: 'inter-ideograph' }}>&nbsp;</p>
            </div>
        </div>
    );
}