import React, { useState, useEffect } from 'react';
import {
    BrowserRouter as Router,
    useHistory,
    useLocation
} from "react-router-dom";
import { LibraryTab } from './LibraryTab';
import { SectionTab } from './SectionTab';
import {
    getAllLibraries, getLibraryById, getPermission, getSectionFromLibrary, getSectionHistory, uploadLibrary
} from '../LibraryFunctions';
import jwt_decode from "jwt-decode";

/*
Description:

This is the main tab that switches between displaying components
called SectionTab and LibraryTab.

When a user first logs in or clicks on the
"Dashboard" tab on the left hand side, they will recieve a display of all the
libraries that they either created or have been shared with. This library
display is called LibraryTab.

Once a user clicks one of these libraries, they will then be transfered to
the SectionTab component. They will see a navigation component where they
can click through all the sections in that libaray. They will also see a display
of either sub-sections for the top most section or comments
depending on the content of the top/first section in this library.

Data From Props:
props.jwt: a user's token to retrive their libraries
props.location: for reading the current pathname (url)

*/
export function MainTab(props) {

    //This variable keeps track of the id for the library that
    //has been selected
    const [selectLibraryId, setSelectLibraryId] = useState(null);

    //Creates the location variable used to see what the
    //current url is displaying
    const location = useLocation();

    //Creates the history varibale that is used to update
    //the url displayed
    const history = useHistory();

    //Data of all the library names and descriptions used by the
    //LibraryTab
    const [libraryData, setLibraryData] = useState(null);

    //Used to create login for updating the path object
    const [doNotReadLocation, setDoNotReadLocation] = useState(false);

    //Path object that is the direct translation of the url
    //displayed, this is easier to use and update.
    //The format for this object is:
    //p = {
    //    librariesPicked: false, (This is true when a user logs in or clicks "Dashboard" tab on the left hand side)
    //    setLibrary: null, (This is set to the library id that the user clicks if they want to see it's content)
    //    topSection: null, (This is null unless the user clicks on a top section or a section inside this top
    //                      (level section. Then this will keep track of the id for the top most section)
    //    subSection: null, (This is null unless the user clicks on a sub-section or a section inside this sub-
    //                      (section. Then this will keep track of the id for the sub-section)
    //    subSubSection: null (This is null unless the user clicks on a sub-sub-section.
    //                        (Then this will keep track of the id for the sub-sub-section)
    //}
    const [path, setPath] = useState(null);

    useEffect(() => {
        //Updates libraryData so we can display libraries and their names
        if (props.jwt !== "") {
            updateLibrariesData();
            updatePath();
            setDoNotReadLocation(true)
        }
    }, [props.jwt]);

    //This is mean to listen in location changes like backtracking,
    //the existent for loop is for safety so this does not run forever.
    //The reason for this running forever without it is yet to be debugged.
    useEffect(() => {
        var count = (location.pathname.match(/\//g) || []).length;
        if(count < 2){
            setSelectLibraryId(null);
        } else if (!doNotReadLocation){
            updatePath();
        } else {
            setDoNotReadLocation(false)
        }
    }, [location]);

    useEffect(() => {
        //If this is called on startup then the path should already include
        //a null value for setLibrary, or a specified value. Further,
        //selectLibraryId is initiated as a null so we will ignore trying
        //to update anything.

        //If a new selectLibraryId was set, then we need to change the path
        //to the selectedLibrary value. Only if the setLibrary in path is
        //not already the selectLibraryId
        if (selectLibraryId != null && path.setLibrary != selectLibraryId) {
            //check if this libraryId is already being used
            path.setLibrary = selectLibraryId;
            //We need to set the rest of the section values in path to null
            //since a new library has been selected
            let p = JSON.parse(JSON.stringify(path));
            p.topSection = null;
            p.subSection = null;
            p.subSubSection = null;
            //Then we push the new path to history so it can now be displayed
            //in pathname as a url
            setPathAndPush(p,true)
        }
    }, [selectLibraryId]);

    function pushToHistory(sectionsToNull, p){
        if(sectionsToNull){
            history.push(`/libraries/${p.setLibrary}`);
        } else if (p) {
            if (p.librariesPicked) {
                let newPath = "/libraries";
                if (p.setLibrary) {
                    newPath = newPath + `/${p.setLibrary}`
                }
                if (p.topSection) {
                    newPath = newPath + `/${p.topSection}`
                }
                if (p.subSection) {
                    newPath = newPath + `/${p.subSection}`
                }
                if (p.subSubSection) {
                    newPath = newPath + `/${p.subSubSection}`
                }
                if (p.commId) {
                    newPath = newPath + `/${p.commId}`
                }
                if (p.category) {
                    newPath = newPath + `/${p.category}`
                }
                history.push(newPath);
            }
        }
    }

    function setPathForSectionTab(p){
        setPathAndPush(p,false)
    }

    async function setPathAndPush(p,allToNull) {
        setDoNotReadLocation(true);
        await setPath(p);
        pushToHistory(allToNull,p)
    }

    //Set's all section and library id's in path
    //to null since the user is going back from the SectionTab.
    //This puts us in the LibraryTab with no selected
    //libraries
    function backButtonPressedInSection() {
        setSelectLibraryId(null);
        let p = JSON.parse(JSON.stringify(path))
        p.setLibrary = null;
        p.topSection = null;
        p.subSection = null;
        p.subSubSection = null;
        p.librariesPicked = true;
        setPathAndPush(p,true)
    }

    // Updates the library data after we make a call.
    // this is a cop out, later we should modify the api response
    // to return the updated data so we do not have to make
    // the extra api call
    function updateLibrariesData() {
        console.log("Called updateLibrariesData");
        getAllLibraries(props.jwt).then((libraries) => {
            console.log(libraries);
            if (libraries !== null) {
                console.log(libraries);
                setLibraryData(libraries);
            } else {
                console.log("error retrieving the users libraries");
            }
        });
    }

    // Updates path for current page. Depends on values returned from
    // API calls, so needed to be asynchronous to work.
    async function updatePathHelper(libId, sectionId) {
        let p = {
            topSection: null,
            subSection: null,
            subSubSection: null
        };
        p.librariesPicked = true;
        p.setLibrary = libId;
        let sectionHist;
        return Promise.all([
            getLibraryById(props.jwt, libId)
        ]).then((libraries) => {
            // sectionId = parseInt(sectionId);
            sectionHist = getSectionHistory(libraries[0], parseInt(sectionId)) // This is returning an undefined object
        }).then(() => {
            console.log(sectionHist);
            if (sectionHist.section === null && sectionHist.child === null) {
                console.log("section");
                p.topSection = sectionHist.parent;
            } else if (sectionHist.child === null) {
                console.log("sub section");
                p.topSection = sectionHist.parent;
                p.subSection = sectionHist.section;
            } else {
                console.log("sub sub section");
                p.topSection = sectionHist.parent;
                p.subSection = sectionHist.section;
                p.subSubSection = sectionHist.child
            }
        }).then(() => {
            setPath(p);
        })
    }

    // Reads the existing pathname and updates the path object
    function updatePath() {
        let p = {
            librariesPicked: false,
            setLibrary: null,
            topSection: null,
            subSection: null,
            subSubSection: null
        };
        console.log(libraryData);
        if (location.pathname.includes('libraries')) {
            console.log("entered main tab on redirect");
            //libraries tab was selected successfully
            var count = (location.pathname.match(/\//g) || []).length;
            p.librariesPicked = true;
            if (count > 1) {
                //A library has been selected based on path
                console.log("selected libraries");
                if (location.pathname.includes("/category/")) {
                    console.log("entered this if statement");
                    // handle category view for main tab
                    const valsAfterSlash = location.pathname.substring("libraries".length + 2);
                    const arrOfVals = valsAfterSlash.split("\/");
                    console.log(arrOfVals);
                    // checks to make sure the given url only contains numbers for the library
                    // and section id
                    let notProperIds = false;
                    for (var i = 0; i < 2; i++){
                        if(/[a-zA-Z]/g.test(arrOfVals[i])){
                            notProperIds = true;
                        }
                    }
                    if (notProperIds) {
                        setSelectLibraryId(null);
                    } else {
                        setSelectLibraryId(arrOfVals[0]);
                        p.setLibrary = arrOfVals[0];
                        console.log(arrOfVals[0]);
                        // asynchronous call to update path
                        updatePathHelper(arrOfVals[0], arrOfVals[1]);
                    }
                } else {
                    // standard routes
                    const valsAfterSlash = location.pathname.substring("libraries".length + 2)
                    const arrOfVals = valsAfterSlash.split("\/")
                    console.log(arrOfVals);
                    //checks to make sure the given url only contains numbers for the section
                    //ids
                    let notProperIds = false
                    for(var i = 0; i < arrOfVals.length; i++){
                        if(/[a-zA-Z]/g.test(arrOfVals[i])){
                            notProperIds = true;
                        }
                    }
                    if(notProperIds){
                        setSelectLibraryId(null);
                    } else {
                        setSelectLibraryId(arrOfVals[0])
                        p.setLibrary = arrOfVals[0]
                        //Check if sections where also selected
                        if (arrOfVals.length == 4) {
                            p.topSection = arrOfVals[1]
                            p.subSection = arrOfVals[2]
                            p.subSubSection = arrOfVals[3]
                        } else if (arrOfVals.length == 3) {
                            p.topSection = arrOfVals[1]
                            p.subSection = arrOfVals[2]
                        } else {
                            p.topSection = arrOfVals[1]
                        }
                    }
                }
            } else {
                //no library was selected based on path
                setSelectLibraryId(null);
            }
        } else {
            p.librariesPicked = false;
            console.log("You are in MainTab but libraries/ isn't in history,"
                + "check tab press for librariesTab on left side")
        }
        setPath(p)
    }

    //Changes the library id, to display SectionTab when a library is 
    //selected in MyLibraries
    function librarySelected(index, id) {
        setSelectLibraryId(id)
    }

    function retrievePermission() {
        const user = jwt_decode(props.jwt);
        const email = user.email;
        const json = getPermission(selectLibraryId, email);
        return json;
    }

    return (
        <div>
            {libraryData && path &&
            <div>
                {console.log(libraryData)}
                {selectLibraryId ? (
                    <SectionTab
                        permissionType={retrievePermission()}
                        jwt={props.jwt}
                        selectLibraryId={selectLibraryId}
                        back={backButtonPressedInSection}
                        path={path}
                        setPath={setPathForSectionTab}
                        category={props.category}
                        commentIdForEdit={props.commentIdForEdit}
                    />
                ) : (
                    <LibraryTab
                        jwt={props.jwt}
                        libraryData={libraryData}
                        librarySelected={librarySelected}
                        updateLibrariesData={updateLibrariesData}
                    />
                )}
            </div>
            }
        </div>
    );
}