import React, { useState, useEffect } from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { CustomTextField } from './CustomPopupComponents';
import { CustomFinishButton } from './CustomPopupComponents';
import { PaperStyle } from './CustomPopupComponents';
import { CustomTitle } from './CustomPopupComponents';

/*
Description: 
    Popup window for creating/editing a new section/subsection

Data from props:
    formType (string) - value="Edit"/"Create"

Function from props:
    handleSection(sectionName) - adds the new section/subsection called sectionName
    closeWindow
*/

export function CreateOrEditSectionPopup(props) {
    const formDefaultValues = {
        sectionName: "",
    }
    const [formValues, setFormValues] = useState(props.formDefaultValues ? props.formDefaultValues : formDefaultValues);
    const { sectionName } = formValues;
    function handleChange(e) {
        const target = e.target;
        setFormValues(prevState => ({
            ...prevState,
            [target.name]: target.value
        }));
    }

    const [open, setOpen] = useState(true);
    const handleClose = () => {
        setOpen(false);
        props.closeWindow();
    };

    const handleFinish = () => {
        handleClose();
        props.handleSection(sectionName);
    }

    return (
        <div>
            <Dialog
                PaperProps={PaperStyle}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={"sm"}
            >
                <CustomTitle title={((props.formType === "Create") ? ("Create a New ") : ("Edit ")) + "Section"} onClick={handleClose} />
                <DialogContent>
                    <CustomTextField title="Name" onChange={handleChange} name="sectionName" value={sectionName} rows={1} onEnter={handleFinish} />
                </DialogContent>
                <DialogActions>
                    <CustomFinishButton text={(props.formType === "Create") ? ("Create") : ("Save")} onClick={handleFinish} />
                </DialogActions>
            </Dialog>
        </div>
    );
}