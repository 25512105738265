import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import ArrowBack from '@material-ui/icons/ArrowBack';
import AddCircle from '@material-ui/icons/AddCircle';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import NavigationSections from './NavigationSections/NavigationSections';
import { CreateOrEditSectionPopup } from './Popups/SectionPopups';

/*
Description: Navigation window for the library

Data from props: 

libraryString object, this will be a stringified json
object that has all the data of the library, and the section object
of the currently selectedSection

library object by itself aswell

path, contains the ids that can be used to parse the library object to reach
the currently select section.
path = {
            librariesPicked: false,
            setLibrary: null,
            topSection: null,
            subSection: null,
            subSubSection: null
        }

selecteeSection, the JSON object of the currently selected section

Function from props: 
addSection(name, libraryId, parentSectionId), pass the name of the new section, sectionId is
the id of the current section object and the parent section id

sectionSelected(section), when a subsection is clicked, pass the section
object to this

back, takes the user back from this section to the main view of all the libraries

setPath, allows us to specify what sub-section we are on by using ids
of any parent sections and child sections associated with the current sections
we have selected.

updateSectionOrder, updates order of the sub-sections or sub-sub-sections 
within a section

updateLibrarySectionOrder, updates order of the top most sections by updating
the section list contained in the library content
*/

export function LibraryNavigation(props) {

    const classes = useStyles();

    // true if section popup is being displayed to create new section
    const [creatingNewSection, setCreatingNewSection] = useState(false);
    // the parentId of a new section
    const [newSectionParentSectionId, setNewSectionParentSectionId] = useState(0);
    // keep track of all the tabs in Accordion and their associated section ids
    const [expandedList, setExpandedList] = useState([]);
    // used to hard update the NavigationSections object, although may be useless
    const [navKey, setNavKey] = useState(0);

    // updates the navKey variable so that the NavigationSections hard refresh
    // with the new props
    useEffect(() => {
        setNavKey(navKey + 1)
    }, [props]);

    // updates the state values for our new section's type and parentId
    // displays the new section popup
    function handleCreateSection(sectionName, parentSectionId, grandParent, subid) {
        setNewSectionParentSectionId(parentSectionId);
        setCreatingNewSection(true);
    }

    // Creates a new section
    function createNewSection(sectionName) {
        props.addSection(sectionName, props.library.id, newSectionParentSectionId);
    }

    // Reroutes the right side of the SectionTab to display the newly
    // selected section
    function handleSectionClick(parentid, sectionid, subid, section) {
        expandedList.map(function (x) {
            x.expand = undefined
            return x
        })
        let p = JSON.parse(JSON.stringify(props.path));
        p.topSection = parentid;
        p.subSection = sectionid;
        p.subSubSection = subid;
        props.setPath(p);
        props.sectionSelected(section);
    }

    //Returns to the MainTab view of the libraries
    function handleBackButton() {
        props.sectionSelected(null);
        props.back();
    }

    return (
        <React.Fragment>
            <div className={classes.navigation}>
                <div className={classes.header}>
                    <IconButton
                        aria-label="delete"
                        size="medium"
                        onClick={handleBackButton}>
                        <ArrowBack className={classes.backButton} />
                    </IconButton>
                    <h1>{props.library.name}</h1>
                </div>
                <p className={classes.headerDescription}>
                    {props.library.description}
                </p>
                <NavigationSections
                    sections={props.library.libraryContent.sections}
                    parentId={0}
                    navKey={navKey}
                    selectedSection={props.selectedSection}
                    sectionOrder={props.library.libraryContent.sectionOrder}
                    update={"library"}
                    sectionSelected={props.sectionSelected}
                    handleSectionClick={handleSectionClick}
                    handleCreateSection={handleCreateSection}
                    updateSectionOrder={props.updateSectionOrder}
                    updateLibrarySectionOrder={props.updateLibrarySectionOrder}
                    granId={null}
                    finalOrderUpdate={props.finalOrderUpdate}
                />
                <div className={classes.addFirstButtonContainer}>
                    <Button
                        startIcon={<AddCircle className={classes.addIcon} />}
                        variant="contained" className={classes.addButton}
                        onClick={() => handleCreateSection("Section", 0, null, null)}>
                        New Section
                    </Button>
                </div>
            </div>
            {creatingNewSection &&
                (<CreateOrEditSectionPopup
                    formType="Create"
                    handleSection={createNewSection}
                    closeWindow={() => setCreatingNewSection(false)}
                />)}
        </React.Fragment>
    );
}

const useStyles = makeStyles(theme => ({
    addButton: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        color: 'black',
        "&:hover": {
            backgroundColor: 'transparent',
            boxShadow: 'none',
        },
        paddingTop: '6px',
        paddingLeft: '7px',
        textTransform: "none",
        fontSize: '85%',
    },
    addFirstButtonContainer: {
        textTransform: "none",
        width: '100%',
        padding: 0,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    addIcon: {
        color: "#484848",
    },
    backButton: {
        fontSize: 27,
        fontWeight: 500,
        color: 'black',
        padding: 'auto'
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'left',
        flexDirection: 'row'
    },
    headerDescription: {
        marginTop: '0%',
        marginLeft: '2%',
        fontSize: 'medium',
        fontWeight: 500,
        color: 'grey'
    },
    navigation: {
        dislay: 'flex',
        flexDirection: 'column',
        width: '100%'
    }
}));