import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from '@material-ui/core';
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import matchSorter from 'match-sorter'
import isReactSyntheticEvent from 'is-react-synthetic-event';

/*
Description: 
    Search Bar for all comments and sections in a user's libraries

Data from props:
    props.libraries - Array of all library objects

Function from props:
    props.setSelectedSection(section) - Sets the view to the selected section
    props.setSelectedLibraryIndex(libraryIndex) - Sets the view to the selected library
    props.setSelectedCommentId(commentId) - Sets the selected comment to be expanded in view
*/

export function SearchBar(props) {
    
    // Style for the material-ui components
    const classes = useStyles();

    // Fuzzy search, consider using Fuse.js
    const filterOptions = (options, { inputValue }) => {
        return matchSorter(options, inputValue, { keys: ['text'] });
    }

    // Stores the input value in the search bar
    const [inputValue, setInputValue] = useState("");
    const [value, setValue] = useState(null);

    // Stores the current type(comment/section) to be displayed in search results
    const [searchType, setSearchType] = useState("Comment");

    // Stores all comments and sections in user's libraries
    const [commentObjects, setCommentObjects] = useState([]);
    const [sectionObjects, setSectionObjects] = useState([]);

    // On load first extract all the comments 
    useEffect(() => {
        extractCommentsAndSectionsFromLibraries(props.library);
    }, [props.libraryString]);

    // Extracts all the comments and sections from an array of libraries
    function extractCommentsAndSectionsFromLibraries(library) {
        let allCommentObjects = [];
        let allSectionObjects = [];

        
        extractCommentsAndSections(library.libraryContent.sections, allCommentObjects, allSectionObjects);
        

        allCommentObjects.sort((a, b) => {
            return -b.text.localeCompare(a.text);
        })
        allSectionObjects.sort((a, b) => {
            return -b.text.localeCompare(a.text);
        })
        setCommentObjects(allCommentObjects);
        setSectionObjects(allSectionObjects);
    }

    // Extracts all the comments and sections from an array of sections
    function extractCommentsAndSections(sections, allCommentObjects, allSectionObjects) {
        let path = JSON.parse(JSON.stringify(props.path));
        sections.forEach(section => {
            extractCommentsAndSectionsHelper(1, path, section, allCommentObjects, allSectionObjects);
        })
    }

    // Extracts all the comments and sections from a single section
    function extractCommentsAndSectionsHelper(level, path, section, allCommentObjects, allSectionObjects) {
        let currentPath = JSON.parse(JSON.stringify(path));;
        if (level === 1) {
            currentPath.topSection = section.id;
        } else if (level === 2) {
            currentPath.subSection = section.id;
        } else if (level === 3) {
            currentPath.subSubButton = section.id;
        }
        const sectionObject = {
            type: "Section",
            text: section.name,
            section: section,
            path: currentPath,
        };
        allSectionObjects.push(sectionObject);
        if (section.comments.length !== 0) {
            section.comments.forEach(comment => {
                const commentObject = {
                    type: "Comment",
                    text: comment.title,
                    section: section,
                    commentId: comment.id,
                    category: comment.category,
                    path: currentPath,
                };
                allCommentObjects.unshift(commentObject);
            })
        } else {
            section.sections.forEach(subSection => {
                extractCommentsAndSectionsHelper(level + 1, currentPath, subSection, allCommentObjects, allSectionObjects);
            })
        }
    }

    // Set the section view to the section that contains the comment or section
    function handleCommentClick(commentOrSectionObject) {
        if (commentOrSectionObject !== null) {
            props.setSelectedSection(commentOrSectionObject.section);
            props.setPath(commentOrSectionObject.path);
            //props.setSelectedLibraryIndex(commentOrSectionObject.libraryIndex);
            if (commentOrSectionObject.type === "Comment") {
                props.setSelectedCommentId(commentOrSectionObject.commentId);
                props.setSelectedCommentCategory(commentOrSectionObject.category);
            }
            setInputValue("");
        }
    }

    // Finds the characters to highlight in text based on query
    function match(text, query, anyMatch) {
        const specialCharsRegex = /[.*+?^${}()|[\]\\]/g;
        const wordCharacterRegex = /[a-z0-9_]/i;
        const whitespacesRegex = /\s+/;

        function escapeRegexCharacters(str) {
            return str.replace(specialCharsRegex, '\\$&');
        }

        return (
            query
                .trim()
                .split(whitespacesRegex)
                // If query is blank, we'll get empty string here, so let's filter it out.
                .filter(function (word) {
                    return word.length > 0;
                })
                .reduce(function (result, word) {
                    var wordLen = word.length;
                    var prefix = wordCharacterRegex.test(word[0]) ? '\\b' : '';
                    var regex = new RegExp(prefix + escapeRegexCharacters(word), 'i');
                    var index = anyMatch ? text.toLowerCase().search(word.toLowerCase()) : text.search(regex);

                    if (index > -1) {
                        result.push([index, index + wordLen]);

                        // Replace what we just found with spaces so we don't find it again.
                        text =
                            text.slice(0, index) +
                            new Array(wordLen + 1).join(' ') +
                            text.slice(index + wordLen);
                    }

                    return result;
                }, [])
                .sort(function (match1, match2) {
                    return match1[0] - match2[0];
                })
        );
    };

    // Breaks the test into parts based on matches to highlight
    function parse(text, matches) {
        var result = [];

        if (matches.length === 0) {
            result.push({
                text: text,
                highlight: false
            });
        } else {
            if (matches[0][0] > 0) {
                result.push({
                    text: text.slice(0, matches[0][0]),
                    highlight: false
                });
            }
        }

        matches.forEach(function (match, i) {
            var startIndex = match[0];
            var endIndex = match[1];

            result.push({
                text: text.slice(startIndex, endIndex),
                highlight: true
            });

            if (i === matches.length - 1) {
                if (endIndex < text.length) {
                    result.push({
                        text: text.slice(endIndex, text.length),
                        highlight: false
                    });
                }
            } else if (endIndex < matches[i + 1][0]) {
                result.push({
                    text: text.slice(endIndex, matches[i + 1][0]),
                    highlight: false
                });
            }
        });

        return result;
    };

    return (
        <div>
            <Autocomplete
                filterOptions={filterOptions}
                disableClearable
                forcePopupIcon={false}
                className={classes.autocomplete}
                id="search"
                options={value === null 
                    ? searchType === "Comment" ? commentObjects : sectionObjects
                    : [value, ...searchType === "Comment" ? commentObjects : sectionObjects]}
                getOptionLabel={(commentOrSectionObject) => commentOrSectionObject.text}
                onChange={(event, value) => {
                    setValue(null);
                    handleCommentClick(value)
                }}
                value={value}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    event && (event.constructor.name === "SyntheticEvent" || event.constructor.name === "Vn") 
                          && setInputValue(newInputValue);
                }}
                getOptionSelected={(option, value) => option.text === value.text }
                renderInput={(params) =>
                    <TextField
                        {...params}
                        label={searchType + " Search"}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Button onClick={(event) => {
                                        event.stopPropagation();
                                        setSearchType(searchType === "Comment" ? "Section" : "Comment")
                                    }}>
                                        Switch to {searchType === "Comment" ? "Section" : "Comment"} Search
                                    </Button>
                                </InputAdornment>
                            )
                        }}
                    />
                }
                renderOption={(commentOrSectionObject, { inputValue }) => {
                    const matches = match(commentOrSectionObject.text, inputValue, true);
                    const parts = parse(commentOrSectionObject.text, matches);

                    return (
                        <div>
                            {parts.map((part, index) => (
                                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                    {part.text}
                                </span>
                            ))}
                        </div>
                    );
                }}
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    autocomplete: {
        marginLeft: '25px',
        marginRight: '20px',
        height: 60
    },
}));