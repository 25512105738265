import React, { useState, useEffect } from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Typography from "@material-ui/core/Typography";
import { CustomTitle } from './CustomPopupComponents';
import { PaperStyle } from './CustomPopupComponents';
import { makeStyles } from "@material-ui/core/styles";

/*
Description: 
    Popup window for a confirmation screen after library operation

Data from props:
    title (string) 
    message (string)

Function from props:
    closeWindow()
*/

export function ConfirmationPopup(props) {

    const classes = useStyles();

    const [open, setOpen] = useState(true);
    const handleClose = () => {
        setOpen(false);
        props.closeWindow();
    };

    return (
        <div>
            <Dialog
                PaperProps={PaperStyle}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={"sm"}
            >
                <CustomTitle onClick={handleClose} />
                <DialogContent>
                    <DialogContentText className={classes.title}>
                        {props.title}
                    </DialogContentText>
                    <DialogContentText className={classes.message}>
                        {props.message}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    title: {
        marginTop: '10px',
        marginBottom: "0",
        color: 'black',
        textAlign: 'center',
        fontWeight: '500',
        fontSize: '20px',
    },
    message: {
        marginTop: '5px',
        marginBottom: "30px",
        color: 'gray',
        textAlign: 'center',
        fontWeight: '400',
        fontSize: '18px',
    },
}));