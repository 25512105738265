import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { PaperStyle } from './CustomPopupComponents';
import Dialog from "@material-ui/core/Dialog";
import { CustomTitle } from './CustomPopupComponents';
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { CustomFinishButton } from './CustomPopupComponents';
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import NativeSelect from "@material-ui/core/NativeSelect";
import { StyledInput } from './CustomPopupComponents';

/*
Description: 
    Popup window for sharing a library

Data from props:
    libraryName 
    libraryId 

Function from props:
    shareExistingLibrary(libraryId, email, role)
    closeWindow()
*/

export function ShareLibraryPopup(props) {
    const classes = useStyles();

    // Stores the state of popup visibility
    const [open, setOpen] = useState(true);
    // Closes the popup
    const handleClose = () => {
        setOpen(false);
        props.closeWindow();
    };

    // Shares the library and closes the popup
    const handleFinish = () => {
        handleShareLibrary();
        handleClose();
    }

    // Shares the given library to all the recipients
    const handleShareLibrary = () => {
        if (props.copy) {
            emails.map((email) => props.shareExistingLibraryCopy(props.libraryId, email, props.libraryName));
        } else {
            emails.map((email, index) => props.shareExistingLibrary(props.libraryId, email, roles[index].toLowerCase(), props.libraryName));
        }
    }

    // Adds a new email and role for a new recipient
    const handleAddRecipient = () => {
        handleAddEmail();
        handleAddRole();
    }

    // Stores the emails for the recipients
    const [emails, setEmails] = useState([""]);
    // Adds a new email for a new recipient
    const handleAddEmail = () => {
        const newEmails = [...emails, ""];
        setEmails(newEmails);
    }
    // Changes the email for a recipient
    const handleEditEmail = (index, event) => {
        let newEmails = [...emails];
        newEmails[index] = event.target.value;
        setEmails(newEmails);
    }

    // Stores the roles for the recipients
    const [roles, setRoles] = useState(["Editor"]);
    // Adds a new role for a new recipient, default Editor
    const handleAddRole = () => {
        const newRoles = [...roles, "Editor"];
        setRoles(newRoles);
    }
    // Changes the role for a recipient
    const handleEditRole = (index, event) => {
        let newRoles = [...roles];
        newRoles[index] = event.target.value;
        setRoles(newRoles);
    }

    // Submits the form when enter is pressed
    function handleKeyPress(e) {
        if (e.key === 'Enter') {
            handleFinish();
        }
    }

    // Renders an email prompt, email textfield, and role dropdown menu
    function renderRecipientFields(index) {
        return (
            <div key={index} className={classes.recipientRoot}>
                <Typography display="inline" className={classes.emailPrompt}>
                    Email:
                </Typography>
                <TextField
                    // id={index}
                    onChange={(event) => handleEditEmail(index, event)}
                    value={emails[index]} rows={1}
                    onKeyPress={handleKeyPress}
                    inputProps={{ maxLength: 58 * 1 }}
                    style={{ width: '100%' }}
                />
                {!props.copy &&
                    <NativeSelect
                        className={classes.select}
                        // id={index}
                        value={roles[index]}
                        onChange={(event) => handleEditRole(index, event)}
                        input={<StyledInput />}
                    >
                        <option key='Editor' value='Editor'>Editor</option>
                        <option key='Owner' value='Owner'>Owner</option>
                        <option key='Viewer' value='Viewer'>Viewer</option>
                    </NativeSelect>}
            </div>
        );
    }

    // Renders all the roles and their descriptions
    function renderRoleDescriptions() {
        return (
            <div>
                {!props.copy &&
                    <div>
                        <Typography className={classes.roleDescriptionTitle}>
                            Description of Permission Types :
                        </Typography>
                        <Typography className={classes.roleDescriptions}>Owner:</Typography>
                        <Typography className={classes.roleDescriptions}>Editor:</Typography>
                        <Typography className={classes.roleDescriptions}>Viewer:</ Typography>
                    </div>}
            </div>
        );
    }

    return (
        <div>
            <Dialog
                PaperProps={PaperStyle}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={"sm"}
            >
                <CustomTitle title={props.copy ? 
                                    'Share copy of ' + props.libraryName :
                                    'Share ' + props.libraryName} 
                                    onClick={handleClose} />
                <DialogContent>
                    {emails.map((email, index) => (
                        renderRecipientFields(index)
                    ))}
                    <Button
                        className={classes.addRecipientButton}
                        variant="outlined"
                        onClick={handleAddRecipient}
                    >
                        + Add Another Recipient
                    </Button>
                    {renderRoleDescriptions()}
                </DialogContent>
                <DialogActions>
                    <CustomFinishButton text='SHARE' onClick={handleFinish} />
                </DialogActions>
            </Dialog>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    addRecipientButton: {
        background: 'white',
        border: 'none',
        paddingLeft: '0px',
        paddingTop: '10px'
    },
    emailPrompt: {
        marginRight: '5px'
    },
    recipientRoot: {
        display: 'flex',
        alignItems: 'center'
    },
    select: {
        marginLeft: '5px',
        width: 110
    },
    roleDescriptionTitle: {
        fontWeight: 'bold',
        marginTop: '15px'
    },
    roleDescriptions: {
        marginTop: '10px'
    }
}));
