import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import { downloadClickEvent  } from '../../GAEvents';

export default function FrontPage(props) {
    const classes = useStyles();
    const title = "Efficiency, Content, Style.";
    const description = "ECoS is a Microsoft Word add-in that allows you to " +
                        "review technical documents more efficiently.";

    const handleInstall = () => {
        downloadClickEvent();
        window.open("https://appsource.microsoft.com/en-us/product/office/WA200003975?tab=Overview");
    }

    return (
        <div>
            <Grid container 
                direction="row"
                justify="center"
                alignItems="center" 
                className={classes.root}
            >
                <Grid item
                    direction="row"
                    alignItems="center" 
                    xs={3}
                >
                    <Typography className={classes.title}>{title}</Typography>
                    <Typography className={classes.description}>{description}</Typography>
                    <Button onClick={() => handleInstall()} className={classes.button} variant="raised">
                        Download Now
                    </Button>
                </Grid>
                <Grid item xs={9} className={classes.grid}>
                    <Card className={classes.card}>
                        <CardMedia 
                            className={classes.media}
                            image='https://ecos-media.s3-us-west-2.amazonaws.com/Landing.jpg'
                        />
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
        flexGrow: 1,
        paddingTop: '20px',
        paddingRight: '100px',
        paddingLeft: '100px',
        paddingBottom: '200px',
        height: '100%',
        width: '100%',
        background: 'linear-gradient(to left, #F9F9FA 60%, #FFFFFF 0%)',
    },
    title: {
        fontWeight: 700,
        color: '#1B0D6E',
        fontSize: 50,
        paddingBottom: '15px',
        paddingLeft: '25px',
        paddingRight: '25px',
    },
    grid: {
        paddingLeft: '25px',
        paddingRight: '25px',
        paddingBottom: '25px',
    },
    description: {
        fontSize: 20,
        paddingLeft: '25px',
        paddingBottom: '25px',
    },
    card: {
        backgroundColor: 'black',
    },
    media: {
        paddingTop: '56.25%',
        height: 0,
    },
    button: {
        marginTop: '15px',
        fontSize: 10,
        color: "white",
        background: '#5096FF',
        border: 'none',
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingRight: '50px',
        paddingLeft: '50px',
        marginLeft: '25px',
        "&:hover": {
            backgroundColor: '#5096FF'
        },
        borderRadius: 25,
    },
  }));