// On routing component or any component that is used
// on every page that you want to track of
// e.g. Navigation, or background containers
import ReactGA from "react-ga";

export const pageViewsTracking = (pathname) => {
  let pageView;
  if (pathname === "*") pageView = "/not-found";
  else pageView = pathname;

  ReactGA.pageview(pageView);
};

export const downloadClickEvent = () => {
  ReactGA.event({
    category: "DOWNLOAD_BUTTON",
    action: "CLICK",
    label: "FRONT_PAGE",
  });
};

export const logInClickEvent = () => {
  ReactGA.event({
    category: "LOGIN_BUTTON",
    action: "CLICK",
  });
};

export const signInClickEvent = () => {
  ReactGA.event({
    category: "SIGNIN_BUTTON",
    action: "CLICK",
  });
};
