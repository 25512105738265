import React from 'react';

export function PrivacyPolicy() {
    return (
        <div>
            <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
            <meta name="Generator" content="Microsoft Word 15 (filtered)" />
            <style dangerouslySetInnerHTML={{ __html: "\n<!--\n /* Font Definitions */\n @font-face\n\t{font-family:Helvetica;\n\tpanose-1:0 0 0 0 0 0 0 0 0 0;}\n@font-face\n\t{font-family:Wingdings;\n\tpanose-1:5 0 0 0 0 0 0 0 0 0;}\n@font-face\n\t{font-family:\"Cambria Math\";\n\tpanose-1:2 4 5 3 5 4 6 3 2 4;}\n@font-face\n\t{font-family:Calibri;\n\tpanose-1:2 15 5 2 2 2 4 3 2 4;}\n /* Style Definitions */\n p.MsoNormal, li.MsoNormal, div.MsoNormal\n\t{margin-top:0in;\n\tmargin-right:0in;\n\tmargin-bottom:0in;\n\tmargin-left:.25in;\n\tmargin-bottom:.0001pt;\n\ttext-indent:-.25in;\n\tline-height:120%;\n\tfont-size:11.0pt;\n\tfont-family:\"Calibri\",sans-serif;}\n.MsoChpDefault\n\t{font-family:\"Calibri\",sans-serif;}\n.MsoPapDefault\n\t{margin-left:.25in;\n\ttext-indent:-.25in;\n\tline-height:120%;}\n@page WordSection1\n\t{size:8.5in 11.0in;\n\tmargin:49.5pt 1.0in .75in 1.0in;}\ndiv.WordSection1\n\t{page:WordSection1;}\n /* List Definitions */\n ol\n\t{margin-bottom:0in;}\nul\n\t{margin-bottom:0in;}\n-->\n" }} />
            <div className="WordSection1">
                <p className="MsoNormal" align="center" style={{ marginBottom: '13.5pt', textAlign: 'center', background: 'white' }}><b><span style={{ fontSize: '18.0pt', lineHeight: '120%', fontFamily: '"Helvetica",sans-serif', color: 'black' }}>Privacy Policy</span></b></p>
                <p className="MsoNormal" style={{ marginLeft: '0in', textAlign: 'justify', textIndent: '0in', background: 'white' }}><span style={{ color: 'black' }}>Please read this Privacy Policy
              carefully before using or accessing “</span><span style={{ color: 'black' }}>ECoS”</span><span style={{ color: 'black' }}> (hereinafter referred to as the “</span><span style={{ color: 'black' }}>Microsoft Add-in</span><span style={{ color: 'black' }}>”) or it’s
              any part or service</span><span style={{ color: 'black' }}>.</span></p>
                <p className="MsoNormal" style={{ marginLeft: '0in', textAlign: 'justify', textIndent: '0in' }}><b><i>&nbsp;</i></b></p>
                <p className="MsoNormal" style={{ marginLeft: '0in', textAlign: 'justify', textIndent: '0in' }}>Our
                Privacy Policy does not cover information we collect about your Users on your
                behalf from your web properties. It is your obligation to provide your own
            privacy policy or notice to your users. </p>
                <p className="MsoNormal" style={{ marginLeft: '0in', textAlign: 'justify', textIndent: '0in' }}><b><i>&nbsp;</i></b></p>
                <p className="MsoNormal" style={{ marginLeft: '0in', textAlign: 'justify', textIndent: '0in' }}><b><i>Information
                we collect</i></b></p>
                <p className="MsoNormal" style={{ marginLeft: '0in', textAlign: 'justify', textIndent: '0in' }}>We
                ask to enter certain information including but not limited to, username, real
                name, date of birth, address, phone number, e-mail address, real estate
                property information or any other information when you register, make a
                purchase or sale, and use the Microsoft Add-in, or if you correspond with us
                for any purpose. We utilize, render or use your given information and data to
                operate, maintain, and provide to you the features, services and functionality
                of the Microsoft Add-in. We do not publish or disclose your username or personal
            information to any third party without your permission.</p>
                <p className="MsoNormal" style={{ marginLeft: '0in', textAlign: 'justify', textIndent: '0in' }}>&nbsp;</p>
                <p className="MsoNormal" style={{ marginLeft: '0in', textAlign: 'justify', textIndent: '0in' }}><b><i>Information
                we may receive from third parties</i></b></p>
                <p className="MsoNormal" style={{ marginLeft: '0in', textAlign: 'justify', textIndent: '0in' }}>We
                may receive information or data about you and your Microsoft Add-in from third
                parties. For example, if you try to access our Microsoft Add-in through a
                third-party Microsoft Add-in connection or log-in, for example, through
                Facebook Connect, linking your account to our Microsoft Add-in etc., that third
                party may pass certain information about your use of its service. The
                information we collect through this channel or procedure could include, but is
                not limited to, the user ID associated with your account, an access token
                necessary to access that service, any information that you have allowed the
                third party to share with us, and any information you have disclosed publicly
                in connection with that service. You should always review and maintain privacy
                settings to secure your personal information on third-party Microsoft Add-ins, services
            or application before linking or connecting them to the Microsoft Add-in.</p>
                <p className="MsoNormal" style={{ marginLeft: '0in', textAlign: 'justify', textIndent: '0in' }}><i>&nbsp;</i></p>
                <p className="MsoNormal" style={{ marginLeft: '0in', textAlign: 'justify', textIndent: '0in' }}><b><i><span style={{ color: 'black' }}>Links to Other Microsoft Add-ins and Services</span></i></b></p>
                <p className="MsoNormal" style={{ marginLeft: '0in', textAlign: 'justify', textIndent: '0in' }}><span style={{ color: 'black' }}>We are not responsible for the practices employed by Microsoft
                Add-ins or services linked to or from the Microsoft Add-in, including the
                information or content contained therein. Please remember that when you use a
                link to go from the Microsoft Add-in to another Microsoft Add-in, our Privacy
                Policy does not apply to third-party Microsoft Add-ins or services. Your
                browsing and interaction on any third-party Microsoft Add-in or service,
                including those that have a link or advertisement on our Microsoft Add-in, are
                subject to that third party’s own rules and policies. In addition, you agree
                that we are not responsible and we do not control over any third-parties that
                you authorize to access your User Content. If you are using a third-party Microsoft
                Add-in or service and you allow such a third-party access to your User Content
                you do so at your own risk. This Privacy Policy does not apply to information
                we collect by other means (including offline) or from other sources other than
              through the Microsoft Add-in.</span></p>
                <p className="MsoNormal" style={{ marginLeft: '0in', textAlign: 'justify', textIndent: '0in' }}><span style={{ color: 'black' }}>&nbsp;</span></p>
                <p className="MsoNormal" style={{ marginLeft: '0in', textAlign: 'justify', textIndent: '0in' }}><b><i>&nbsp;</i></b></p>
                <p className="MsoNormal" style={{ marginLeft: '0in', textAlign: 'justify', textIndent: '0in' }}><b><i>&nbsp;</i></b></p>
                <p className="MsoNormal" style={{ marginLeft: '0in', textAlign: 'justify', textIndent: '0in' }}><b><i><span style={{ color: 'black' }}>&nbsp;</span></i></b></p>
                <p className="MsoNormal" style={{ marginLeft: '0in', textAlign: 'justify', textIndent: '0in' }}><b><i><span style={{ color: 'black' }}>How we Communicate</span></i></b></p>
                <p className="MsoNormal" style={{ marginLeft: '0in', textAlign: 'justify', textIndent: '0in' }}><span style={{ color: 'black' }}>We utilize the information you provide us, such as your
                email address or phone number, to communicate directly with you. We may send
                you emails or messages including newsletters, promotional information and
                special offers. If you do not agree to continue and receive such communication,
                you have the option to change your account preferences and terminate these
                services. Your information may also be used to send other Microsoft Add-in
              related emails.</span></p>
                <p className="MsoNormal" style={{ marginLeft: '0in', textAlign: 'justify', textIndent: '0in' }}><i><span style={{ color: 'black' }}>&nbsp;</span></i></p>
                <p className="MsoNormal" style={{ marginLeft: '0in', textAlign: 'justify', textIndent: '0in' }}><b><i><span style={{ color: 'black' }}>Sharing of Your Information</span></i></b></p>
                <p className="MsoNormal" style={{ marginLeft: '0in', textAlign: 'justify', textIndent: '0in' }}><span style={{ color: 'black' }}>We do not rent or sell your information to third parties
                and its group companies (including any partners, parents, subsidiaries or
              affiliates) without your consent, except as noted below:</span></p>
                <p className="MsoNormal" style={{ marginLeft: '0in', textAlign: 'justify', textIndent: '0in' }}><span style={{ color: 'black' }}>We may share your information with third-party business
                partners for the purpose of providing the functionality of Microsoft Add-in to
                you. We provide only limited information to third parties, affiliates or
                partners reasonably necessary to deliver the services of the Microsoft Add-in.
                This Privacy Policy also governs such third parties or affiliates, or we
                operate under a similar privacy policy. We or any third party service embedded
                with the Microsoft Add-in will also have access to your IP address. We may
                disclose your information upon request of law enforcement agencies or
                departments where we believe that such disclosure is necessary to comply with
                the law. We may also share certain information such as your location, browser,
                and cookie data and other data relating to your use of our Microsoft Add-in
                with our business partners or affiliates to deliver advertisements (“ads”) that
              may be of interest to you.</span></p>
                <p className="MsoNormal" style={{ marginLeft: '0in', textAlign: 'justify', textIndent: '0in' }}><b><i><span style={{ color: 'black' }}>&nbsp;</span></i></b></p>
                <p className="MsoNormal" style={{ marginLeft: '0in', textAlign: 'justify', textIndent: '0in' }}><b><i><span style={{ color: 'black' }}>Event of a change of control</span></i></b></p>
                <p className="MsoNormal" style={{ marginLeft: '0in', textAlign: 'justify', textIndent: '0in' }}><span style={{ color: 'black' }}>We may sell or transfer our company collectively or
                partially or any combination of its products, services and assets. Your
                information such as customer names and email addresses, User Content and other
                user information related to the Microsoft Add-in may be among the products or
              services sold or otherwise transferred. </span></p>
                <p className="MsoNormal" style={{ marginLeft: '0in', textAlign: 'justify', textIndent: '0in' }}><b><span style={{ color: 'black' }}>&nbsp;</span></b></p>
                <p className="MsoNormal" style={{ marginLeft: '0in', textAlign: 'justify', textIndent: '0in' }}><b><i><span style={{ color: 'black' }}>We Store and Protect Your Information Keeping Safe</span></i></b></p>
                <p className="MsoNormal" style={{ marginLeft: '0in', textAlign: 'justify', textIndent: '0in' }}><span style={{ color: 'black' }}>We take care about the security of your information, and
                uses commercially reasonable safeguards to preserve the integrity and security
                of all information collected through our Microsoft Add-in or App. To protect
                your privacy and security, we take reasonable steps (such as requesting a
                unique password) to verify your identity before granting you access to your
                account. You are responsible for maintaining the secrecy of your unique
                password and account information, and for controlling access to your email communications,
              at all times. </span></p>
                <p className="MsoNormal" style={{ marginLeft: '0in', textAlign: 'justify', textIndent: '0in' }}><span style={{ color: 'black' }}>&nbsp;</span></p>
                <p className="MsoNormal" style={{ marginLeft: '0in', textAlign: 'justify', textIndent: '0in' }}><b><i><span style={{ color: 'black' }}>Warranties and Assurance</span></i></b></p>
                <p className="MsoNormal" style={{ marginLeft: '0in', textAlign: 'justify', textIndent: '0in' }}><span style={{ color: 'black' }}>HOWEVER, WE CANNOT ENSURE OR WARRANT THE SECURITY OF ANY
                INFORMATION YOU TRANSMIT TO US OR GUARANTEE THAT INFORMATION ON THE MICROSOFT
                ADD-IN MAY NOT BE ACCESSED, DISCLOSED, ALTERED, OR DESTROYED. YOUR PRIVACY
                SETTINGS MAY ALSO BE AFFECTED BY CHANGES TO THE FUNCTIONALITY OF THIRD PARTY
                SITES AND SERVICES THAT YOU ADD. WE ARE NOT RESPONSIBLE FOR THE FUNCTIONALITY
              OR SECURITY MEASURES OF ANY THIRD PARTY.</span></p>
                <p className="MsoNormal" style={{ marginLeft: '0in', textAlign: 'justify', textIndent: '0in' }}><span style={{ color: 'black' }}>&nbsp;</span></p>
                <p className="MsoNormal" style={{ marginLeft: '0in', textAlign: 'justify', textIndent: '0in' }}><b><i><span style={{ color: 'black' }}>Compromise of information:</span></i></b></p>
                <p className="MsoNormal" style={{ marginLeft: '0in', textAlign: 'justify', textIndent: '0in' }}><span style={{ color: 'black' }}>In the event that any information under our control is
                compromised as a result of a breach of security, we will take reasonable steps
                to investigate the situation and where appropriate, notify those individuals
                whose information may have been compromised and take other steps, in accordance
              with any applicable laws and regulations.</span></p>
                <p className="MsoNormal" style={{ marginLeft: '0in', textAlign: 'justify', textIndent: '0in' }}><i><span style={{ color: 'black' }}>&nbsp;</span></i></p>
                <p className="MsoNormal" style={{ marginLeft: '0in', textAlign: 'justify', textIndent: '0in' }}><b><i><span style={{ color: 'black' }}>How long we keep your information:</span></i></b></p>
                <p className="MsoNormal" style={{ marginLeft: '0in', textAlign: 'justify', textIndent: '0in' }}><span style={{ color: 'black' }}>Following termination of your User account, we may retain
                your private profile information for a commercially reasonable time for backup,
                archival, or audit purposes. For the avoidance of doubt, any information that
              you choose to make public on the service may not be removable.</span></p>
                <p className="MsoNormal" style={{ marginLeft: '0in', textAlign: 'justify', textIndent: '0in' }}><b><span style={{ color: 'black' }}>&nbsp;</span></b></p>
                <p className="MsoNormal" style={{ marginLeft: '0in', textAlign: 'justify', textIndent: '0in' }}><span style={{ color: 'black' }}>.</span></p>
                <p className="MsoNormal" style={{ textAlign: 'justify' }}>&nbsp;</p>
            </div>
        </div>
    );
}