import React from 'react';
import LogoutButton from "./LogoutButton";
import jwt_decode from 'jwt-decode';
import Grid from '@material-ui/core/Grid'

export default function NameForm(props) {

  const user = jwt_decode(props.jwt);

  return (
    <div>
      <Grid container
        alignItems='center'
        justify='center'
        alignContent='center'
        spacing={0}
        style={{ minHeight: '75vh' }}
      >
        <Grid item xs={12}
          justify="center"
          align="center"
        >
          <p style={{
            textAlign: 'center'
          }}>
            <span>{user.name}</span>
            <br />
            <span>{user.email}</span>
          </p>
        </Grid>
        <Grid item xs={12}
          justify='center'
          align='center'
        >
          <LogoutButton></LogoutButton>
        </Grid>
      </Grid>
    </div>
  );

}