import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { CreateOrEditSectionPopup } from './Popups/SectionPopups';
import {
    getSectionHistory
} from './LibraryFunctions';
/*
Description: This componenet displays a grid of sub-sections for a section

Data from props: section object, the section with all
the subsections that we are going to display, and the library id
of the library that this section belongs to.

Functions from props: 
sectionSelected(section): when a subsection is clicked, pass the section
object to this

addSection(name, sectionId, libraryId): pass the name of the new section, sectionId is
the id of the current section object and the parent section id

setParentSections: allows us to specify what sub-section we are on by using ids
of any parent sections and child sections associated with the current sections
we have selected.

*/

export default function CommentDescription(props) {

    //Style for the material-ui componenets
    const classes = useStyles();

    useEffect(() => {
        getSectionsInOrder()
        console.log(props.section)
    }, [props]);

    const [sectionPopup, setSectionPopup] = useState(false);
    const [sectionOrder, setSectionOrder] = useState(null)

    async function getSectionsInOrder() {
        if (props.section.sectionOrder.length < props.section.sections.length) {
            let orderedSections = []
            props.section.sectionOrder.map(id => orderedSections.push(props.section.sections.find(d => d.id == id)))
            let sectionsLength = props.section.sections.length;
            for (var i = 0; i < sectionsLength; i++) {
                let sec = props.section.sections[i];
                if (!orderedSections.find(y => y.id == sec.id)) {
                    orderedSections.push(sec)
                }
            }
            await setSectionOrder(orderedSections)
        } else if (props.section.sectionOrder.length == 0 && props.section.sections.length == 0) {
            await setSectionOrder([])
        } else {
            let orderedSections = []
            props.section.sectionOrder.map(id => orderedSections.push(props.section.sections.find(d => d.id == id)))
            await setSectionOrder(orderedSections);
        }
    }

    //Calls addSection for the popup to create a new section
    function handleAddSection(sectionTitle) {
        //This if statement is for when this LibraryDescription is displayed
        //with the top level section upon the library being open.
        //This is important because the user hasn't selected a section yet
        //but we still want to be able to add to the top level index 0 section
        //that is displayed.
        props.addSection(sectionTitle, props.libraryId, props.section.id);
    }

    function handleAdd() {
        setSectionPopup(true)
    }

    function handleSectionClick(sectionid, section){
        let s = JSON.parse(JSON.stringify(props.path));
        let res = getSectionHistory(props.libraryData, sectionid)
        s.topSection = res.parent;
        s.subSection = res.section;
        s.subSubSection = res.child;
        props.setPath(s);
        props.sectionSelected(section);
    }

    return (
        <div>
            <Divider />
            <div className={classes.sectionGrid}>
                <div>
                    <Button
                        onClick={handleAdd}
                        variant="contained"
                        className={classes.newSectionButton}>
                        New Sub-Section
                            </Button>
                    {sectionPopup &&
                        <CreateOrEditSectionPopup
                            formType='Create'
                            handleSection={handleAddSection}
                            closeWindow={() => setSectionPopup(false)} />}
                </div>
                <div className={classes.filledSectionGrid}>
                    <Grid container className={classes.root} spacing={4}>
                        <Grid item xs={12}>
                            <Grid container alignContent='flex-start' spacing={2}>
                                {sectionOrder && sectionOrder.map((section) => (
                                    <Grid key={sectionOrder.indexOf(section)} item>
                                        <Card className={classes.content}
                                            onClick={() => handleSectionClick(section.id, section)}>
                                            <CardContent className={classes.contentDesc} >
                                                <Typography align='center'>
                                                    {section.name}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    );
}

//Styling for all of the custom componenets
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    contentDesc: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    content: {
        height: 90,
        width: 150,
        backgroundColor: '#d5d5d5',
        marginTop: '2%',
        boxShadow: "none",
        marginBottom: '2%',
        display: 'flex',
        justifyContent: 'center'
    },
    sectionGrid: {
        display: 'flex',
        flexDirection: 'column',
    },
    filledSectionGrid: {
        width: '100%'
    },
    newSectionButton: {
        float: 'right',
        textTransform: 'none',
        marginTop: '2%',
        boxShadow: 'none',
        marginBottom: '2%'
    },
    vacantSectionContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    vacantSectionDisplay: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: 'auto',
    },
    vacantSectionTitle: {
        fontWeight: 500,
        fontSize: 'large',
        color: 'grey',
        marginBottom: '0%'
    },
    addCircleIcon: {
        color: 'Silver',
        fontWeight: 500,
        fontSize: 50,
    },
}));