//WARNING TESTS MAY NOT WORK NOW DUE TO SOME VARIABLE CHANGES (Date: 11/2/2020)

//Takes a comment or sections as the "object" parameter, it distinguishes both by the given "type"
//string. This function add the given comment or section to libraryData by using the given selectedLibraryIndex
//and the path object which indicates what section is selected and if it has children or 
//parent sections. This function edits by refrence, so make sure you do not give deep copies of
//of object but instead the objects themselves.
export function jsonAdd(object, type, libraryData, path) {
    // libraryData = libraryData.libraryContent
    let parentIndex = 0;
    let subSectionIndex = 0;
    let subSubSectionIndex = 0;
    if (path.topSection == null) {
        if (type == "comment") {
            libraryData.sections[0].comments.push(object);
            libraryData.sections[0].commentOrder.push(object.id);
        } else {
            libraryData.sections.push(object);
            libraryData.sectionOrder.push(object.id);
        }
    } else {
        parentIndex = libraryData.sections
            .findIndex(p => p.id == path.topSection)
        if (path.subSection == null) {
            if (type == "comment") {
                libraryData.sections[parentIndex].comments.push(object);
                libraryData.sections[parentIndex].commentOrder.push(object.id);
            } else {
                libraryData.sections[parentIndex].sections.push(object);
                libraryData.sections[parentIndex].sectionOrder.push(object.id)
            }
        } else {
            subSectionIndex = libraryData.sections[parentIndex].sections
                .findIndex(s => s.id == path.subSection);
            if (path.subSubSection == null) {
                if (type == "comment") {
                    libraryData.sections[parentIndex].sections[subSectionIndex].comments.push(object);
                    libraryData.sections[parentIndex].sections[subSectionIndex].commentOrder.push(object.id);
                } else {
                    libraryData.sections[parentIndex].sections[subSectionIndex].sections.push(object);
                    libraryData.sections[parentIndex].sections[subSectionIndex].sectionOrder.push(object.id)
                }
            } else {
                subSubSectionIndex = libraryData.sections[parentIndex].sections[subSectionIndex]
                    .sections.findIndex(s => s.id == path.subSubSection);
                if (type == "comment") {
                    libraryData
                        .sections[parentIndex].sections[subSectionIndex].sections[subSubSectionIndex].comments.push(object);
                    libraryData
                        .sections[parentIndex].sections[subSectionIndex].sections[subSubSectionIndex].commentOrder.push(object.id);
                } else {
                    libraryData
                        .sections[parentIndex].sections[subSectionIndex].sections[subSubSectionIndex].sections.push(object);
                    libraryData
                        .sections[parentIndex].sections[subSectionIndex].sections[subSubSectionIndex].sectionOrder.push(object.id);
                }
            }
        }
    }
}

//Takes a comment or sections as the "object" parameter, it distinguishes both by the given "type"
//string. This function modifies the given comment or seciton in libraryData by using the given 
//selectedLibraryIndex and the path object which indicates what section is selected and 
//if it has children or parent sections. This function edits by refrence, so make sure you do not
//give deep copies of object but instead the objects themselves.
export function jsonChange(object, type, libraryData, path) {
    let parentIndex = 0;
    let subSectionIndex = 0;
    let subSubSectionIndex = 0;
    let commentIndex = 0;
    if (path.topSection == null) {
        if (type == "comment") {
            commentIndex = libraryData.sections[0].comments.findIndex(x => x.id == object.id)
            libraryData.sections[0].comments[commentIndex] = object
        } else {
            libraryData.sections[0] = object
        }
    } else {
        parentIndex = libraryData.sections
                    .findIndex(s => s.id == path.topSection);
        if(path.subSection == null){
            if (type == "comment") {
                commentIndex = libraryData.sections[parentIndex].comments.findIndex(x => x.id == object.id)
                libraryData.sections[parentIndex].comments[commentIndex] = object
            } else {
                libraryData.sections[parentIndex] = object
            }
        } else {
            subSectionIndex = libraryData.sections[parentIndex].sections
            .findIndex(s => s.id == path.subSection);
            if(path.subSubSection == null){
                if (type == "comment") {
                    commentIndex = libraryData.sections[parentIndex].sections[subSectionIndex].comments.findIndex(x => x.id == object.id)
                    libraryData.sections[parentIndex].sections[subSectionIndex].comments[commentIndex] = object
                } else {
                    libraryData.sections[parentIndex].sections[subSectionIndex] = object
                }
            } else {
                subSubSectionIndex = libraryData.sections[parentIndex].sections[subSectionIndex].sections
                .findIndex(c => c.id == path.subSubSection);
                if (type == "comment") {
                    commentIndex = libraryData
                        .sections[parentIndex].sections[subSectionIndex].sections[subSubSectionIndex].comments.findIndex(x => x.id == object.id)
                    libraryData
                        .sections[parentIndex].sections[subSectionIndex].sections[subSubSectionIndex].comments[commentIndex] = object
                } else {
                    libraryData
                        .sections[parentIndex].sections[subSectionIndex].sections[subSubSectionIndex] = object
                }
            }
        }
    }
}

//Takes an seciton id or comment id, it distinguishes both by the given "type"
//string. This funciton deletes the given comment or seciton in libraryData by using the given 
//selectedLibraryIndex and the path object which indicates what section is selected and 
//if it has children or parent sections. This function deletes by refrence, so make sure you do not
//give deep copies of object but instead the objects themselves.
export function jsonDelete(id, type, libraryData, path) {
    let parentIndex = 0;
    let subSectionIndex = 0;
    let subSubSectionIndex = 0;
    let newList = [];
    let newOrder = [];
    console.log(path)
    console.log(id)
    console.log(libraryData)
    if (path.topSection == null) {
        if ("comment" === type) {
            newList = libraryData.sections[0].comments
                .filter(x => { return x.id != id; });
            libraryData.sections[0].comments = newList;
            newOrder = libraryData.sections[0].commentOrder
                .filter(x => { return x != id; });
            libraryData.sections[0].commentOrder = newOrder;
        } else {
            newList = libraryData.sections
                .filter(x => { return x.id != id; })
            libraryData.sections = newList
            newOrder = libraryData.sectionOrder.filter(x => {return x != id});
            libraryData.sectionOrder = newOrder;
        }
    } else {
        parentIndex = libraryData.sections
                    .findIndex(s => s.id == path.topSection);
        if(path.subSection == null){
            if ("comment" === type) {
                newList = libraryData.sections[parentIndex].comments
                    .filter(x => { return x.id != id; });
                libraryData.sections[parentIndex].comments = newList;
                newOrder = libraryData.sections[parentIndex].commentOrder
                    .filter(x => { return x != id; });
                libraryData.sections[parentIndex].commentOrder = newOrder;
            } else {
                newList = libraryData.sections
                    .filter(x => { return x.id != id; })
                libraryData.sections = newList
                newOrder = libraryData.sectionOrder.filter(x => {return x != id});
                libraryData.sectionOrder = newOrder;
            }
        } else {
            subSectionIndex = libraryData.sections[parentIndex].sections
                    .findIndex(s => s.id == path.subSection);
            if(path.subSubSection == null){
                if ("comment" === type) {
                    newList = libraryData.sections[parentIndex].sections[subSectionIndex]
                        .comments.filter(x => { return x.id != id; });
                    libraryData.sections[parentIndex].sections[subSectionIndex].comments = newList;
                    newOrder = libraryData.sections[parentIndex].sections[subSectionIndex]
                        .commentOrder.filter(x => { return x != id; });
                    libraryData.sections[parentIndex].sections[subSectionIndex].commentOrder = newOrder;
                } else {
                    newList = libraryData.sections[parentIndex].sections
                        .filter(x => { return x.id != id; })
                    libraryData.sections[parentIndex].sections = newList
                    newOrder = libraryData.sections[parentIndex].sectionOrder.filter(y => {return y != id})
                    newOrder = libraryData.sections[parentIndex].sectionOrder = newOrder
                }
            } else {
                subSubSectionIndex = libraryData.sections[parentIndex].sections[subSectionIndex].sections
                .findIndex(c => c.id == path.subSubSection);
                if ("comment" === type) {
                    newList = libraryData
                        .sections[parentIndex].sections[subSectionIndex].sections[subSubSectionIndex].comments
                        .filter(x => { return x.id != id; });
                    libraryData
                        .sections[parentIndex].sections[subSectionIndex].sections[subSubSectionIndex].comments = newList;
                    newOrder = libraryData
                        .sections[parentIndex].sections[subSectionIndex].sections[subSubSectionIndex].commentOrder
                        .filter(x => { return x != id; });
                    libraryData
                        .sections[parentIndex].sections[subSectionIndex].sections[subSubSectionIndex].commentOrder = newOrder;
                } else {
                    newList = libraryData
                        .sections[parentIndex].sections[subSectionIndex].sections
                        .filter(x => { return x.id != id; })
                    libraryData
                        .sections[parentIndex].sections[subSectionIndex].sections = newList
                    newOrder = libraryData.sections[parentIndex].sections[subSectionIndex].sectionOrder.filter(y => {return y != id; })
                    libraryData.sections[parentIndex].sections[subSectionIndex].sectionOrder = newOrder
                }
            }
        }
    }
}

//Json test strings used in __test__ file
//to see how the above functions edit the
//following strings.
//These test jsons are made without "additionalMaterials"
//parameter in comments, but they should either way work
//in the same way with this parameter.

export let libraryData0 = [{
    "id": 1,
    "name": "name",
    "permission": "owner",
    "type": "comment",
    "description": "description",
    "link": "",
    "libraryContent": {
        "sections": []
    }
}];

export let libraryData1 = [{
    "id": 1,
    "name": "name",
    "permission": "owner",
    "type": "comment",
    "description": "description",
    "link": "",
    "libraryContent":
    {
        "sections":
            [{ "id": 2, "name": "section1", "sections": [], "comments": [] }]
    }
}];


export let libraryData2 = [{
    "id": 1,
    "name": "name",
    "permission": "owner",
    "type": "comment",
    "description": "description",
    "link": "",
    "libraryContent": {
        "sections": [{
            "id": 2,
            "name": "section1",
            "sections": [{
                "id": 5,
                "name": "section1",
                "sections": [{
                    "id": 8,
                    "name": "section1",
                    "sections": [],
                    "comments": [{
                        "category": "Effciency",
                        "goodExample": "goodExample",
                        "badExample": "badExample",
                        "title": "title",
                        "explanation": "explanation",
                        "id": 14,
                        "additionalMaterials": "additionalMaterials"
                    }, {
                        "category": "Effciency",
                        "goodExample": "goodExample",
                        "badExample": "badExample",
                        "title": "title",
                        "explanation": "explanation",
                        "id": 9,
                        "additionalMaterials": "additionalMaterials"
                    }]
                }],
                "comments": []
            }],
            "comments": []
        }, {
            "id": 3,
            "name": "section1",
            "sections": [],
            "comments": [{
                "category": "Effciency",
                "goodExample": "goodExample",
                "badExample": "badExample",
                "title": "title",
                "explanation": "explanation",
                "id": 11,
                "additionalMaterials": "additionalMaterials"
            }]
        }, {
            "id": 4,
            "name": "section1",
            "sections": [{
                "id": 7,
                "name": "section1",
                "sections": [],
                "comments": [{
                    "category": "Effciency",
                    "goodExample": "goodExample",
                    "badExample": "badExample",
                    "title": "title",
                    "explanation": "explanation",
                    "id": 12,
                    "additionalMaterials": "additionalMaterials"
                }]
            }],
            "comments": []
        }]
    }
}];

export let libraryData3 = [{
    "id": 1,
    "name": "name",
    "permission": "owner",
    "type": "comment",
    "description": "description",
    "link": "",
    "libraryContent": {
        "sections": [{
            "id": 2,
            "name": "section1",
            "sections": [{
                "id": 5,
                "name": "section1",
                "sections": [],
                "comments": []
            }],
            "comments": []
        }]
    }
}];

export let libraryData4 = [{
    "id": 1,
    "name": "name",
    "permission": "owner",
    "type": "comment",
    "description": "description",
    "link": "",
    "libraryContent":
    {
        "sections":
            [{
                "id": 2, "name": "section1", "sections": [], "comments": [{
                    "category": "Effciency",
                    "goodExample": "goodExample",
                    "badExample": "badExample",
                    "title": "title",
                    "explanation": "explanation",
                    "id": 12,
                    "additionalMaterials": "additionalMaterials"
                }]
            }]
    }
}];