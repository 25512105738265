import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { useAuth0 } from "../../react-auth0-spa";
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import GetStarted from './GetStarted';
import {
    logInClickEvent,
    signInClickEvent
} from '../../GAEvents';
import { Link } from "react-router-dom";

export default function LoginBar(props) {
    const classes = useStyles();
    const { isAuthenticated, loginWithRedirect, logout, user, loading } = useAuth0();

    const title = "Efficiency, Content, Style.";
    const description = "ECoS is a Microsoft Word add-in that allows you to " +
        "review technical documents more efficiently.";

    const handleInstall = () => {

    }

    const pickedColor = props.color ? props.color : 'linear-gradient(to left, #F9F9FA 60%, #FFFFFF 0%)'

    const root = {
        flexGrow: 1,
        paddingBottom: 0,
        background: pickedColor,
    }

    const loginClick = () => {
        logInClickEvent();
        loginWithRedirect({ appState: { targetUrl: window.location.pathname, commentCategory: props.comment, secData: props.secData } })
    }

    const signinClick = () => {
        signInClickEvent();
        loginWithRedirect({})
    }

    return (
        <div>
            <Grid
                container
                style={root}
            >
                <Grid item
                    xs={6}
                    className={classes.image}
                >
                    <Link to="/">
                    <Card className={classes.card}>
                        <CardMedia
                            className={classes.media}
                            image='https://ecos-media.s3-us-west-2.amazonaws.com/logo-filled.png'
                        />
                    </Card>
                    </Link>
                    <Card className={classes.tutorial}>
                        <GetStarted edit={props.edit}/>
                    </Card>
                </Grid>
                <Grid item
                    xs={6}
                    className={classes.grid}
                >
                    { /* passes target URL, details about comment and section (if available) to redirect handler (in index.js) */ }
                    <Button onClick={loginClick} className={classes.loginbutton}>
                        {props.edit ? "Login to Edit" : "Login"}
                    </Button>
                    <Button onClick={signinClick} className={classes.signupbutton}>
                        Sign Up
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        paddingBottom: 0,
        background: 'linear-gradient(to left, #F9F9FA 60%, #FFFFFF 0%)',
    },
    image: {
        paddingLeft: '15px',
        display: 'flex',
        flexDirection: 'row',
    },
    grid: {
        paddingLeft: '15px',
        paddingRight: '15px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    loginbutton: {
        marginTop: '15px',
        fontSize: 15,
        color: "gray",
        background: '#F9F9FA',
        border: 'none',
        marginRight: 50,
        "&:hover": {
            backgroundColor: '#F9F9FA'
        },
        paddingLeft: '3%',
        paddingRight: '3%',
        borderRadius: 25,
        minWidth: 105.25
    },
    signupbutton: {
        marginTop: '15px',
        fontSize: 15,
        color: "#5096FF",
        background: '#FFFFFF',
        border: 'none',
        paddingLeft: '5%',
        paddingRight: '5%',
        paddingTop: '0%',
        paddingBottom: '0%',
        "&:hover": {
            backgroundColor: '#FFFFFF'
        },
        borderRadius: 25,
    },
    card: {
        paddingTop: '15px',
        boxShadow: 'none',
        height: 50,
        width: 50,
    },
    media: {
        paddingTop: '100%',
    },
    tutorial: {
        paddingLeft: '5%',
        paddingTop: '2%',
        boxShadow: 'none',
        display: 'flex',
        alignItems:'center',
        minWidth: '100px'
    }
}));