import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Editor } from "@tinymce/tinymce-react";
import { CustomSelect } from './Popups/CustomPopupComponents';
import { CustomTextField } from './Popups/CustomPopupComponents';
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import Box from '@material-ui/core/Box';

/*
Description: 
    Popup window for creating/editing a comment

Data from props:
    formType (string) - value="Edit"/"Create"
    forDefaultValues (commentType, commentText, 
        commentExplanation, commentCorrectExample, 
        commentIncorrectExample) - used to set the existing
        values of editor and text fields if they have been
        set before for the current comment

Function from props:
    handleComment(commentType, commentText, commentExplanation, commentCorrectExample, commentIncorrectExample) - create a new comment
    closeWindow() - stops the display of this component in CommentDescription
    */

export default function NewComment(props) {

    //styling details for different components in this file
    const classes = useStyles();

    var explanationSetup = "<h2 style=\"line-height: 1;\" data-mce-style=\"line-height: 1;\"><span style=\"color: #000000; font-size: 18pt;\" data-mce-style=\"color: #000000; font-size: 18pt;\">Explanation</span></h2>"
    var correctUseSetup = "<h2 style=\"line-height: 1;\" data-mce-style=\"line-height: 1;\"><span style=\"color: #2dc26b; font-size: 18pt;\" data-mce-style=\"color: #2dc26b; font-size: 18pt;\">Examples of Correct Use</span></span></h2>"
    var incorrectUseSetup = "<h2 class=\"jss286\" style=\"line-height: 1;\" data-mce-style=\"line-height: 1;\"><span style=\"background-color: #ffffff; color: #e03e2d; font-size: 18pt;\" data-mce-style=\"background-color: #ffffff; color: #e03e2d; font-size: 18pt;\">Examples of Incorrect Use</span></h2>"
    var additionalMUseSetup = "<h2 style=\"line-height: 1;\" data-mce-style=\"line-height: 1;\"><span style=\"color: #b96ad9; font-size: 18pt;\" data-mce-style=\"color: #b96ad9; font-size: 18pt;\">Additional Materials</span></h2>"
    var fillerTextSetup = "<p style=\"line-height: 1.5;\" data-mce-style=\"line-height: 1.5;\"><span style=\"font-size: 12pt; color: #000000;\" data-mce-style=\"font-size: 12pt; color: #000000;\">Some filler text to set the right line font.</span></p>"

    //Default object for all the parts in the comment
    const formDefaultValues = {
        category: "Efficiency",
        commentText: "",
        commentExplanation: explanationSetup + fillerTextSetup + correctUseSetup + fillerTextSetup + incorrectUseSetup + fillerTextSetup + additionalMUseSetup + fillerTextSetup,
        commentCorrectExample: "",
        commentIncorrectExample: "",
        additionalMaterials: "",
        type: "Regular"
    }

    //formValues uses the default values above unless they are given,
    //formValues are usually given when the comment is editted
    //with existing values
    const [formValues, setFormValues] = useState(props.formDefaultValues ? props.formDefaultValues : formDefaultValues);
    const { category, commentText, commentExplanation, type} = formValues;

    //changes the explanation value of formValues based
    //on what is input into the editor
    function handleExplanationEditorChange(content, editor) {
        setFormValues(prevState => ({
            ...prevState,
            commentExplanation: content
        }));
    }

    //this is used by the title of the comment and the
    //category to reset the values in formValues
    function handleChange(e) {
        const target = e.target;
        setFormValues(prevState => ({
            ...prevState,
            [target.name]: target.value
        }));
    }

    //If the "Save" or "Create" button are clicked
    //this funciton will stop displaying this component
    //and will call the associated parent componenet function
    //to either update the edited comment or create a new one
    function handleFinish() {
        props.handleComment(category, commentText, commentExplanation, type);
        props.closeWindow();
    };

    function getEditor(formValue, title, editorFunc) {
        return (
            <div className={classes.editor}>
                <Editor
                    onEditorChange={editorFunc}
                    apiKey={process.env.TINYMCE_API}
                    value={formValue}
                    init={{
                        //This allows people to use "Tab"
                        setup: function (ed) {
                            ed.on('keydown', function (event) {
                                if (event.keyCode == 9) {
                                    if (event.shiftKey) {
                                        ed.execCommand('Outdent');
                                    }
                                    else {
                                        ed.execCommand('Indent');
                                    }

                                    event.preventDefault();
                                    return false;
                                }
                            });

                        },
                        width: "100%",
                        //Allows a large array of text editting options
                        plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount autoresize',
                            'tabfocus fullscreen hr'
                        ],
                        //should allow image uploads locally
                        images_upload_url: 'postAcceptor.php',
                        automatic_uploads: false,
                        //This sets up the first couple row in the editor view
                        toolbar: 'undo redo | formatselect | ' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | ' +
                            'removeformat | help',
                    }}
                />
            </div>
        );
    }

    return (
        <div>
            <div className={classes.mainComponents}>
                <Typography component="div" >
                    <Box
                        fontWeight="fontWeightBold"
                        fontSize={25}
                        style={{ 'margin': '0px', 'marginBottom': '2%' }}
                        m={1}>
                        {(props.formType === "Create") ? ("Create a New Comment") : ("Edit Comment")}
                    </Box>
                </Typography>
                <CustomSelect
                    title="Category"
                    name="category"
                    value={category}
                    onChange={handleChange}
                    options={["Efficiency", "Content", "Style"]}
                />
                {/*<CustomSelect*/}
                {/*    title="Type"*/}
                {/*    name="type"*/}
                {/*    value={type}*/}
                {/*    onChange={handleChange}*/}
                {/*    options={["Regular", "Category Overview"]} */}
                {/*/>*/}
                <div style={{ 'marginTop': '2%' }}>
                    <CustomTextField
                        title="Title"
                        onChange={handleChange}
                        name="commentText"
                        value={commentText}
                        rows={1} 
                        />
                </div>
                {getEditor(commentExplanation, "Explanation", handleExplanationEditorChange)}
                <div className={classes.buttonGroup}>
                    <Button onClick={props.closeWindow} size='large' variant='outlined'>
                        {(props.formType === "Create") ? ("Discard") : ("Back")}
                    </Button>
                    <Button onClick={handleFinish} size='large' variant='contained' color="primary">
                        {(props.formType === "Create") ? ("Create") : ("Save")}
                    </Button>
                </div>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    editor: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    buttonGroup: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginTop: '2%',
        '& > *': {
            margin: theme.spacing(1),
        }
    },
    mainComponents: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        margin: 'auto',
        justifyContent: 'space-between'
    },
    editorTitle: {
        color: 'black',
        marginTop: '3%',
        marginBottom: '1%',
    },
}));