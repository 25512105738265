import React, { useState, useEffect } from 'react';
import Button from "@material-ui/core/Button";
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle"
import { makeStyles } from "@material-ui/core/styles";
import { PaperStyle } from './CustomPopupComponents';
import { CustomTitle } from './CustomPopupComponents';

/*
Description: 
    Popup window for deleting anything

Data from props:
    deleteType (string) - value="Section"/"Sub-Section"/"Comment"/...

Function from props:
    handleDelete() - delete the {deleteType }
    closeWindow()
*/
export function DeletePopup(props) {
    const classes = useStyles();

    const [open, setOpen] = useState(true);
    const [perm, setPerm] = useState('owner');

    useEffect(() => {
        if (props.permissionType != null && props.permissionType != 'owner') {
            props.permissionType.then(value => { setPerm(value) });
        }
    }, [props]);

    const handleClose = () => {
        setOpen(false);
        props.closeWindow();
    };

    const handleFinish = () => {
        handleClose();
        props.handleDelete();
    }

    return (
        <div>
            <Dialog
                PaperProps={PaperStyle}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={"sm"}
                style={{ textAlign: "center" }}
            >
                <CustomTitle title="" onClick={handleClose} />
                <DialogTitle id="alert-dialog-title">
                    {perm !== 'owner' ?
                        "You don't have the permission to delete this section."
                        :
                        "Are you sure you want to delete " + props.deleteType + "?"
                    }
                </DialogTitle>
                <DialogContent>
                    {perm === 'owner' &&
                        <DialogContentText id="alert-dialog-description">
                            This change is irreversible.
                        </DialogContentText>
                    }
                </DialogContent>
                <DialogActions className={classes.buttonGroup}>
                    <Button
                        className={classes.button}
                        variant="outlined"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    {perm === 'owner'  &&
                        <Button
                            className={classes.button}
                            variant="contained"
                            color="secondary"
                            startIcon={<DeleteIcon />}
                            onClick={handleFinish}
                        >
                            Delete
                        </Button>
                    }
                </DialogActions>
            </Dialog>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    button: {
        padding: "25 px",
    },
    buttonGroup: {
        justifyContent: "center",
        padding: "15px"
    }
}));