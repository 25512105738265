import React from "react";
import { useAuth0 } from "../react-auth0-spa";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import { makeStyles } from '@material-ui/core/styles';
import CardMedia from '@material-ui/core/CardMedia';

const LoginPage = (props) => {
    const classes = useStyles();
    const { isAuthenticated, loginWithRedirect, logout, user, loading } = useAuth0();

    function handleLogIn(){
        loginWithRedirect({})
    }

    return (
        <div>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                className={classes.grid}
            >
                <Card className={classes.pictureRoot}>
                    <CardMedia
                        className={classes.media}
                        image="./logo-filled.png"
                        image={require('../assets/logo-filled.png')}
                        title="Logo"
                    />
                </Card>
                <Card className={classes.buttonRoot}>
                    <CardActions classes={{ root: classes.actionBar }}>
                        <Button onClick={handleLogIn} className={classes.button}>Log In</Button>
                    </CardActions>
                </Card>
            </Grid>
        </div>
    );
};

const useStyles = makeStyles({
    grid: {
        minHeight: '90vh',
    },
    buttonRoot: {
        midWidth: 200,
        paddingTop: '20px',
        boxShadow: 'none'
    },
    pictureRoot: {
        minWidth: 100,
        boxShadow: 'none'
    },
    actionBar: {
        justifyContent: 'center',
    },
    button: {
        background: '#C4C4C4',
        borderRadius: '10px',
        marginBottom: '10px',
        "&:hover": {
            backgroundColor: '#C4C4C4'
        },
        minWidth: '200px',
    },
    media: {
        height: 100,
    },
});

export default LoginPage;