import React, { useState, useEffect } from 'react';
import { MyLibraries } from '../MyLibraries';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import {
    createLibrary,
    deleteLibrary,
    updateLibrary,
    shareLibrary,
    shareLibraryCopy,
    getLibraryById,
    recoverLibrary,
    uploadLibrary,
} from '../LibraryFunctions';

/*
Description: 

This is called by the MainTab when a user has first clicked the "Dashboard"
tab on the left side menu or if the user first logged in.

If the user has no libraries shared with them or they did not create any then
no library tiles will be displayed. Otherwise tiles of different libraries will
be seen displayed vertically. Each tile contains the name and a small description.

The user is able to create, delete, update, and share any of the section through
this display by clicking the three vertical dots on each of the tiles.

Data From Props: 
props.jwt: used to create, add, edit, and delete librararies
props.libraryData: the list of all the libraries with their associated
    ids and descriptions.
props.librarySelected: any object of a section that has been selected
    by the user

Functions From Props: 
props.updateLibrariesData: function that allows LibraryTab to make
    MainTab hard update the libraryData object it contains

*/
export function LibraryTab(props) {

    // Creates a new library
    function createNewLibrary(name, description) {
        createLibrary(props.jwt, name, description).then(() => {
            props.updateLibrariesData();
        });
    }

    // Uploads a library
    function uploadNewLibrary(libraryJSON) {
        return uploadLibrary(props.jwt, libraryJSON).then((responseObj) => {
            return new Promise((resolve, reject) => {
                props.updateLibrariesData();
                resolve();
            }).then(() => {
                console.log(responseObj.status);
                return responseObj.status;
            });
        });
    }

    // Deletes a library
    function deleteExistingLibrary(libraryId) {
        return deleteLibrary(props.jwt, libraryId).then((responseObj) => {
            return new Promise((resolve, reject) => {
                props.updateLibrariesData();
                resolve();
            }).then(() => {
                return responseObj.status;
            });
        });
    }

    // Edits a library
    function editExistingLibrary(libraryId, libraryName, libraryDescription) {
        updateLibrary(props.jwt, libraryId, libraryName, libraryDescription).then(() => {
            props.updateLibrariesData();
        });
    }

    // Shares a library
    function shareExistingLibrary(libraryId, emailToShareWith, roleToGive) {
        return shareLibrary(props.jwt, libraryId, emailToShareWith, roleToGive).then((responseObj) => {
            return responseObj.status;
        });
    }

    // Downloads the library as a backup
    function downloadBackupExistingLibrary(libraryId) {
        getLibraryById(props.jwt, libraryId).then((library) => {
            if (library != null) {
                const lib = JSON.stringify(library);
                const data = new Blob([lib], { type: 'text/plain' });
                const URL = window.URL.createObjectURL(data);
                let tempLink = document.createElement('a');
                tempLink.href = URL;
                tempLink.setAttribute('download', library.name + '.lib');
                tempLink.click();
            }
        });
    }

    // Uploads a library backup to recover
    function recoverBackupExistingLibrary(libraryJSON, libraryId) {
        return recoverLibrary(props.jwt, libraryJSON, libraryId).then((responseObj) => {
            props.updateLibrariesData();
            return responseObj.status;
        });
    }

    // Shares a copy of a library
    function shareCopyOfExistingLibrary(libraryId, emailToShareWith) {
        return shareLibraryCopy(props.jwt, libraryId, emailToShareWith).then((responseObj) => {
            return responseObj.status;
        });
    }

    return (
        <MyLibraries
            libraries={props.libraryData}
            librarySelected={props.librarySelected}
            createNewLibrary={createNewLibrary}
            deleteExistingLibrary={deleteExistingLibrary}
            editExistingLibrary={editExistingLibrary}
            shareExistingLibrary={shareExistingLibrary}
            shareCopyOfExistingLibrary={shareCopyOfExistingLibrary}
            downloadBackupLibrary={downloadBackupExistingLibrary}
            recoverBackupLibrary={recoverBackupExistingLibrary}
            uploadLibrary={uploadNewLibrary}
        />
    );
}