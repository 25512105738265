import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";

export default function GetStarted(props) {
    const classes = useStyles();
    return (
        <div>
            { props.edit == null &&
                <Link className={classes.link} to="/tutorials">Tutorials</Link>
            }
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    link: {
        textDecoration: 'none',
        fontSize: 18,
        color: "gray",
        background: '#F9F9FA',
        "&:hover": {
            backgroundColor: '#F9F9FA'
        },
        paddingLeft: 25,
        paddingRight: 25,
        fontFamily: 'Roboto',
        borderRadius: 25,
        paddingBottom: 10,
        paddingTop: 10
    },
}))