import React from 'react';

export function Support() {
    return (
        <div>
            <title>Page Title</title>
            <div>
                <h1>ECoS Support</h1>
          Thank you for using ECoS. We are working on improving the addin and
          any feedback would be helpful. Check out our demo video and email us
          with any questions you have.
          <h3>Contact Info</h3>
          email: development@ecostools.com
          <h3>Demo Video</h3>
                <a href="https://drive.google.com/file/d/18z3FQT8xW2K0VIjfd1CaOMVg009rmFqK/view?usp=sharing">Demo Video</a>
            </div>
        </div>
    );
}