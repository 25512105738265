import React, { useState, useEffect, useLayoutEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Editor } from "@tinymce/tinymce-react";
import Button from '@material-ui/core/Button';

/*
Description: The componenet renders the expanded view of a comment accordion details.

Data from props: 
update: number that hard updates the display of this component.
dispaly: object contianing comment id and property called "open" which
indicates if the comment with this id has been expanded before.
comment: object containing everything about the comment that needs to be expanded.

Functions from props: 
None

*/

export default function ExpandedComment(props) {

    //updates the "output" based on changes in the props
    useEffect(() => {
        handleOutput();
    }, [props]);

    //initiates the classes we use for styling
    const classes = useStyles();
    //keeps track of weather the comment has been expanded or not
    const [expanded, setExpanded] = useState(false);
    //the output displayed by this component
    const [output, setOutput] = useState(null);
    //keeps track of what previous comment looked like
    const [prevComment, setPrevComment] = useState(null)

    //Displays the right output
    async function handleOutput() {
        let out;
        if (props.display.open && !expanded) {
            out = handleExpand(props.comment);
            setPrevComment(props.comment)
            setOutput(out);
            setExpanded(true)
        } else if (!expanded) {
            out = <div></div>
            await setOutput(out);
        } else if (props.comment != prevComment) {
            out = handleExpand(props.comment);
            setOutput(out);
            setPrevComment(props.comment)
        }
    }

    //Creates the editors for the comment descriptions
    function handleExpand(selectCategoryComment) {
        return (
            <div className={classes.expandedComment}>
                {/* <div>Explanation</div> */}
                <div className={classes.regEditor}>
                    <Editor
                        id={"editorOne" + selectCategoryComment.id}
                        apiKey={process.env.REACT_APP_TINYMCE_API}
                        value={selectCategoryComment.explanation}
                        init={{
                            setup: function (editor) {
                                editor.on('init', function (e) {
                                    editor.mode.set("readonly");
                                    editor.execCommand('mceAutoResize');
                                });
                            },
                            readonly: 1,
                            //makes sure no toolbars and menubars are seen
                            //by the user because they can't use it anyways
                            menubar: false,
                            toolbar: false,
                            //this plugin is used to resize editor based on the
                            //content in the editor
                            plugins: 'autoresize',
                            //defauls styling for the editor taken from tinymce
                            //content_css: '//www.tiny.cloud/css/codepen.min.css',
                            cleanup_on_startup: true,
                            fix_list_elements: false,
                            fix_nesting: false,
                            fix_table_elements: false,
                            paste_use_dialog: true,
                            paste_auto_cleanup_on_paste: true,
                        }}
                    />
                </div>
                {/* </div>
                <div style={{ 'marginTop': '2%' }}>Correct Usage Example</div>
                <Card className={classes.goodExample} >
                    <CardContent>
                        <Editor
                            id={"editorTwo" + selectCategoryComment.id}
                            apiKey={process.env.REACT_APP_TINYMCE_API}
                            value={selectCategoryComment.goodExample}
                            init={{
                                setup: function (editor) {
                                    editor.on('init', function (e) {
                                        editor.mode.set("readonly");
                                        editor.execCommand('mceAutoResize');
                                    });
                                },
                                readonly: 1,
                                plugins: 'autoresize',
                                menubar: false,
                                toolbar: false,
                                content_css: '//www.tiny.cloud/css/codepen.min.css',
                                cleanup_on_startup: true,
                                fix_list_elements: false,
                                fix_nesting: false,
                                fix_table_elements: false,
                                paste_use_dialog: true,
                                paste_auto_cleanup_on_paste: true,
                            }}
                        />
                    </CardContent>
                </Card>
                <div style={{ 'marginTop': '2%' }}>Incorrect Usage Example</div>
                <Card className={classes.badExample} >
                    <CardContent>
                        <Editor
                            id={"editorThree" + selectCategoryComment.id}
                            apiKey={process.env.REACT_APP_TINYMCE_API}
                            value={selectCategoryComment.badExample}
                            init={{
                                setup: function (editor) {
                                    editor.on('init', function (e) {
                                        editor.mode.set("readonly");
                                        editor.execCommand('mceAutoResize');
                                    });
                                },
                                readonly: 1,
                                plugins: 'autoresize',
                                menubar: false,
                                toolbar: false,
                                content_css: '//www.tiny.cloud/css/codepen.min.css',
                                cleanup_on_startup: true,
                                fix_list_elements: false,
                                fix_nesting: false,
                                fix_table_elements: false,
                                paste_use_dialog: true,
                                paste_auto_cleanup_on_paste: true,
                            }}
                        />
                    </CardContent>
                </Card>
                <div style={{ 'marginTop': '2%' }}>Additional Materials</div>
                <div className={classes.regEditor}>
                    <Editor
                        id={"editorFour" + selectCategoryComment.id}
                        apiKey={process.env.REACT_APP_TINYMCE_API}
                        value={selectCategoryComment.additionalMaterials}
                        init={{
                            setup: function (editor) {
                                editor.on('init', function (e) {
                                    editor.mode.set("readonly");
                                    editor.execCommand('mceAutoResize');
                                });
                            },
                            readonly: 1,
                            //makes sure no toolbars and menubars are seen
                            //by the user because they can't use it anyways
                            menubar: false,
                            toolbar: false,
                            //this plugin is used to resize editor based on the
                            //content in the editor
                            plugins: 'autoresize',
                            //defauls styling for the editor taken from tinymce
                            content_css: '//www.tiny.cloud/css/codepen.min.css',
                            cleanup_on_startup: true,
                            fix_list_elements: false,
                            fix_nesting: false,
                            fix_table_elements: false,
                            paste_use_dialog: true,
                            paste_auto_cleanup_on_paste: true,
                        }}
                    />
                </div> */}
            </div>
        );
    }

    //Copies comment to clipboard
    function copyToClipboard(e, id) {
        var copyOne = window.tinymce.get('editorOne' + id).getContent();
        //var copyTwo = window.tinymce.get('editorTwo' + id).getContent();
        //var copyThree = window.tinymce.get('editorThree' + id).getContent();
        //var copyFour = window.tinymce.get('editorFour' + id).getContent();
        var explanationHeading = '<h2><span style="color: #000000;">Explanation</span></h2>';
        //var corrrectHeading = '<h2><span style="color: #000000;">Correct Usage Example</span></h2>';
        //var incorrectHeading = '<h2><span style="color: #000000;">Incorrect Usage Example</span></h2>';
        //var additionalHeading = '<h2><span style="color: #000000;">Additional Materials</span></h2>';
        window.tinymce.get('editorOne' + id).setContent(explanationHeading + copyOne);
        //console.log(window.tinymce.get('editorOne' + id).setContent(explanationHeading + copyOne));
        //window.tinymce.get('editorOne' + id).setContent(explanationHeading + copyOne
        //    + corrrectHeading + copyTwo + incorrectHeading + copyThree + additionalHeading + copyFour);
        window.tinymce.get('editorOne' + id).selection.select(window.tinymce.get('editorOne' + id).getBody());
        window.tinymce.get('editorOne' + id).execCommand('copy');
        window.tinymce.get('editorOne' + id).setContent(copyOne)
    };

    return (
        <div key={props.update} className={classes.main}>
            <div>
                <Button
                    onClick={(e) => copyToClipboard(e, props.comment.id)}
                    variant="contained"
                    className={classes.copyCommentButton}>
                    Copy Comment
                </Button>
            </div>
            {output}
        </div >
    );
}

//styling
const useStyles = makeStyles((theme) => ({
    main: {
        display: 'flex',
        flexDirection: 'column'
    },
    copyCommentButton: {
        float: 'right',
        textTransform: 'none',
        marginBottom: '1%',
        boxShadow: 'none',
    },
    expandedComment: {
        width: '90%',
        margin: 'auto',
    },
    regEditor: {
        width: '100%',
        marginTop: '2%',
        marginBottom: '2%',
    },
    expandedCommentDesc: {
        fontWeight: 500,
        color: 'grey',
        fontSize: 'small',
    },
    badExample: {
        backgroundColor: '#ffb3b3',
        marginTop: '2%',
        boxShadow: "none",
        width: '100%',
    },
    badExampleDesc: {
        fontWeight: 500,
        fontSize: 'small',
        color: 'grey'
    },
    goodExample: {
        width: '100%',
        backgroundColor: '#b3ffb3',
        marginTop: '2%',
        boxShadow: "none",
        marginBottom: '2%'
    },
    goodExampleDesc: {
        fontWeight: 500,
        fontSize: 'small',
        color: 'grey'
    }
}));
