import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import Card from '@material-ui/core/Card';

export default function UsePage(props) {
    const classes = useStyles();
    const title = "How does ECoS work?";
    const stepOne = "Users can create a library representing a technical document to be shared with other users.";
    const stepTwo = "Specific issues, hints, and suggestions can be described within a library's comments.";
    const stepThree = "Users can access and use libraries by downloading and logging into the ECoS addin.";

    return (
        <div>
            <Grid container 
                direction="row"
                justify="center"
                alignItems="center" 
                spacing = {12}
                className={classes.root}
            >
                <Grid container 
                    direction="row"
                    justify="center"
                    spacing = {12}
                    alignItems="center" xs={12} className={classes.grid}
                >
                    <Typography className={classes.title}>{title}</Typography>
                </Grid>

                <Grid item xs={4} sm={3} className={classes.grid}>
                    <Card className={classes.card}>
                        <CardMedia 
                            className={classes.media}
                            image='https://ecos-media.s3-us-west-2.amazonaws.com/library.jpg'
                        />
                    </Card>
                </Grid>
                <Grid item xs={5} sm={3} className={classes.grid}>
                    <Card className={classes.card}>
                        <CardMedia 
                            className={classes.media}
                            image='https://ecos-media.s3-us-west-2.amazonaws.com/comment.jpg'
                        />
                    </Card>
                </Grid>
                <Grid item xs={5} sm={3} className={classes.grid}>
                    <Card className={classes.card}>
                        <CardMedia 
                            className={classes.media}
                            image='https://ecos-media.s3-us-west-2.amazonaws.com/addin.png'
                        />
                    </Card>
                </Grid>

                <Grid container
                style={{gap: 35}}
                direction="row"
                justify="center"
                alignItems="center"
                className={classes.grid}
                >
                    <Grid item xs={5} sm={3} className={classes.grid} padding={40}>
                        <Typography className={classes.description}>{stepOne}</Typography>
                    </Grid>
                    <Grid item xs={4} sm={3} className={classes.grid} padding={40}>
                        <Typography className={classes.description}>{stepTwo}</Typography>
                    </Grid>
                    <Grid item xs={4} sm={3} className={classes.grid} padding={40}>
                        <Typography className={classes.description}>{stepThree}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      paddingTop: '100px',
      paddingRight: '100px',
      paddingLeft: '100px',
      paddingBottom: '100px',
      background: 'linear-gradient(to left, #F9F9FA 100%, #FFFFFF 0%)',
    },
    title: {
        fontWeight: 700,
        color: '#1B0D6E',
        fontSize: 50,
        paddingBottom: '15px',
    },
    grid: {
        paddingLeft: '25px',
        paddingRight: '25px',
        paddingBottom: '25px',
    },
    description: {
        fontSize: 25,
    },
    card: {
        backgroundColor: 'black',
    },
    media: {
        paddingTop: '56.25%',
        height: 0,
    },
  }));